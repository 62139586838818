.job-analytics {
  padding: 10px;
  padding-top: 30px;

  &__header {
    @include display-flex(row);

    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: $mobile-special-case-1) {
      flex-direction: column;
    }

    .title {
      h1 {
        font-weight: $semi-bold;

        @media (max-width: $mobile-special-case-1) {
          margin-bottom: 10px;
        }
      }
    }

    .skill-and-status {
      @include display-flex(row);

      @media (max-width: $mobile-special-case-1) {
        justify-content: space-between;
      }

      .skill {
        color: $candidate-color;
        font-weight: $semi-bold;

        h3 {
          color: $tgtextcolor;
        }
      }

      .status {
        margin-left: 40px;

        h3 {
          color: $white;
          font-weight: $semi-bold;
          background-color: $custom-red;
          padding: 4px 8px;
          border-radius: 5px;
        }
      }

      .status.shortlisted {
        h3 {
          background-color: $primary-color;
        }
      }
    }
  }

  .designation-and-location {
    @include display-flex(row);

    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .location {
      @media (max-width: $mobile) {
        margin-top: 10px;
      }
    }

    .location div {
      width: 50px;
      height: 6px;
      background-color: $candidate-color;
    }
  }

  .qualification {
    margin-bottom: 10px;
    color: $custom-red;
  }

  .analytics {
    color: $header;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: $semi-bold;
  }

  .top-skills {
    p {
      color: $tgtextcolor;
      background-color: $very-light-grey-3;
      padding: 2px 5px;
      margin: 2px;
      border-radius: 5px;
    }
    div {
      @media (max-width: $mobile) {
        margin-bottom: 10px;
      }
    }
  }

  .applicants,
  .selected-candidates,
  .top-skills,
  .compensation {
    @include display-flex(row);
    color: $header;
    margin: 5px 0;

    @media (max-width: $mobile) {
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }
}
