// Font weight
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

//font-szie
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font16: 16px;
$font15: 15px;
$font18: 18px;
$font20: 20px;
$font24: 24px;
$font30: 30px;
$font34: 34px;

// Color
$main-background: #fafafa;
$white: #fff;
$red: #f00;
$header: #434343;
$sub-header: #8c8c8c;
$gray: #8c8c8c;
$placeholder: #bfbfbf;
$black-text: #141414;
$custom-red: #ff5050;
$custom-orange: #ffbf40;
$custom-green: #47bfa8;
$primary-color: #47bfa8;
$candidate-color: #6963aa;
$candidate-shadow: #dddcea;
$candidate-color-light-shade: #8680c5;
$recruiter-color: #ffbf40;
$organizer-color: #47bfa8;
$label: #141414;
$lightbluecolor: #e0edfd;
$bluecolor: #1890ff;
$ctextcolor: #595959;
$tgtextcolor: #707070;
$black: #000;
$live_color: #f5222d;
$icon_color: #bfbfbf;
$titlefr_color: #262626;
$sheader: #707070;
$sheader2: #1f1f1f;
$light_red: #fbd8d8;
$light_green: #d6eeea;
$very-light-grey: #d4d4d4;
$very-light-grey-2: #f0f0f0;
$dark-yellow: #feb72a;
$recruiter-shadow: #fed27c;
$organizer-shadow: #a3d6cd;
$organizer-shadow-2: #6dc9b7;
$resume-button: #fc0;
$badge-color: #717fff;
$very-light-grey-3: #f5f5f5;
$very-light-grey-4: #d9d9d9;
$very-light-grey-5: #f3f3f3;
$medium-light-grey: #efefef;
$gray-2: #a2acab;
$gray-3: #9f9f9f;
$gray-4: #515151a6;
$custom-purple: #6d597a;
$recruit-tab: #d9d9d9;
$success-color: #49c0a9;
$notifications-orange: #f3d2c0;
$notifications-red: #b86b7c;
$notifications-voilet: #6f5b7b;
$notifications-blue: #675cc8;

//shadow
$shadow: 0 0 10px #0000001a;
$shadow2: 0 2px 10px #00000029;
$shadow3: 0 2px 3px #00000029;

//radius
$bradius: 10px;
$bradius5: 5px;
$bradius3: 3px;
$bradius10: 10px;
$bradius12: 12px;

//breakpoint
$mobile-special-case-1: 525px; //Using for alignment of buttons in Quick Start page.
$tablet-special-case-1: 970px; //Using for changing the layout and adjusting the size of inout in Quick Start page.
$tablet-special-case-2: 886px; //Using to adjust the overlapping of delete button in quick start page.
$desktop-special-case-1: 1900px; //Using to adjust the margin of span of job types in Candidate Jobs Page.
$mobile: 767px;
$desktop-min: 1600px;
$laptop-min: 1201px;
$laptop-max: 1599px;
$laptop-special-case-1: 1000px;
$laptop-special-case-2: 1350px; //Using to adjust the overlapping of delete button in quick start page.
$mobile-special-case-2: 400px;
$mobile-special-case-3: 320px;
$tablet-min: 768px;
$tablet-max: 1200px;

.h43 {
  height: 43px !important;
}

//margin
//
.ml5 {
  margin-left: 5px;
}

.mb53 {
  margin-bottom: 53px;
}

.mb90 {
  margin-bottom: 90px;
}

.mt51 {
  margin-top: 51px !important;
}

.mt35 {
  margin-top: 35px;
}

.mr25 {
  margin-right: 25px !important;
}

.mt70 {
  margin-top: 70px;
}

.mr70 {
  margin-right: 70px !important;
}

.mt40 {
  margin-top: 40px;
}

.mb35 {
  margin-bottom: 35px;
}

.mr0 {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

button {
  outline: none;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.over_h {
  overflow: hidden;
}

.flex_end {
  justify-content: flex-end;
}

.full-width {
  width: 100%;
}

.primary-color {
  color: $primary-color;
}

.background-notifications-orange {
  background-color: $notifications-orange;
}

.background-notifications-red {
  background-color: $notifications-red;
}

.background-notifications-voilet {
  background-color: $notifications-voilet;
}

.background-notifications-blue {
  background-color: $notifications-blue;
}

.background-primary {
  background-color: $primary-color;
}

.background-blue {
  background-color: $bluecolor;
}

.background-red {
  background-color: $custom-red;
}

.background-gray-2 {
  background-color: $gray-2;
}

.background-orange {
  background-color: $custom-orange;
}

.candidate-color-background {
  background-color: $candidate-color;
}

.color-primary {
  color: $primary-color;
}

.color-blue {
  color: $bluecolor;
}

.color-red {
  color: $custom-red;
}

.color-gray-2 {
  color: $gray-2;
}

.color-orange {
  color: $custom-orange;
}

.candidate-color {
  color: $candidate-color;
}

.heading-gray {
  color: $ctextcolor;
}
