@import '../../variables.scss';

.recruiter_events,
.candidate_events {
  .ant-modal-close-x {
    background-color: $white;
  }
  .book-event {
    &__title {
      font-weight: $semi-bold;
      font-size: 24px;
      margin-bottom: 3rem;
    }

    &__tickets {
      color: $ctextcolor;
      font-size: 18px;
      font-weight: $semi-bold;
    }

    &__ticket-data {
      display: flex;
      align-items: center;
      margin-bottom: 5rem;

      & > .ant-select {
        width: 45rem;
        color: $black;
      }

      p {
        margin-left: 2rem;
        font-weight: $bold;
      }
    }

    &__billing-address {
      margin-bottom: 4rem;
      max-width: 1000px;

      .fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        div {
          width: 300px;
        }
      }
      h3 {
        color: $tgtextcolor;
        font-size: 18px;
      }

      &--text-area {
        width: 50rem;
        margin-bottom: 2rem;
        margin-right: 2rem;
        color: $black;
        font-size: 1.6rem;
      }

      &--add-button {
        border: none;
        color: black;
        margin-left: -1rem;
        font-size: 18px;
        margin-bottom: 1rem;
      }
    }

    &__promo-code {
      color: $tgtextcolor;
      font-size: 18px;

      &--flex {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2rem;
        justify-content: space-between;
        width: 340px;

        button {
          margin-left: 15px;
        }
      }
    }

    &__ticket-cost {
      font-weight: $semi-bold;
      margin-bottom: 4rem;
    }

    &__continue-button {
      display: flex;
      justify-content: flex-end;

      & > .ant-btn {
        color: $white;
        width: 22rem;
        border: none;
        border-radius: 5px;
      }
    }
  }
}

.recruiter_events {
  .book-event {
    &__title {
      color: $recruiter-color;
    }

    &__ticket-data {
      p {
        color: $recruiter-color;
      }
    }

    &__continue-button {
      & > .ant-btn {
        background-color: $recruiter-color;
      }
    }
  }
}

.candidate_events {
  .book-event {
    &__title {
      color: $candidate-color;
    }

    &__ticket-data {
      p {
        color: $candidate-color;
      }
    }

    &__continue-button {
      & > .ant-btn {
        background-color: $candidate-color;
      }
    }
  }
}

.book-event__billing-address-list-container {
  list-style-type: none;
}

.book-event__billing-address-list-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid grey;
  margin-top: 15px;
  border-radius: 5px;
  padding: 20px 15px;
  width: 50%;

  .book-event__address-name {
    color: $sub-header;
    font-weight: $medium;
  }

  .address-text {
    margin-right: 5px;
  }
}

.book-event__address-selection {
  display: grid;
  place-items: center;

  .book-event__check-circle-icon > svg {
    font-size: 30px;
  }
}

.hidden {
  visibility: hidden;
}

.recruiter {
  color: $recruiter-color;
}

.candidate {
  color: $candidate-color;
}

.book-event__promo-btn,
.book-event__add-button,
.book-event__address-submit-btn {
  color: $white;
  border-radius: 4px;
  padding: 0 30px;
}

.book-event__address-submit-btn {
  margin-top: 20px;
}

.recruiter-btn {
  background-color: $recruiter-color;

  &:hover {
    background-color: $recruiter-color;
    color: $white;
  }
}

.candidate-btn {
  background-color: $candidate-color;

  &:hover {
    background-color: $candidate-color;
    color: $white;
  }
}

.new-billing-address-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.book-event__add-new-address {
  margin-top: 30px;
}

.book-event__new-billing-heading {
  margin-bottom: 30px;
}
