.organizer__search {
  @include display-flex(row);

  justify-content: flex-end;
}

.organizer__sub-heading {
  font-weight: $semi-bold;
}

.organizer__verify-button {
  &,
  &:hover,
  &:active {
    @include display-flex(row);

    background-color: $primary-color;
    align-items: center;
    justify-content: center;

    span {
      color: white;
    }
  }
}

.organizer__verified-text {
  font-weight: $semi-bold;
  color: $primary-color;
}

.title-color {
  div.ant-page-header-heading {
    div.ant-page-header-heading-left {
      span.ant-page-header-heading-title {
        color: $primary-color;
      }
    }
  }
}

.side-menu.admin {
  .fixed_side li:hover img,
  .fixed_side li:visited img,
  .fixed_side li.ant-menu-item-active img,
  .fixed_side li.ant-menu-item-selected img {
    filter: invert(66%) sepia(14%) saturate(1445%) hue-rotate(119deg)
      brightness(97%) contrast(91%);
  }
}

.admin-organizer-table {
  cursor: pointer;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
  }
}

.crew-members-list-admin {
  margin-top: 40px;
}

.admin-crew-members__add {
  margin-left: 20px;
}
