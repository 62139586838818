.calendar__container {
  box-shadow: 0 0 10px #0000001a;
  width: 100%;
  z-index: 1;
  position: relative;
  background: #fff;
  padding: 20px;
  margin-top: 20px;

  .ant-select-arrow {
    margin-top: -9px;

    span {
      svg {
        width: 16px;
      }
    }
  }
}

.calendar__wrapper {
  &__title {
    font-weight: $semi-bold;

    padding: 0 5px;
  }
  &__information {
    display: flex;
    flex-direction: row;
    align-items: center;
    &__circle--left {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin-right: 5px;
    }
  }
  &__calendar-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;

    &__right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__button {
      div {
        display: flex;
        flex-direction: row;

        label {
          width: 95px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .disclaimer-box {
    text-align: left;
    padding: 10px 7px;

    .disclaimer {
      @include medium-font-mixin;

      color: $gray;
      margin-bottom: 10px;
    }
  }
}

.calendar-meta-information__wrapper {
  display: flex;
  flex-direction: row;

  &__container {
    @include large-font-mixin();

    display: flex;
    flex-direction: row;
    align-items: center;
    color: $sub-header;
    margin-right: 0;

    &:not(:first-child) {
      margin-left: 15px;
    }

    &__circle--left {
      width: 14px;
      height: 14px;
      margin-right: 5px;
      margin-left: 5px;
      border-radius: 100%;
    }
  }
}

.candidate_calendar {
  .circle_box {
    background-color: $candidate-color;
  }

  h3 {
    color: $candidate-color;
  }

  .calendar__container .calendar__wrapper {
    &__calendar-header {
      &__button {
        div {
          label:active,
          label:hover,
          label.ant-radio-button-wrapper-checked {
            color: $candidate-color;
            border-color: $candidate-color;
          }
        }
      }
    }
  }
  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-top: 7px solid #f0f0f0;
    border-color: $candidate-color;
    margin-top: 4px;
  }
}

.calendar-view-modal__header {
  font-weight: medium;
}

.organizer_calendar {
  .circle_box {
    background-color: $primary-color;
  }

  h3 {
    color: $primary-color;
  }

  .calendar__container .calendar__wrapper {
    &__calendar-header {
      &__button {
        div {
          label:active,
          label:hover,
          label.ant-radio-button-wrapper-checked {
            color: $primary-color;
            border-color: $primary-color;
          }
        }
      }
    }
    .ant-picker-calendar-full
      .ant-picker-panel
      .ant-picker-calendar-date-today {
      border-top: 7px solid #f0f0f0;
      border-color: $primary-color;
      margin-top: 4px;
    }
  }

  button {
    margin-top: 12px;

    @media (max-width: $laptop-max) {
      margin-top: 1px;
    }

    @media (max-width: $tablet-max) {
      margin-top: 0px;

      width: 100px;
      height: 40px;
    }
  }

  #event_title.head_sec_title .search_input {
    width: 250px;
    margin-top: 10px;

    @media (max-width: $laptop-max) {
      margin-top: 3px;
    }

    @media (max-width: $tablet-max) {
      margin-top: 0px;
    }

    @media (max-width: $mobile) {
      margin-bottom: 25px;
    }
  }
}

.recruiter_calendar {
  .circle_box {
    background-color: $recruiter-color;
  }

  h3 {
    color: $recruiter-color;
  }

  .calendar__container .calendar__wrapper {
    &__calendar-header {
      &__button {
        div {
          label:active,
          label:hover,
          label.ant-radio-button-wrapper-checked {
            color: $recruiter-color;
            border-color: $recruiter-color;
          }
        }
      }
    }
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
    border-top: 7px solid #f0f0f0;
    border-color: $recruiter-color;
    margin-top: 4px;
  }
}

.calendar__container .anticon svg {
  display: inline-block;
  width: unset;
  height: unset;
}

@media (max-width: $mobile) {
  .calendar-meta-information__wrapper {
    margin-bottom: 10px;
    width: 100%;

    &__container {
      @include small-font-mixin();

      &__circle--left {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        margin-left: 5px;
        border-radius: 100%;
      }
    }

    &__calendar-header {
      margin-bottom: 20px;
      justify-content: flex-start;

      &__button {
        div {
          label {
            width: 70px;
          }
        }
      }
    }
  }

  .calendar__container {
    padding: 10px;
    margin-top: 0;
  }
}

@media (min-width: $desktop-min) {
  .calendar-meta-information__wrapper {
    &__container {
      margin-right: 10px;

      &__circle--left {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}

.calendar-view-modal__container__add {
  @include display-flex(row);

  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}

.recruiter_calendar,
.candidate_calendar,
.organizer_calendar {
  #event_title.head_sec_title .search_input {
    @media (max-width: 414px) {
      width: 55%;
    }
  }
}
