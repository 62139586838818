.profile-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  // padding-bottom: 4rem;

  .profile-links-btn {
    margin-right: 1rem;
  }

  .btn {
    width: 4rem;
    height: 4rem;
    border-radius: 1.2rem;
    background-color: $very-light-grey-3;
    border: none;
    color: $placeholder;
    padding: 0;

    &:hover {
      color: $color_text_2;
    }

    .btn-icon {
      filter: grayscale(100%);
      // width: 1.5rem;
      height: 1.5rem;
    }

    .global-outlined {
      color: $gray;
    }
  }
}

.profile-link-list__delete {
  display: grid;
  place-items: center;
  padding-left: 0;
  margin-left: -10px;
  margin-top: 0;

  &.default {
    height: 32px;
  }

  &.large {
    height: 40px;
  }

  @media (min-width: $desktop-min) {
    margin-left: -20px;
  }

  @media (max-width: $mobile) {
    margin-top: 0;
  }

  &__button {
    display: grid;
    place-items: center;
  }

  .minus-circle {
    display: grid;
    place-items: center;
  }
}

.editpen.delete-icon svg {
  width: 25px;
  font-size: 25px;
  fill: $custom-red;
}

.create_event_sec {
  .upload_sec {
    & > span {
      min-width: 240px;
    }
  }
}

.skill-remove-column {
  svg {
    font-size: 24px;
    margin-top: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}
