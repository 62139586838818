.job-opportunities {
  margin-bottom: 30px;

  &__choice {
    margin-bottom: 20px;

    button {
      border: none;
    }

    .active {
      color: $candidate-color;
      font-weight: $medium;
      border-bottom: 4px solid $candidate-color;
      margin-right: 80px;
    }

    .inactive {
      color: $gray;
      margin-right: 80px;
    }

    span {
      @include special-case-3-font-mixin;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $candidate-color;
      }
    }

    .ant-tabs-tab-btn {
      color: $gray;
      font-weight: $medium;
    }
  }

  .select-event {
    .ant-select-selector {
      .ant-select-selection-item {
        font-size: $font14;
      }
      .ant-select-selection-placeholder {
        font-size: $font14;
      }
    }
  }

  .ant-select-arrow {
    margin-top: -9.5px;

    svg {
      width: 16px;
    }
  }

  .ant-pagination-item-link {
    span {
      margin-top: 5px;

      svg {
        width: 16px;
      }
    }
  }

  .choose-event {
    margin-bottom: 20px;
  }

  &__title {
    background-color: $very-light-grey-2;
    border-radius: 5px;
    padding: 8px 15px;
    margin-bottom: 30px;
    margin-top: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0 2px 4px -1px;

    h1 {
      font-size: $font15;
      color: $ctextcolor;
      font-weight: 500;
      padding-top: 10px;
      padding-left: 20px;
    }
  }

  .event-select {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .select-event {
      margin-top: -10px;
    }
  }

  &__container {
    @include display-flex(row);

    padding: 16px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 6px;
    transition: 0.6s;
    border: 1px solid transparent;

    @media (min-width: $desktop-min) {
      padding: 25px;
      margin-bottom: 25px;
    }

    @media screen and(max-width: $mobile) {
      flex-direction: column;
      height: auto;
    }

    &:hover {
      border: 1px solid $candidate-color;
    }

    .title-and-types {
      justify-self: center;

      @media screen and(max-width: $mobile) {
        justify-self: self-start;
      }

      .title {
        @include display-flex(row);

        min-height: 24px;

        @media (min-width: $desktop-min) {
          min-height: 40px;
        }

        img {
          width: 16px;
          align-self: start;
          margin-right: 10px;
          margin-top: 0;
          filter: invert(39%) sepia(35%) saturate(654%) hue-rotate(205deg)
            brightness(97%) contrast(94%);

          @media (min-width: $desktop-min) {
            margin-top: 4px;
            width: 18px;
          }
        }

        h2 {
          @include special-case-font-mixin;

          color: $candidate-color;
          font-weight: $semi-bold;
        }
      }

      .types {
        @include display-flex(row);

        margin-top: 25px;
        align-items: center;

        @media (max-width: $mobile-special-case-2) {
          flex-wrap: wrap;
        }

        p {
          @include special-case-2-font-mixin;

          font-weight: $medium;
          margin-right: 30px;
          color: $ctextcolor;

          @media (max-width: $mobile-special-case-2) {
            margin-bottom: 10px;
          }
        }

        div {
          white-space: nowrap;

          span {
            @include special-case-2-font-mixin;

            color: $tgtextcolor;
            background-color: $very-light-grey-3;
            margin-right: 10px;
            border-radius: 4px;
            padding: 5px 10px;
            font-weight: $medium;
            margin-bottom: 5px;

            @media (max-width: $mobile) {
              margin-left: 12px;
            }

            @media (min-width: $desktop-min) {
              padding: 5px 30px;
            }
          }
        }
      }
    }

    .location-and-engagement {
      @media (max-width: $mobile) {
        @include display-flex(column-reverse);
      }

      h3 {
        @include special-case-3-font-mixin;
        word-break: break-all;
      }

      .location {
        @include display-flex(row);

        align-self: start;
        min-height: 24px;

        @media (min-width: $desktop-min) {
          min-height: 40px;
        }

        @media screen and(max-width: $mobile) {
          flex-direction: column;
          grid-row: 1;
          margin: 10px 0;
        }

        div {
          @include display-flex(row);

          align-items: flex-start;

          & > img {
            align-self: start;
            width: 18px;
            margin-right: 10px;
            margin-top: -5px;

            @media (min-width: $desktop-min) {
              width: 25px;
            }

            @media (max-width: $mobile) {
              margin-top: 2px;
            }
          }
        }
      }

      .engagement {
        @include display-flex(row);

        margin-top: 25px;
        margin-left: 10px;

        @media screen and(max-width: $mobile) {
          margin: 10px -10px;
        }

        @media (max-width: $mobile-special-case-2) {
          flex-wrap: wrap;
        }

        @media (max-width: $laptop-max) {
          margin-top: 35px;
          margin-left: 2.5px;
        }

        @media (min-width: $desktop-min) {
          margin-top: 30px;
        }

        p {
          @include special-case-2-font-mixin;

          font-weight: $medium;
          margin-right: 20px;
          margin-top: -5px;

          @media (max-width: $mobile-special-case-2) {
            margin-bottom: 20px;
          }
        }

        div {
          @include display-flex(row);

          flex-wrap: wrap;
          margin-top: -12px;

          & > span {
            @include special-case-2-font-mixin;

            color: $tgtextcolor;
            background-color: $very-light-grey-3;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
            font-weight: $medium;
            padding: 5px 30px;
            white-space: nowrap;

            @media (max-width: $mobile-special-case-1) {
              white-space: normal;
            }
          }
        }
      }
    }

    .skills-and-apply__container {
      @include display-flex(row-reverse);

      @media (max-width: $mobile) {
        // display: block;
      }
    }

    .skills-and-apply {
      @include display-flex(row);

      align-self: center;

      button {
        width: 150px;
      }

      @media screen and(max-width: $mobile) {
        justify-content: space-between;
        margin-top: -80px;
      }

      @media (max-width: $mobile-special-case-2) {
        flex-direction: row;
      }

      .skills {
        margin-right: 30px;
        justify-self: self-start;

        @media (max-width: $mobile-special-case-1) {
          margin-bottom: 30px;
        }

        p:nth-child(1) {
          @include special-case-3-font-mixin;

          color: $tgtextcolor;
          margin-bottom: 10px;
          font-weight: $semi-bold;
        }

        p:nth-child(2) {
          @include medium-large-heading-mixin;

          color: $candidate-color;
          font-weight: $bold;
        }
      }

      .apply {
        justify-self: self-end;
        @include display-flex(column);
        align-items: center;

        &-button {
          background-color: $candidate-color;
          color: $white;
          border: none;
          border-radius: 3px;
          padding: 0 30px;
          margin-bottom: 10px;

          @media (max-width: $mobile-special-case-2) {
            padding: 0 20px;
          }

          @media (max-width: $mobile-special-case-3) {
            padding: 0 10px;
          }

          @media (min-width: $desktop-min) {
            margin-bottom: 20px;
          }

          span {
            @include special-case-font-mixin;
          }
        }

        .applied {
          color: $custom-green;
          font-weight: $semi-bold;
          border: none;
          border-radius: 3px;
          padding: 0 30px;
          margin-bottom: 10px;
          cursor: not-allowed;
          pointer-events: none;

          @media (min-width: $desktop-min) {
            margin-bottom: 20px;
          }
        }

        p {
          @include special-case-font-mixin;

          color: $gray-2;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .pagination {
    @include display-flex(row);

    justify-content: flex-end;
    margin: 20px 0;
  }

  hr {
    border: 0.5px dashed $gray-2;
  }
}
