.edit-event__cancel-btn-container {
  background-color: $white;
  padding: 20px 0;
  box-shadow: $shadow2;
  margin-bottom: 40px;

  .edit-event__cancel-event-btn {
    padding: 4px 30px;
    border-radius: $bradius3;
    color: $white;
    background-color: $custom-red;
    margin-right: 20px;

    &:hover {
      border: 0;
    }
  }
}

.breadcrumb-wrapper {
  padding-top: 30px;
  padding-bottom: 20px;

  ul li {
    color: $custom-green;
  }
}
