.responsive-modal-header__container {
  height: 120px;
  display: none;
  background-color: $primary-color;
  position: relative;
  flex-direction: column;

  &.danger {
    background-color: $custom-red;
  }

  &.purple {
    background-color: $candidate-color;
  }

  &.yellow {
    background-color: $recruiter-color;
  }

  &__logo__title--logo {
    width: 127px;
    height: 22px;
  }

  &__logo__title--title {
    font-size: 12px;
    text-align: left;
    margin-left: 35px;
    letter-spacing: 0;
    color: $white;
    opacity: 0.5;
  }

  &__logo__title__container {
    padding: 20px;
    width: fit-content;
    margin-right: 0;
    float: left;
    right: -10px;
  }

  &__sideLogo {
    margin-right: 0;
    float: right;
    position: relative;
    align-self: right;
    right: 0;
    width: fit-content;
  }

  &__sideLogo--right {
    width: 76px;
    height: 84px;
    opacity: 0.5;
  }

  &__arrow {
    width: 24px;
    height: 22px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
      brightness(118%) contrast(119%);
    transform: scale(-1, -1);
    transform: rotate(180);
    transform-origin: 'center';
    opacity: 0.7;
  }

  &__arrow__container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.OrganizerCompanyInformationModal .responsive-modal-header__container,
.OrganizerRepresentativeModal .responsive-modal-header__container,
.OrganizerCrewManagementModal .responsive-modal-header__container {
  background-color: $primary-color;
}

.RecruiterCompanyInformationModal .responsive-modal-header__container,
.RecruiterRepresentativeModal .responsive-modal-header__container,
.RecruiterCrewManagementModal .responsive-modal-header__container,
.RecruiterCrewManagementModal .submit-button {
  background-color: $recruiter-color;
}

.adminCrewManagementModal .responsive-modal-header__container,
.adminCrewManagementModal .submit-button {
  background-color: $bluecolor;
}

.PersonDetailModal .responsive-modal-header__container {
  background-color: $candidate-color;
}

@media (max-width: $mobile) {
  .responsive-modal-header__container {
    display: flex;
  }
}

.sub-header__block {
  @include display-flex(row);

  justify-content: space-between;
  flex-wrap: wrap;

  .change-password {
    margin-bottom: 70px;

    &__button {
      border: none;
      background-color: $very-light-grey-2;
      width: 275px;
      height: 39px;

      @media screen and (max-width: $mobile) {
        width: 180px;
      }

      span {
        @include extra-large-font-mixin;

        font-weight: $semi-bold;
        color: $ctextcolor;
      }
    }
  }
}
