.create-job__steps {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  @media (max-width: $mobile) {
    display: none;
  }

  .ant-steps-item-icon {
    margin: 0 16px 0 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-color: $recruiter-color;
  }

  .ant-steps-item-title {
    @include medium-large-heading-mixin;

    color: $titlefr_color;
    font-weight: $medium;
    margin-bottom: 10px;
  }
  // Overriding ant default blue colour of ant-class
  .ant-steps-item-title::after {
    background-color: $tgtextcolor !important;
    opacity: 0.4;
  }

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background: $recruiter-color;
      border-color: $recruiter-color;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      span {
        color: $recruiter-color;
      }
    }
  }
}

.create-job__header {
  color: $recruiter-color;
  margin-top: 20px;
  margin-bottom: 20px;
}

.basic-info {
  &__container {
    position: relative;
    height: min-content;
    z-index: 1;
    background: $white;
    box-shadow: $shadow;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__heading {
    @include medium-heading-mixin;

    color: $recruiter-color;
    font-weight: $medium;
    margin: 26px;
  }

  &__sub-heading {
    @include small-heading-mixin;

    color: $sub-header;
    font-weight: $medium;
    margin: 26px;
  }

  &__job-title {
    @include small-heading-mixin;

    color: $header;
    margin-left: 26px;
    margin-top: 36px;
    font-weight: $medium;
  }

  &-mandatory__switch {
    color: $recruiter-color;
    font-weight: $medium;
    margin-left: 10px;
  }

  &-preffered__switch {
    color: $tgtextcolor;
    font-weight: $medium;
    margin-left: 10px;
  }
}

.basic-info-job-title__input-box {
  margin-left: 26px;
  margin-top: 16px;
  color: $placeholder;
  font-weight: $semi-bold;
}

.set-skills {
  @media (max-width: $mobile) {
    display: flex;
    justify-content: space-between;
  }
}

.basic-info-skill-set {
  &__heading {
    @include small-heading-mixin;

    color: $recruiter-color;
    font-weight: $medium;
    margin: 26px;
    margin-top: 30px;
  }

  &__button {
    margin-left: 26px;
    width: min-content;
    margin-top: 16px;

    .ant-btn,
    .ant-btn-dashed {
      padding-left: 80px;
      padding-right: 80px;

      @media (max-width: $mobile) {
        width: 90%;
      }

      @media (max-width: $mobile-special-case-2) {
        width: 88%;
      }
    }
  }

  &__description {
    @include large-font-mixin;

    margin-top: 16px;
    color: $sub-header;
    font-weight: $medium;

    @media (max-width: $mobile) {
      margin: 0 auto;
      text-align: center;
    }
  }
}

// .basic-info-skill-set__button {
//   margin: 0 auto;
//   width: 100%;
// }

.basic-info-any-skill__dashed-button {
  margin-top: 26px;
  @media (max-width: $mobile) {
    margin-right: 30px;
  }

  .ant-btn,
  .ant-btn-dashed {
    padding-left: 20px;
    padding-right: 20px;
    color: $recruiter-color;
    border-color: $recruiter-color;
  }
  .any-skill__tooltip {
    background-color: $recruiter-color;
    color: $white;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none;
  }
}

.add-skill-set__select {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 20px;
  margin-bottom: 40px;
}

.skill-set {
  @include small-heading-mixin;
  &-value__column {
    width: min-content;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;

    @media (max-width: $mobile) {
      width: 96%;
      padding-left: 0;
    }

    @media (max-width: $mobile-special-case-2) {
      width: 86vw;
    }
  }

  &-step-level__column {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;

    @media (max-width: $mobile-special-case-1) {
      margin-left: -30px;
    }

    .ant-steps-item-icon {
      background-color: transparent;
      color: $recruiter-color;
      border: 2px solid $recruiter-color;
    }

    .ant-steps-item-tail {
      margin-top: -16px;
      padding: 4px 12px;
    }

    .ant-steps-item-tail::after {
      background-color: $black;
      height: 4px;
    }

    .ant-steps-item-process .ant-steps-item-icon {
      color: $white;
      background-color: $recruiter-color;
      border-radius: 50%;
      .ant-steps-icon {
        color: $white;
        background-color: $recruiter-color;
      }
    }
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        span {
          color: $recruiter-color;
        }
      }

      .ant-steps-item-tail::after {
        background-color: $recruiter-color;
        height: 2px;
      }
    }
  }

  &-delete__column {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 10px;

    .minus-circle {
      color: $custom-red;
      // Overriding ant default icon size
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  &-preferred__column {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 10px;

    .ant-switch-checked {
      background-color: $recruiter-color;
    }
  }
}

.create-job__steps-action {
  margin-top: 34px;

  .next_btn {
    background-color: $recruiter-color;
    color: $white;
    margin-right: 20px;
    cursor: pointer;

    @include large-font-mixin;

    line-height: 27px;
    max-width: 168px;
    width: 100%;
    height: 43px;
    border: none;
  }

  .previous_btn {
    margin-left: 20px;
    border: 1px solid $gray;
    color: $gray;
    background-color: $white;
    cursor: pointer;
  }

  .sve_con {
    max-width: 195px;
    width: 100%;
  }
}
.recruiter__tooltip {
  .ant-tooltip-placement-top {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      background: $recruiter-color;
      color: $white;
      font-weight: 500;
      font-size: 14px;
      width: min-content;
    }
  }
}

@media (min-width: $desktop-min) {
  .skill-set {
    &-value__column {
      width: min-content;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      padding-left: 20px;
    }

    &-step-level__column {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;

      .ant-steps-item-icon {
        background-color: transparent;
        color: $recruiter-color;
        border: 2px solid $recruiter-color;
      }

      .ant-steps-item-tail {
        margin-top: -16px;
      }

      .ant-steps-item-tail::after {
        background-color: $black;
        height: 4px;
      }

      .ant-steps-item-finish {
        .ant-steps-item-icon {
          span {
            color: $recruiter-color;
          }
        }

        .ant-steps-item-tail::after {
          background-color: $recruiter-color;
          height: 2px;
        }
      }
    }

    &-delete__column {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 30px;
      padding-left: 10px;
      // Overriding ant default icon size
      .minus-circle {
        color: $custom-red;

        svg {
          width: 25px !important;
          height: 25px !important;
        }
      }
    }

    &-preferred__column {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      padding-left: 10px;

      .ant-switch-checked {
        background-color: $recruiter-color;
      }
    }
  }
}

// todo media query for mobile version

.salary-checkbox {
  &.job-description__sub-heading {
    margin-top: -10px;
  }

  &--left {
    margin-right: 10px;
  }
}
