.date-picker-custom {
  .ant-picker-panel-container {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (max-width: 768px) {
    .ant-picker-panels {
      display: flex;
      flex-direction: column;
    }
  }
}
