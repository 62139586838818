//TODO: Need to add Media Queries for Mobile screens.
%purple-text {
  color: $candidate-color;
}

.job-details__container {
  h1 {
    color: $ctextcolor;
  }
  .current-path {
    @extend %purple-text;

    margin-bottom: 20px;

    p {
      @include special-font-mixin;
    }
  }

  .job-details {
    &__header {
      @include display-flex(row);

      justify-content: space-between;
      padding-bottom: 20px;

      @media (max-width: $mobile) {
        @include display-flex(row);
        align-items: center;
      }

      @media (min-width: $desktop-min) {
        padding-bottom: 40px;
      }

      &__title {
        @media (max-width: $mobile) {
          padding-bottom: 30px;
          padding-top: 10px;
        }
      }

      &__skills-match__apply-job {
        @include display-flex(row);

        @media (max-width: $mobile) {
          justify-content: space-between;
        }

        .skills-match {
          p {
            @extend %purple-text;

            font-weight: $bold;
          }
        }

        .apply-job {
          padding: 0 20px;
          margin-left: 35px;
          border-radius: 5px;
          .apply-button {
            background-color: $candidate-color;
            color: $white;
            width: 120px;
            height: 38px;
            font-weight: $medium;
            border-radius: 5px;

            span {
              font-size: $font14;
            }

            @media (min-width: $desktop-min) {
              width: 150px;
            }
          }
        }

        .applied {
          background-color: $white;
          color: $custom-green;
          border: none;
          font-weight: $semi-bold;
        }
      }
    }

    &__job-title__location__logo {
      @include display-flex(row);

      justify-content: space-between;
      margin: 30px 0;

      @media (max-width: $mobile) {
        @include display-flex(column-reverse);
      }

      @media (min-width: $desktop-min) {
        margin-bottom: 40px;
      }
      .job-title {
        @media (max-width: $mobile) {
          margin-top: 40px;
        }

        h1,
        p {
          font-size: $font14;

          @media (max-width: $mobile) {
            font-size: $font12;
          }
        }

        p {
          @media (max-width: $mobile) {
            font-weight: $medium;
          }
        }
      }

      p {
        @include special-font-mixin;
      }

      .location__logo {
        @include display-flex(row);
        margin-right: 25px;

        @media (max-width: $mobile) {
          justify-content: space-between;
        }

        .location {
          //This is used to make the border-bottom with custom width.
          .title::after {
            content: '';
            display: block;
            width: 30%;
            padding-top: 3px;
            border-bottom: 5px solid $candidate-color;
          }

          p {
            cursor: pointer;
          }

          h1 {
            font-size: $font14;
          }

          .company-details {
            margin: 10px 0;
            padding: 4px 0;
            cursor: pointer;
            color: $candidate-color;
            border: 1px solid $candidate-color;
            text-align: center;
          }

          .company-details:hover {
            color: $bluecolor;
          }
        }

        .logo {
          img {
            width: 80px;
            height: 80px;
            margin-left: 30px;
          }
        }
      }
    }

    &__sub-details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
      margin-bottom: 30px;

      @media (max-width: $mobile) {
        @include display-flex(column);
      }

      .highlighted-text {
        white-space: nowrap;
      }

      .min-work-experience {
        p {
          & > div {
            font-size: $font14;
          }
        }
      }
    }

    &__industry__job-type__engagement {
      @include display-flex(row);

      justify-content: space-between;
      margin-bottom: 25px;

      @media (min-width: $desktop-min) {
        margin-bottom: 40px;
      }

      .job-type {
        margin-left: -40px;
      }
    }

    &__number-of-positions__min-work-experience__min-education {
      @include display-flex(row);

      justify-content: space-between;
      margin-bottom: 20px;

      @media (min-width: $desktop-min) {
        margin-bottom: 40px;
      }

      .min-work-experience {
        @media screen and (max-width: $laptop-max) {
          margin-right: 30px;
        }
      }

      .min-education {
        margin-right: 100px;

        @media screen and (max-width: $laptop-max) {
          margin-right: 80px;
        }
      }

      p {
        font-size: $font12;
        color: $label;
      }
    }

    &__pay {
      margin-bottom: 20px;

      @media (min-width: $desktop-min) {
        margin-bottom: 40px;
      }

      p {
        font-size: $font14;

        color: $label;
      }
    }

    &__required-skill-sets {
      margin-bottom: 20px;
      width: 30vw;
      margin-top: 20px;

      @media (min-width: $desktop-min) {
        margin-bottom: 40px;
      }

      .title {
        margin-bottom: 20px;
      }

      .skills {
        @include display-flex(row);

        justify-content: space-between;

        div {
          @include display-flex(row);
          justify-content: space-between;
          width: 280px;
        }

        p {
          @extend %purple-text;

          @include special-font-mixin;

          font-weight: $semi-bold;
        }
      }
    }

    &__job-description {
      margin-bottom: 40px;
    }

    &__tag {
      margin-bottom: 20px;
    }

    .title {
      @include large-font-mixin;

      font-weight: $semi-bold;
    }

    .highlighted-text {
      font-size: $font12;
      background-color: $very-light-grey-3;
      padding: 5px 15px;
      border-radius: 5px;
      color: $label;
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
}

.success {
  color: $white;
  width: 30vw;
}

//TODO: Remove ant class with custom class later.
//Overriding ant class otherwise styles are not being applied. Even after wrapping in a container and defining the component with a className.
.ant-message {
  margin-top: 50px;

  div.ant-message-notice > div.ant-message-notice-content {
    border-radius: 8px;
    background-color: $success-color;
    color: $white;

    svg {
      width: 30px;
      height: 30px;
      fill: $white;
    }
    div.ant-message-custom-content {
      @include display-flex(row);
      align-items: center;
    }
  }
}

.job-details__header__title {
  font-weight: $semi-bold;
}

.job-profile-box__industry_type {
  max-width: 350px;
  @media (min-width: $desktop-min) {
    max-width: 470px;
  }
}

.upload-certification-hint {
  margin-top: 5px;
  @media (min-width: $desktop-min) {
    margin-top: 0px;
  }
}
