$candidate-color: #6963aa;
$recruiter-color: #ffbf40;
.candidate-res-menu .ant-dropdown-link a {
  color: $candidate-color;
  font-size: 16px;
}

.candidate-res-menu a .img_frnt svg {
  width: 26px;
  margin-right: 15px;
  position: relative;
  top: 6px;
  fill: $candidate-color;
}

.candidate-res-menu a svg {
  fill: $candidate-color;
}
.recruiter-res-menu .ant-dropdown-link a {
  color: $recruiter-color;
  font-size: 16px;
}

.recruiter-res-menu a .img_frnt svg {
  width: 26px;
  margin-right: 15px;
  position: relative;
  top: 6px;
  fill: $recruiter-color;
}
.recruiter-res-menu a svg {
  fill: $recruiter-color;
}
.organizer-res-menu .ant-dropdown-link a {
  color: $primary-color;
  font-size: 16px;
}

.organizer-res-menu a .img_frnt svg {
  width: 26px;
  margin-right: 15px;
  position: relative;
  top: 6px;
  fill: $primary-color;
}
.organizer-res-menu a svg {
  fill: $primary-color;
}

.Xstyle {
  width: 17px;
  height: 18px;
  font-size: 20px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  color: $tgtextcolor;
  font-weight: bolder;
  filter: $tgtextcolor;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1);

  stroke-width: 20px;
  stroke: '#000000';
}
.closeStyle {
  color: $tgtextcolor;
  font-size: 16px;
}
.alignRow {
  align-content: center;
  align-items: center;
  margin-top: 8px;
}
.organizerwelcome {
  color: $primary-color;
  font-size: 20px;
}

.recruiterwelcome {
  color: $recruiter-color;
  font-size: 20px;
}

.candidatewelcome {
  color: $candidate-color;
  font-size: 20px;
  font-weight: $semi-bold;
}
.viewProfile {
  color: $gray-2;
  font-size: 16px;
  margin-top: 4px;
}

.mt20 {
  margin-top: 20px;
}

.responsive-menu__item {
  img {
    margin-right: 5px;
    position: relative;
    fill: $placeholder;
    width: 28px;
    margin-right: 20px;
    filter: invert(79%) sepia(1%) saturate(2258%) hue-rotate(24deg)
      brightness(99%) contrast(87%);
  }
}
.responsive-menu__item a {
  color: $placeholder;
}
#organizer__menu .ant-menu-item-selected a,
#organizer__menu .ant-menu-item-selected a:hover,
#organizer__menu .ant-menu-item-selected img {
  color: $primary-color;
}
#organizer__menu .ant-menu-item-selected img {
  filter: invert(66%) sepia(14%) saturate(1445%) hue-rotate(119deg)
    brightness(97%) contrast(91%);
}
#organizer__menu.responsive-menu .support_btn {
  background-color: $primary-color;
}

#organizer__menu .ant-menu-item::after {
  border-right: 3px solid $primary-color;
  border-right-width: 5px;
  border-right-style: solid;
  border-right-color: $primary-color;
}
#candidate__menu .ant-menu-item-selected a,
#candidate__menu .ant-menu-item-selected a:hover {
  color: $candidate-color;
}
#candidate__menu .ant-menu-item-selected img {
  filter: invert(42%) sepia(17%) saturate(1370%) hue-rotate(206deg)
    brightness(92%) contrast(91%);
}

#candidate__menu.responsive-menu .support_btn {
  background-color: $candidate-color;
}
#candidate__menu .ant-menu-item::after {
  border-right: 3px solid $candidate-color;
  border-right-width: 5px;
  border-right-style: solid;
  border-right-color: $candidate-color;
}

#recruiter__menu .ant-menu-item-selected a,
#recruiter__menu .ant-menu-item-selected a:hover {
  color: $recruiter-color;
}
#recruiter__menu .ant-menu-item-selected img {
  filter: invert(83%) sepia(42%) saturate(1046%) hue-rotate(325deg)
    brightness(105%) contrast(103%);
}
#recruiter__menu.responsive-menu .support_btn {
  background-color: $recruiter-color;
}
#recruiter__menu .ant-menu-item::after {
  border-right: 3px solid $recruiter-color;
  border-right-width: 5px;
  border-right-style: solid;
  border-right-color: $recruiter-color;
}
.responsive-menu__bottom__button__item {
  color: $gray;
  font-size: 16px;
  justify-content: space-around;
  margin-top: 20px;
  padding-left: 22px;
}

#outerConSideMenu {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.responsive-menu__container {
  #outerConSideMenu > .sideMenu > ul {
    border-right: none;
    & > li {
      font-size: 16px;
      height: 53px;
      vertical-align: middle;
      padding-left: 22px;
      line-height: 50px;
      color: $placeholder;
      margin-bottom: 15px;
      background-color: $white;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
#outerConSideMenu .sideMenu ul li span svg {
  font-size: 24px;
  margin-right: 5px;
  position: relative;
  fill: $placeholder;
}
#outerConSideMenu .sideMenu ul li span a {
  color: $placeholder;
  cursor: pointer;
}
.responsive-menu .botm_btns {
  width: 100%;
  .support_btn {
    width: 120%;
    height: 53px;
    border: none;
    text-align: left;
    margin-top: 10px;
    margin-left: -24px;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      margin-left: 10px;
      align-items: center;
      svg {
        fill: $white;
        margin-top: 5px;
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
  }
  .support_btn.candidate-support_btn {
    margin-top: 23px;
  }
}
.modalInnerHeading {
  display: flex;
  align-items: center;
  font-weight: $medium;
}
.responsive-menu .ant-drawer-body {
  padding-bottom: 0px;
  padding-top: 0px;
}
.responsive-menu__container {
  padding-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.responsive-menu__bottom__button {
  width: 100%;
}
.responsive-menu {
  overflow: scroll;
}
