.report-overview-modal__header {
  color: $recruiter-color;
  margin-top: 15px;
}

.report__overview {
  &__heading-1 {
    font-weight: $bold;
  }

  &__heading-2 {
    margin-bottom: 10px;
    font-weight: $bold;
  }

  &__reason {
    display: flex;
    font-weight: $bold;

    .ant-select-selector {
      width: 350px;
    }
  }
}

.report__reason__details {
  font-weight: $bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.report-overview-modal__save__button {
  &--reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  &--right {
    display: flex;
    flex-direction: row-reverse;
    background-color: $recruiter-color;
    justify-content: center;
    color: $white;
    margin: 10px;
    width: 160px;
  }
}

.report__overview-modal__responsiveHeader {
  display: none;
}

@media (max-width: $mobile) {
  .report-overview-modal__header {
    display: none;
  }

  .report__reason__details {
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 10px;

    &--textarea {
      margin-bottom: 300px;
    }
  }

  .report-overview-modal__save__button--reverse {
    display: flex;
    flex-direction: row-reverse;
    height: 30px;
  }

  .report__overview-modal__responsiveHeader {
    background-color: $recruiter-color;
    height: 120px;
    display: flex;
    position: relative;
    flex-direction: column;

    &__arrow__container {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 10px;
      padding-top: 10px;
      background-color: $recruiter-color;
    }

    &__arrow {
      width: 24px;
      height: 22px;
      filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
        brightness(118%) contrast(119%);
      transform: scale(-1, -1);
      transform: rotate(180);
      transform-origin: 'center';
      opacity: 0.7;
    }
  }

  .report__overview-modal__responsiveHeader__logo {
    &_Container {
      background-color: $recruiter-color;
    }

    &__title__container {
      padding: 20px;
      width: fit-content;
      margin-right: 0;
      float: left;
      right: -10px;
    }

    &__title--logo {
      width: 127px;
      height: 22px;
    }

    &__title--heading {
      text-align: left;
      margin-left: 35px;
      letter-spacing: 0;
      color: $white;
      opacity: 0.5;
    }
  }

  .report__overview-modal__responsiveHeader__sideLogo {
    margin-right: 0;
    float: right;
    position: relative;
    align-self: right;
    right: 0;
    width: fit-content;

    &--right {
      width: 76px;
      height: 84px;
      opacity: 0.5;
    }
  }
}
