@import '../../variables.scss';

@mixin icon-size {
  width: 17px;
  height: 17px;

  @media (min-width: $desktop-min) {
    width: 25px;
    height: 25px;
  }
}

.dateeventsec {
  margin-top: 15px;
}

.public-event__container {
  padding: 50px;
  padding-top: 30px;
  @media (max-width: $mobile) {
    padding: 15px;
  }

  .back_itsubmit {
    div.para_content,
    p.para_content {
      margin-top: -15px;
    }

    .tag_sec h6 {
      @media (max-width: $mobile) {
        padding-bottom: 10px;
      }
    }
  }
  .public_events {
    .book-event-section {
      @media (max-width: $mobile) {
        margin-top: -10px;
      }
    }
    .dateeventsec {
      @media (max-width: $mobile) {
        margin-top: 10px;
      }
    }
    .org_tag .tags.tag_sec {
      @media (max-width: $mobile) {
        margin-top: 0px;
      }
    }
    .view-event-public__description {
      @media (max-width: $mobile) {
        margin-top: 10px;
      }
    }
    .view-public-event__book-section {
      @media (max-width: $mobile) {
        margin-top: -30px;
      }
    }
  }
}

.public_events,
.organizer_events,
.recruiter_events,
.candidate_events {
  .book-event-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &__header {
      h2 {
        font-weight: $bold;
      }
    }

    &__book-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: $mobile) {
        margin-left: 10px;
      }

      h3 {
        margin-right: 5px;
        margin-top: 0.5rem;
        color: $sheader;
      }
      .edit-event {
        @media (max-width: $mobile) {
          margin-left: 10px !important;
        }
      }

      & > :nth-child(2) {
        margin-right: 10px;

        @media (max-width: $mobile) {
          margin-left: 10px;
        }
      }

      & > :nth-child(3) {
        @media (max-width: $laptop-max) {
          span {
            font-size: 12px;
          }
        }
      }

      .share-button {
        border: none;
        border-radius: 10px;
        width: 28px;
        height: 28px;
        margin-top: -4px;
        display: flex;
        justify-content: center;
        align-items: center;

        span > svg {
          @include icon-size();
        }

        svg {
          @include icon-size();

          fill: $white;
        }
        @media (min-width: $desktop-min) {
          width: 40px;
          height: 40px;
        }
      }

      .view-analytics-button {
        height: 36px;
      }
    }
  }
}

.recruiter_events {
  .book-event-section {
    .book-button,
    .share-button {
      background-color: $recruiter-color;
      color: $white;
    }
  }
}

.candidate_events {
  .book-event-section {
    .book-button,
    .share-button {
      background-color: $candidate-color;
      color: $white;
    }
  }
}

.organizer_events {
  .book-event-section {
    .book-button,
    .share-button {
      background-color: $custom-green;
      color: $white;
    }
    .edit-event {
      display: flex;
      margin-left: 30px;
      align-items: center;
      margin-top: 5px;
      @media (max-width: $mobile) {
        margin-left: -5px;
      }
      a {
        button {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          padding: 8px;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      @media (min-width: $desktop-min) {
        a {
          button {
            width: 42px;
            padding: 10px;
            height: 42px;
          }
        }
      }
    }
  }
}

.public_events {
  .book-event-section {
    .book-button,
    .share-button {
      background-color: $bluecolor;
      color: $white;
    }
  }
}

@media (max-width: $mobile) {
  .public_events .book-event-section__book-area .book-button,
  .organizer_events .book-event-section__book-area .book-button,
  .recruiter_events .book-event-section__book-area .book-button,
  .candidate_events .book-event-section__book-area .book-button {
    width: 148px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -5px;

    span {
      text-align: center;
    }
  }

  .view-event__back-icon {
    align-items: center;
    align-self: center;
    margin-top: 10px;
    margin-right: 10px;
    justify-content: center;
  }

  .event_analytic
    .view-event-analytics__container
    .view-event-analytics__container__column
    > .icon_bx {
    width: 63px;
    height: 63px;
  }
}

@media (max-width: $mobile) {
  .candidate_events .book-event-section__book-area .book-button {
    margin-bottom: 0px;
  }
}
