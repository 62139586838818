.page-not-found {
  width: 100%;
  height: 100vh;
  position: relative;

  &__footer {
    height: 30vh;
    background-color: $bluecolor;
    padding: 35px 100px;

    @media (max-width: $mobile) {
      height: 40vh;
      padding: 20px 20px;
    }

    &__heading {
      width: 100%;
      color: $white;
      font-weight: bolder;
    }

    &__message {
      width: 100%;
      color: $white;
    }

    &__seperator {
      width: 50%;
      border-top: 1px solid $white;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &__follow {
      width: 100%;
      color: $white;
    }

    &__buttons {
      a {
        margin: 5px;
      }

      a:hover {
        cursor: pointer;
      }
    }
  }

  &__container {
    height: 70vh;
    position: relative;
    padding: 50px 100px;

    @media (max-width: $mobile) {
      height: 60vh;
      padding: 10px 20px;
    }

    &--right {
      margin-top: -200px;

      img#vector {
        width: 90%;
        position: absolute;
        bottom: -50px;
        max-width: 600px;
        left: 20px;

        @media (max-width: $mobile) {
          width: 150px;
          bottom: -10px;
          left: unset;
          right: 0;
        }

        @media (min-width: $desktop-min) {
          width: 100%;
          left: 60px;
        }
      }

      img#text_404 {
        position: absolute;

        @media (max-width: $mobile) {
          width: 140px;
          bottom: -32px;
          right: unset;
          left: -10px;
        }
      }
    }

    &--left {
      &--top {
        .img {
          max-width: 250px;

          @media (max-width: $mobile) {
            max-width: 160px;
            padding-top: 20px;
          }
        }
      }

      &--middle {
        margin-top: 80px;
        color: $bluecolor;

        & > div {
          font-weight: 500;
        }

        & button {
          @include display-flex(row);

          margin-top: 20px;
          background-color: $bluecolor;
          min-width: 190px;
          justify-content: center;
          align-items: center;

          span {
            @include large-heading-mixin;

            color: $white;
          }

          @mixin pseudo-normal-effect($element) {
            &:#{$element} {
              background-color: $bluecolor;
              color: $white;
            }
          }

          @include pseudo-normal-effect(hover);
          @include pseudo-normal-effect(focus);
          @include pseudo-normal-effect(active);
        }
      }

      &--bottom {
        @include display-flex(column);

        pointer-events: none;
        bottom: -190px;
        position: absolute;
        font-weight: bolder;
        color: $bluecolor;
        max-width: 420px;
        justify-content: flex-end;

        @media (min-width: $desktop-min) {
          bottom: -135px;

          img {
            width: 560px;
          }
        }

        @media (min-width: $laptop-min) {
          bottom: -135px;

          img {
            width: 530px;
          }
        }

        @media (min-width: $tablet-min) {
          bottom: -115px;

          img {
            width: 420px;
          }
        }
      }
    }
  }

  &__logo--right {
    height: 50vh;
    position: absolute;
    top: -20px;
    right: 0;
    opacity: 0.1;
    filter: invert(74%) sepia(9%) saturate(182%) hue-rotate(125deg)
      brightness(90%) contrast(94%);

    @media (max-width: $mobile) {
      display: none;
    }

    @media (min-width: $desktop-min) {
      height: 55vh;
    }
  }
}
