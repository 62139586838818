@mixin ul-default {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin flex {
  display: flex;
}

@mixin cursorPointer {
  &:hover {
    cursor: pointer;
  }
}

@mixin section-heading-mixin {
  font-size: $font14;
  line-height: 21px;
  position: relative;
  padding-left: 22px;

  @media (min-width: $desktop-min) {
    font-size: $font18;

    &::before {
      top: -3px;
      width: 13px;
      height: 30px;
    }
  }

  @media (max-width: $mobile) {
    font-size: $font14;

    &::before {
      width: 9px;
      height: 30px;
    }
  }

  &::before {
    top: -3px;
    width: 9px;
    height: 22px;
    content: '';
    position: absolute;
    left: 0;
  }
}

.section-heading {
  @include section-heading-mixin;
}

@mixin medium-heading-mixin {
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font18;
  }

  @media (max-width: $mobile) {
    font-size: $font14;
  }
}

.medium-heading {
  @include medium-heading-mixin;
}

@mixin small-heading-mixin {
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }

  @media (max-width: $mobile) {
    font-size: $font12;
  }
}

.small-heading {
  @include small-heading-mixin;
}

@mixin medium-large-heading-mixin {
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font20;
  }

  @media (max-width: $mobile) {
    font-size: $font14;
  }
}

.medium-large-heading {
  @include medium-large-heading-mixin;
}

@mixin large-heading-mixin {
  font-size: $font18;

  @media (min-width: $desktop-min) {
    font-size: $font24;
  }

  @media (max-width: $mobile) {
    font-size: $font14;
  }
}

.large-heading {
  @include large-heading-mixin;
}

@mixin extra-large-heading-mixin {
  font-size: $font24;

  @media (min-width: $desktop-min) {
    font-size: $font30;
  }

  @media (max-width: $mobile) {
    font-size: $font16;
  }
}

.extra-large-heading {
  @include extra-large-heading-mixin;
}

@mixin button-mixin {
  max-width: 130px;
  width: max-content;
  height: 28px;

  span {
    font-size: $font14;
  }
  @media (min-width: $desktop-min) {
    max-width: 174px;
    height: 38px;

    span {
      font-size: $font18;
    }
  }

  @media (max-width: $mobile) {
    width: 108px;
    height: 30px;

    span {
      font-size: $font12;
    }
  }
}

.button {
  @include button-mixin;
}

@mixin small-font-mixin {
  font-size: $font12;

  @media (min-width: $desktop-min) {
    font-size: $font12;
  }

  @media (max-width: $mobile) {
    font-size: $font10;
  }
}

.small-font {
  @include small-font-mixin;
}

@mixin medium-font-mixin {
  font-size: $font12;

  @media (min-width: $desktop-min) {
    font-size: $font14;
  }

  @media (max-width: $mobile) {
    font-size: $font12;
  }
}

.medium-font {
  @include medium-font-mixin;
}

@mixin large-font-mixin {
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }

  @media (max-width: $mobile) {
    font-size: $font14;
  }
}

.large-font {
  @include large-font-mixin;
}

@mixin extra-large-font-mixin {
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font18;
  }

  @media (max-width: $mobile) {
    font-size: $font16;
  }
}

.extra-large-font {
  @include extra-large-font-mixin();
}

//In Candidate job details page, custom font sizes are needed so it doesn't break in laptop screens.
@mixin special-font-mixin {
  font-size: $font12;

  @media (min-width: $desktop-min) {
    font-size: 18px;
  }
}
// TODO: Find a better name for special-case-font-mixin's

//In Jobs page of Candidate, this mixin is used to make the font responsive and match according to the design, so we are trying to lower the font-size for default screens.
@mixin special-case-font-mixin {
  font-size: $font12;

  @media (min-width: $desktop-min) {
    font-size: $font18;
  }

  @media (max-width: $mobile) {
    font-size: $font16;
  }
}

//In Candidate job details page, custom font sizes are needed so it doesn't break in laptop screens.
@mixin special-font-mixin-2 {
  font-size: $font10;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }

  @media (max-width: $mobile) {
    font-size: $font12;
  }
}
//In Jobs page of Candidate, this mixin is used to make the text responsive otherwise it breaks the layout, so we are trying to lower the font-size for default screens.
@mixin special-case-2-font-mixin {
  font-size: $font10;

  @media (min-width: $desktop-min) {
    font-size: $font14;
  }

  @media (max-width: $mobile) {
    font-size: $font14;
  }
}

//In Jobs page of Candidate, the component starts to break in laptop screens, so we are trying to lower the font-size for default screens.
@mixin special-case-3-font-mixin {
  font-size: $font10;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }

  @media (max-width: $mobile) {
    font-size: $font16;
  }
}

@mixin display-grid($repeat, $fr) {
  display: grid;
  grid-template-columns: repeat($repeat, $fr);
}

@mixin display-flex($direction) {
  display: flex;
  flex-direction: $direction;
}

@mixin position-absolute($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin large-button-mixin {
  max-width: 130px;
  width: 100%;
  height: 32px;
  span {
    font-size: $font14;
  }

  @media (min-width: $desktop-min) {
    max-width: 170px;
    height: 40px;
    span {
      font-size: $font18;
    }
  }

  @media (max-width: $mobile) {
    width: 108px;
    height: 30px;

    span {
      font-size: $font12;
    }
  }
}

.large-button {
  @include large-button-mixin;
}

@mixin pseudo-normal-effect-tag($element) {
  &:#{$element} {
    background-color: $very-light-grey-3;
    color: $tgtextcolor;
  }
}

// Assign Colors

@mixin recruiter-color {
  color: $recruiter-color;
}

@mixin candidate-color {
  color: $candidate-color;
}

@mixin organizer-color {
  color: $organizer-color;
}

.recruiter-color {
  @include recruiter-color();
}

.candidate-color {
  @include candidate-color();
}

.organizer-color {
  @include organizer-color();
}

// Assign Bg Colors

@mixin recruiter-bg-color {
  background-color: $recruiter-color;
}

@mixin candidate-bg-color {
  background-color: $candidate-color;
}

@mixin organizer-bg-color {
  background-color: $organizer-color;
}

.recruiter-bg-color {
  @include recruiter-bg-color();
}

.candidate-bg-color {
  @include candidate-bg-color();
}

.organizer-bg-color {
  @include organizer-bg-color();
}

// Assign Border Colors

@mixin recruiter-border-color {
  border-color: $recruiter-color;
}

@mixin candidate-border-color {
  border-color: $candidate-color;
}

@mixin organizer-border-color {
  border-color: $organizer-color;
}

.recruiter-border-color {
  @include recruiter-border-color();
}

.candidate-border-color {
  @include candidate-border-color();
}

.organizer-border-color {
  @include organizer-border-color();
}
