.view-job-details {
  &__header {
    color: $custom-orange;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    box-shadow: $shadow;
    width: 100%;
    z-index: 1;
    position: relative;
    color: $sub-header;
    background: $white;
    padding: 20px;
    height: auto;
    max-width: auto;
    max-height: auto;
    font-weight: $medium;
  }

  &__main-title {
    @include medium-heading-mixin;

    color: $header;
    margin-left: 26px;
    margin-top: 36px;
    font-weight: $medium;
  }

  &__title {
    @include small-heading-mixin;

    color: $header;
    margin-left: 26px;
    margin-top: 36px;
    font-weight: $medium;
  }

  &__field-value {
    @include small-heading-mixin;
    color: $sub-header;
    margin-left: 26px;
    margin-top: 16px;
    font-weight: $medium;

    .skill-name {
      color: $black;
      margin-top: 10px;
    }

    .skill-value {
      margin-top: 10px;
    }
  }
}

.yellow-text {
  color: $recruiter-color;
}

.view-job-details {
  &__buttons--right {
    margin-right: 5px;
    margin-top: 36px;
  }

  &__edit-button {
    background-color: $recruiter-color;
    color: $white;
    width: min-content;
    height: min-content;
    padding: 4px;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 10px;
    span {
      font-size: $font14;
    }
    &:hover {
      color: $dark-yellow;
      border: 1px solid $recruiter-color;
    }
  }

  &__analytics-button {
    width: min-content;
    height: min-content;
    border-color: $recruiter-color;
    color: $recruiter-color;
    padding: 4px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    span {
      font-size: $font14;
    }
    &:hover {
      color: $dark-yellow;
      border: 1px solid $recruiter-color;
    }

    &::after {
      color: $dark-yellow;
      border: 1px solid $recruiter-color;
    }
  }
}

.tags_recruiter {
  margin-left: 24px;
  margin-top: 12px;
}

@media (min-width: $desktop-min) {
  .view-job-details {
    &__header {
      color: $custom-orange;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      box-shadow: $shadow;
      width: 100%;
      z-index: 1;
      position: relative;
      color: $sub-header;
      background: $white;
      padding: 20px;
      height: auto;
      max-width: auto;
      max-height: auto;
      font-weight: $medium;
    }

    &__main-title {
      @include medium-heading-mixin;

      color: $header;
      margin-left: 26px;
      margin-top: 36px;
      font-weight: $medium;
    }

    &__title {
      @include small-heading-mixin;

      color: $header;
      margin-left: 26px;
      margin-top: 36px;
      font-weight: $medium;
    }

    &__field-value {
      color: $sub-header;
      margin-left: 26px;
      margin-top: 16px;
      font-weight: $medium;

      .skill-name {
        color: $black;
        margin-top: 10px;
      }

      .skill-value {
        margin-top: 10px;
      }

      .yellow-text {
        color: $recruiter-color;
      }
    }
  }

  .view-job-details {
    &__buttons--right {
      margin-right: 5px;
      margin-top: 36px;
    }

    &__edit-button {
      background-color: $recruiter-color;
      color: $white;
      width: min-content;
      height: min-content;
      padding: 7px;
      padding-left: 40px;
      padding-right: 40px;
      margin-right: 10px;
    }

    &__analytics-button {
      width: min-content;
      height: min-content;
      border-color: $recruiter-color;
      color: $recruiter-color;
      padding: 7px;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 10px;
    }
  }
}

@media (max-width: $mobile) {
  .view-job-details {
    &__header {
      color: $custom-orange;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: $font20;
    }

    &__container {
      display: flex;
      flex-direction: column;
      box-shadow: $shadow;
      width: 100%;
      z-index: 1;
      position: relative;
      color: $sub-header;
      background: $white;
      padding: 20px;
      height: auto;
      max-width: auto;
      max-height: auto;
      font-weight: $medium;

      .header {
        display: flex;
        justify-content: space-between;
      }
    }

    &__main-title {
      font-size: $font20;
      color: $header;
      margin-left: 26px;
      margin-top: 36px;
      font-weight: $medium;
    }

    &__title {
      font-size: $font16;

      color: $header;
      margin-left: 26px;
      margin-top: 36px;
      font-weight: $medium;
    }

    &__field-value {
      font-size: $font16;
      color: $sub-header;
      margin-left: 26px;
      margin-top: 16px;
      font-weight: $medium;

      .skill-name {
        font-size: $font16;
        color: $black;
        margin-top: 10px;
      }

      .skill-value {
        font-size: $font16;
        margin-top: 10px;
      }
    }
  }

  .yellow-text {
    color: $recruiter-color;
  }

  .view-job-details {
    &__buttons--right {
      margin-right: 5px;
      margin-top: 36px;
    }

    &__edit-button {
      background-color: $recruiter-color;
      color: $white;
      width: min-content;
      height: min-content;
      padding: 4px;
      padding-left: 25px;
      padding-right: 25px;
      margin-right: 10px;
      span {
        font-size: $font16;
      }
      &:hover {
        color: $dark-yellow;
        border: 1px solid $recruiter-color;
      }
    }

    &__analytics-button {
      width: min-content;
      height: min-content;
      border-color: $recruiter-color;
      color: $recruiter-color;
      padding: 4px;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 10px;
      span {
        font-size: $font16;
      }
      &:hover {
        color: $dark-yellow;
        border: 1px solid $recruiter-color;
      }

      &::after {
        color: $dark-yellow;
        border: 1px solid $recruiter-color;
      }
    }
  }

  .tags_recruiter {
    margin-left: 24px;
    margin-top: 14px;
    button {
      span {
        font-size: $font16;
      }
    }
  }
}
