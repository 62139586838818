.CandidateProfile .quick-start {
  position: relative;

  .circle_box {
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  &__main-header {
    @include extra-large-heading-mixin;

    font-weight: $bold;
  }

  &__sub-header {
    @include medium-heading-mixin;

    color: $gray;
    font-weight: $light;
  }

  &__title {
    @include large-heading-mixin;

    color: $candidate-color;
  }

  &__sub-title {
    @include medium-heading-mixin;

    color: $candidate-color;
    margin-top: 20px;
  }

  &__basic-information {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 220px;
    gap: 40px;
    padding-top: 15px;

    .zipcode__block {
      margin-top: -100px;

      & > div {
        flex-wrap: nowrap;

        @media (max-width: $mobile) {
          flex-wrap: wrap;
        }

        .country {
          margin-left: 12px;

          @media (max-width: $mobile) {
            margin-left: 0px;
          }
        }
      }

      input {
        font-size: $font16;
      }

      .profile__information__header {
        margin-bottom: 0;
        color: $label;
      }
    }

    .country__block {
      margin-top: -100px;
    }

    @media (max-width: $mobile) {
      @include display-grid(1, 1fr);
      gap: 0;

      .full-name__block {
        margin-top: 120px;
      }

      .zipcode__block,
      .nationality__block {
        margin: 0;
      }
    }
  }

  &__quick-submission {
    @include medium-heading-mixin;

    color: $candidate-color;
    margin-top: 20px;

    @media (max-width: $mobile) {
      margin-top: 20px;
    }
  }

  &__upload-title {
    @include small-heading-mixin;

    font-weight: $regular;
  }

  &__info-header {
    @include small-heading-mixin;

    color: $black;
    white-space: nowrap;
  }

  &__info-input {
    color: $black;
    width: 330px;
    margin-right: 30px;
    margin-bottom: 40px;

    @media screen and (max-width: $tablet-special-case-1) {
      width: 100%;
      height: 40px;
      padding: 12px;
    }
  }

  &__profile-picture {
    margin-left: 40px;
    margin-top: -30px;

    .upload-image-hint-text {
      margin-left: 20px;
      margin-top: -10px;
      width: 150px;
    }

    .shadow-picture {
      margin-left: 35px;
    }

    @media (max-width: $mobile) {
      position: absolute;
      top: 0;
      right: 0;

      .upload-component__user-image {
        width: 80px !important;
        height: 80px !important;
      }

      .upload-image-hint-text {
        margin-left: 5px;
      }
    }

    .removeLogo {
      margin-left: 40px;
      white-space: nowrap;
      margin-top: -10px;
      margin-bottom: 12px;
    }
  }

  &.upload-title {
    margin-bottom: 15px;
  }

  &__upload-section {
    @include display-flex(row);

    align-items: center;
    flex-wrap: wrap;

    span {
      margin-right: 10px;
    }

    .upload-information {
      color: $candidate-color;

      @media (max-width: $mobile) {
        margin-top: 10px;
      }
    }
  }

  &__submit {
    @include display-flex(row);

    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    height: 40px;
    margin-bottom: 50px;

    &--button {
      margin-bottom: -30px;
      margin-right: 30px;
      width: 220px;
      height: 40px;
      border-color: $candidate-color;

      span {
        @include medium-heading-mixin;
      }
    }

    .quick-jobs-button {
      background-color: $candidate-color;
    }

    .continue-button {
      color: $candidate-color;

      @media screen and (max-width: $mobile-special-case-1) {
        margin-bottom: 10px;
      }
    }
  }

  .skill-set__levels {
    width: auto;

    .skill-line {
      margin: 20px 0;
    }

    .skill-remove-column {
      margin-left: 30px;
      margin-top: -10px;

      @media (min-width: $laptop-min) and (max-width: $laptop-special-case-2) {
        margin-left: 70px;
      }

      @media (min-width: $tablet-special-case-2) and (max-width: $tablet-special-case-1) {
        margin-left: -30px;
      }

      @media (max-width: $mobile) {
        margin-left: 15px;
        width: 10px;
      }
    }

    .skill-value-column {
      @media (max-width: $mobile) {
        margin-left: -20px;
        width: auto;
      }
    }

    .ant-steps {
      width: 100%;
    }

    .ant-steps-item-tail {
      top: -8px;
    }
  }
}
