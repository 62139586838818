.resume__pdf__viewer {
  display: flex;
  position: relative;
  height: max-content;
  color: $sheader;
  padding: 210px;
  font-weight: $semi-bold;
}

@media (max-width: $mobile) {
  .resume__pdf__viewer {
    display: flex;
    color: $sheader;
    padding: 150px;
    font-weight: $semi-bold;
  }
}
