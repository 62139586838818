@import '../../variables.scss';
@import '../../mixins.scss';

.modal {
  margin-bottom: 100px;

  &__title {
    @include large-heading-mixin;

    margin-bottom: 4vh;
  }

  &__sub-title {
    @include medium-heading-mixin;

    margin-bottom: 2vh;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
    }

    h2 {
      @include large-font-mixin;

      color: $label;
    }

    & > div {
      width: 18vw;
      margin-bottom: 3vh;
      color: $label;

      @media only screen and (max-width: $mobile) {
        width: 100%;
      }

      input {
        color: $label;

        @media only screen and (max-width: $mobile) {
          width: 100%;
        }
      }
    }

    &__email {
      white-space: nowrap;
    }

    &__phone-number {
      & > div {
        display: flex;

        @media only screen and (max-width: $mobile) {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }

    &__profile-pic {
      @media only screen and (max-width: $mobile) {
        margin-top: 40px;
        margin-left: -140px;
        position: absolute;
        top: 3.5%;
        left: 80%;
      }

      @media only screen and (max-width: $mobile) {
        left: 20%;
        top: 11%;
      }

      .add-profile-picture-button {
        @media (min-width: $tablet-min) and (max-width: $tablet-special-case-1) {
          margin-left: -25px;
        }
      }

      .shadow-picture {
        width: 80px;
        height: 80px;
        margin-left: 52px;

        @media (max-width: $tablet-max) {
          margin-left: 30px;
        }

        @media (min-width: $tablet-min) and (max-width: $tablet-special-case-1) {
          margin-left: -20px;
        }
      }

      .upload-image-hint-text {
        margin-left: 25px;
        margin-top: -5px;
        width: 150px;

        @media only screen and (max-width: $mobile) {
          margin-left: 0;
        }

        @media (min-width: $tablet-min) and (max-width: $tablet-special-case-1) {
          margin-left: -20px;
        }
      }

      span {
        // margin-left: 1.6vw;

        @media only screen and (max-width: $mobile) {
          margin-left: -0.8vw;
          width: 150px;
          height: 60px;
          margin-top: -12px;
        }
      }

      & > div {
        @media (max-width: $mobile) {
          position: absolute;
          right: 20px;
          margin-top: 35px;
        }

        @media (max-width: $mobile-special-case-1) {
          right: -40px;
        }

        @media (max-width: $mobile-special-case-1) {
          right: -50px;
        }
      }

      & > div > span {
        @media only screen and (max-width: $laptop-special-case-1) {
          margin-left: 7vw;
        }

        @media only screen and (max-width: $mobile) {
          margin-top: -5vh;
          margin-bottom: -10px;
        }
      }
    }

    &__status-block {
      @media only screen and (max-width: $mobile) {
        display: flex;
        justify-content: space-between;
      }

      .status {
        @include extra-large-font-mixin;

        font-weight: $semi-bold;
        margin-top: -30px;

        @media only screen and (max-width: $mobile) {
          margin-top: 30px;
        }
      }

      .active-text {
        margin-top: -5px;
        &:hover {
          cursor: pointer;
        }

        @media only screen and (max-width: $mobile) {
          margin-top: 25px;
        }
      }
    }

    &__cancel-and-reset {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      @media only screen and (max-width: $mobile) {
        height: 100%;
        margin-top: 50px;
      }

      & > button {
        border: none;
      }
    }
  }
}

.recruiter__modal {
  .modal__title,
  .modal__sub-title {
    color: $custom-orange;
  }

  .modal__wrapper__cancel-and-reset {
    & > button:nth-child(2) {
      color: white;
      background-color: $custom-orange;
      width: 180px;
    }
  }
}

.organizer__modal {
  .modal__title,
  .modal__sub-title {
    color: $custom-green;
  }

  .modal__wrapper__cancel-and-reset {
    & > button:nth-child(2) {
      color: white;
      background-color: $custom-green;
      width: 180px;
    }
  }
}

.recruiter__modal,
.organizer__modal {
  @media only screen and (max-width: $mobile) {
    padding-bottom: 100px;
  }

  .modal__wrapper__cancel-and-reset {
    & > button:nth-child(2) {
      color: white;
      width: 180px;
    }
  }
}

.recruiter__modal {
  @media (max-width: $mobile) {
    padding-bottom: 0;
  }
}

.crew-member-profile-modal {
  .ant-modal-header {
    @media only screen and (min-width: $mobile) {
      display: none;
    }
  }
}

.OrganizerCrewManagementModal {
  .invite-member-modal__title {
    color: $primary-color;
  }

  .invite-member-modal__members-container {
    &__title {
      color: $primary-color;
      font-weight: $semi-bold;
      font-size: $font16;
    }
  }
}
.RecruiterCrewManagementModal {
  .invite-member-modal__title {
    color: $recruiter-color;
    font-weight: $semi-bold;
  }

  .invite-member-modal__members-container {
    &__title {
      color: $recruiter-color;
      font-weight: $semi-bold;
      font-size: $font16;
    }
  }
}

.adminCrewManagementModal {
  .invite-member-modal__title {
    color: $bluecolor;
    font-weight: $semi-bold;
  }

  .invite-member-modal__members-container {
    &__title {
      color: $bluecolor;
      font-weight: $semi-bold;
      font-size: $font16;
    }
  }
}

.invite-member-modal__members-container___member__heading {
  display: inline-block;
  margin-bottom: 20px;
}

.invite-member-modal__members-container___member {
  display: flex;
  width: 100%;
  row-gap: 15px !important; // To overwrite default ant row gap

  .ant-col-offset-1 {
    margin-left: 15px;
    @media (max-width: $mobile) {
      margin-left: 0px;
    }
  }

  .label {
    @media (max-width: $mobile) {
      margin-bottom: 10px;
    }
  }
}

.invite-member-modal__members-container {
  margin-bottom: 20px;

  &___member {
    margin-top: 10px;

    @media (max-width: $mobile) {
      row-gap: 20px;
    }

    &___member__heading {
      font-weight: 500;
      color: $black;
      margin-bottom: 4px;
    }

    &__options {
      @include display-flex(column);

      justify-content: flex-end;
      margin-bottom: 3px;

      @media (max-width: $mobile) {
        margin-bottom: 10px;
      }

      .status {
        display: flex;
        flex-wrap: nowrap;

        @media (max-width: $mobile) {
          margin-left: auto;
        }

        .invited-text {
          margin-left: 16px;
        }
      }

      &__remove {
        a {
          p {
            color: $custom-red;
            margin-left: 20px;
            font-weight: $semi-bold;
          }
        }
      }
    }
  }

  .crew-management__button__view-profile:hover {
    cursor: pointer;
  }

  &__members__wrapper {
    max-height: 250px;
    overflow-y: scroll;
  }
}

.danger-text {
  color: $custom-red;
  font-weight: $semi-bold;
  padding-top: 4px;
  &:hover {
    cursor: pointer;
  }
}
