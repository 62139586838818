.dashboard-admin__container {
  background-color: $white;
  opacity: 1;
  &__box {
    box-shadow: $shadow2;
    padding: 20px;

    &__header {
      color: $sheader2;
      font-weight: $semi-bold;
      margin: 10px 0px;
    }
  }
  .analytics-container__items {
    .analytics-item {
      a {
        min-width: 180px;
        @media (min-width: $desktop-min) {
          min-width: 250px;
        }
      }
    }
  }
}

.admin-container {
  box-shadow: $shadow2;
  padding: 20px;
  background-color: $white;
}

.analytics-container {
  padding: 10px 0px;
  width: 100%;
  height: 100%;

  .created_eve {
    padding: 20px;
    width: 100%;
    .circle_1 {
      max-width: 100px;
      width: 100%;
      background: #6dc9b7;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -51px;
      z-index: 0;
    }
  }
  &__items {
    @include display-flex(row);
    align-items: center;
    position: relative;
    .analytics-item {
      color: $white;
      margin-right: 10px;
      margin-bottom: 10px;
      .left {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        margin-right: 10px;
        svg {
          fill: $white;
        }
      }
    }
    .viewdetails {
      position: absolute;
      right: 10px;
      bottom: -10px;
      div {
        color: white;
        span {
          svg {
            fill: white;
          }
        }
      }
    }
  }
  &.application-analytics .created_eve {
    background-color: $bluecolor;
    .analytics-item {
      .left {
        background-color: $bluecolor;
        filter: brightness(120%);
      }
    }
    .circle_1,
    .circle_2 {
      background-color: $bluecolor;
      filter: brightness(120%);
    }
  }
  &.organizer-analytics .created_eve {
    background-color: $primary-color;
    .analytics-item {
      .left {
        background-color: $organizer-shadow;
      }
    }
    .circle_1,
    .circle_2 {
      background-color: $organizer-shadow-2;
    }
  }
  &.recruiter-analytics .created_eve {
    background-color: $recruiter-color;
    .analytics-item {
      .left {
        background-color: $recruiter-shadow;
      }
    }
    .circle_1,
    .circle_2 {
      background-color: $recruiter-shadow;
    }
  }
  &.candidate-analytics .created_eve {
    background-color: $candidate-color;
    .analytics-item {
      .left {
        background-color: $candidate-color-light-shade;
      }
    }
    .circle_1,
    .circle_2 {
      background-color: $candidate-color-light-shade;
    }
  }
}

.analytics-container-title {
  margin-bottom: 10px;
  color: $white;
}
.dashboard__welcome__message {
  font-weight: $semi-bold;
  color: $primary-color;
  margin-bottom: 20px;
  margin-top: 20px;
}
.plain-container {
  background-color: $white;
  box-shadow: $shadow2;

  .site-page-header {
    .ant-page-header-heading {
      .ant-page-header-heading-left {
        span {
          @include large-font-mixin;
          color: $header;
          font-weight: $semi-bold;
        }
      }
    }
  }
}

.admin {
  .mark-present-btn {
    background-color: $bluecolor;

    span {
      color: white;
    }

    &[disabled] {
      background: $light_gray;

      span {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &#sidebar {
    .fixed_side {
      ul {
        li.ant-menu-item-active,
        li.ant-menu-item-selected {
          a {
            color: $bluecolor !important;
          }

          svg {
            fill: $bluecolor !important;
          }

          &::after {
            border-right: 7px solid $bluecolor;
          }

          img {
            filter: invert(48%) sepia(92%) saturate(3438%) hue-rotate(193deg)
              brightness(103%) contrast(103%);
          }
        }
      }
    }
  }

  .create-event__title-heading,
  .edit-event__title-heading,
  #organizer-breadcrumb a,
  .dashboard__welcome__message,
  .organizer__verified-text,
  .event-information__title,
  .auto-mark-text,
  .event-timer {
    color: $bluecolor;
  }

  &.OrganizerCompanyInformationModal {
    .company-information-modal-item__header,
    .nor_header {
      color: $bluecolor;
    }
  }

  .submit-button {
    background-color: $bluecolor;
  }

  .organizer__verify-button,
  .organizer__verify-button:hover,
  .organizer__verify-button:active,
  .circle_box,
  .event-analytics__container__wrapper,
  .ant-switch-checked {
    background-color: $bluecolor;
  }
  .analytics-item .event-analytics__container__wrapper--icon--left {
    background-color: #1890ff;
    filter: brightness(120%);
  }

  .form-group.search_input {
    margin: 0px 10px;
  }

  .skill-admin-add {
    margin-left: 20px;
  }

  &.profile_logout {
    li {
      &:hover div {
        color: $bluecolor;
      }

      &:hover div svg {
        fill: $bluecolor;
      }
    }
  }

  .skill-set-step-level__column .ant-steps-item-process .ant-steps-item-icon {
    color: #fff;
    background-color: $bluecolor;
    border-radius: 50%;
  }

  .skill-set-step-level__column .ant-steps-item-icon {
    border: 2px solid $bluecolor;
  }

  .skill-set-step-level__column
    .ant-steps-item-process
    .ant-steps-item-icon
    .ant-steps-icon {
    color: #fff;
    background-color: $bluecolor;
  }

  .skill-set-step-level__column
    .ant-steps-item-finish
    .ant-steps-item-tail::after {
    background-color: $bluecolor;
  }

  .skill-set-step-level__column
    .ant-steps-item-finish
    .ant-steps-item-icon
    span {
    color: $bluecolor;
  }

  .skill-set-preferred__column .ant-switch-checked {
    background-color: $bluecolor;
  }

  .basic-info-mandatory__switch,
  .basic-info-skill-set__heading {
    color: $bluecolor;
  }

  .basic-info-any-skill__dashed-button .ant-btn,
  .basic-info-any-skill__dashed-button .ant-btn-dashed {
    color: $bluecolor;
    border-color: $bluecolor;
  }

  .additional-details-address__row,
  .additional-details-location,
  .additional-details__title,
  .job-description__sub-heading {
    margin-bottom: 10px;
    margin-top: 14px;
  }

  &.PersonDetailModal {
    .skill-set .ant-steps-item-active .ant-steps-item-icon {
      border: 3px solid $bluecolor;
      color: $bluecolor;

      .ant-steps-icon {
        color: $bluecolor;
      }
    }
    .main-header,
    .nor_header {
      color: $bluecolor;
    }
    .submit-button {
      background-color: $bluecolor;
    }
    .skill-set .ant-steps-item-finish {
      svg {
        fill: $bluecolor;
      }

      .ant-steps-item-tail::after {
        background-color: $bluecolor;
      }

      .ant-steps-item-icon {
        border: 3px solid $bluecolor;
      }
    }
  }

  .back_itsubmit .dateeventsec ul li.blue_li {
    color: $bluecolor;
    svg {
      fill: $bluecolor;
    }
  }

  .organizer_events .org_tag h4::after {
    background-color: $bluecolor;
  }

  .pro_steps_content {
    box-shadow: unset;

    h3.content_title,
    .content_subtitle,
    .green-header {
      color: $bluecolor;
    }
  }

  .steps-action .nxt_btn,
  .event_analytic {
    background-color: $bluecolor;
    .circle_1,
    .circle_2 {
      background-color: $bluecolor;
      filter: brightness(120%);
    }
  }

  .event_analytic .view-event-analytics__container__column .icon_bx {
    background-color: $bluecolor;
    filter: brightness(120%);
  }

  .analytics-item-hyperlink {
    min-width: 220px;
  }

  .analytics-container .created_eve {
    padding: 35px 20px;
    width: 100%;
  }

  &.OrganizerCompanyInformationModal .ant-input {
    margin-bottom: 0px;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  div {
    @include medium-font-mixin;
  }
}

.view-resume__color-code-section {
  @include display-flex(row);
}

.super-admin-users.dashboard__welcome__message {
  color: $bluecolor;
}

.admins-table__container {
  overflow: auto;
}
