.live-event {
  position: fixed;
  bottom: 10px;
  left: 2px;
  display: none;
  z-index: 999;

  @media (max-width: $mobile) {
    bottom: 10px;
    left: 10px;
    width: 70%;
    max-width: 340px;
  }
  @media (min-width: $desktop-min) {
    bottom: 10px;
  }
  &__video {
    width: 340px;
    height: 195px;
    position: relative;
    background-color: $black;

    @media (min-width: $desktop-min) {
      width: 430px;
      height: 230px;
    }

    @media (max-width: $mobile) {
      width: 100%;
      height: 160px;
      max-width: 340px;
    }

    &__iframe {
      width: 340px;
      height: 195px;

      @media (min-width: $desktop-min) {
        width: 430px;
        height: 230px;
      }

      @media (max-width: $mobile) {
        width: 100%;
        max-width: 340px;
        height: 160px;
      }
    }
  }

  &__video__expanded {
    width: 100vw;
    height: 100vh;
    background-color: $black;
    transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));

    @media (max-width: $mobile) {
      width: 100vh;
      height: 100vw;
      transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%))
        rotate(90deg);
    }

    &__iframe {
      width: 100%;
      height: 100vh;

      @media (max-width: $mobile) {
        width: 100vh;
        height: 100vw;
      }
    }

    .live-event__name {
      @include medium-large-heading-mixin;

      top: 20px;
      left: 20px;
    }

    .live-event__minimize-icon {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 42px;
      height: 42px;
      fill: $white;
      opacity: 0.8;

      @media (max-width: $mobile) {
        width: 20px;
        height: 20px;
        top: 15px;
        right: 15px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .live-event__time {
      position: absolute;
      bottom: 20px;
      left: 20px;
      color: $white;
    }
  }

  &__close-icon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    margin-bottom: 5px;

    @media (min-width: $desktop-min) {
      width: 16px;
      height: 16px;
      margin-bottom: 10px;
    }
  }
  &__drag-icon {
    position: absolute;
    top: 0px;
    right: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: block;
    cursor: move;
    svg {
      width: 20px;
      height: 20px;
      cursor: move;
      @media (min-width: $desktop-min) {
        width: 24px;
        height: 24px;
        margin-bottom: 10px;
      }
    }
  }

  &__name {
    position: absolute;
    top: 10px;
    left: 10px;
    color: $white;
  }

  &__play-list {
    position: absolute;
    top: -3px;
    right: 35px;
    margin-left: 5px;
    margin-bottom: 5px;
    display: block;
    @media (min-width: $desktop-min) {
      top: 0px;
    }

    &.expended {
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(148deg)
        brightness(99%) contrast(104%);
      top: 85px;
      right: 30px;
      width: 42px;
      height: 42px;
    }
  }

  &__id {
    display: none;
  }

  &__fullscreen-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    fill: $white;

    @media (min-width: $desktop-min) {
      width: 25px;
      height: 25px;
      top: 15px;
      right: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__time {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: $white;
  }
}

.live-event-fullscreen {
  width: 100vw;
  height: 100vh;
  //Important is required here.....this style is applied when video is expanded.
  left: 0px !important;
  top: 0px !important;
  bottom: 0px;
  .live-event__drag-icon {
    display: none;
  }
}

.link-switch-watch-stream {
  @include display-flex(row);

  border-radius: 15px;
  padding: 20px 40px;
  align-items: center;

  @media (max-width: $mobile) {
    border-radius: 10px;
    padding: 5px 10px;
  }

  &.organizer {
    background-color: $primary-color;
  }

  &.recruiter {
    background-color: $recruiter-color;
  }

  &.candidate {
    background-color: $candidate-color;
  }
  span {
    @include large-font-mixin;
    color: white;
  }
}

.link-switch-modal-container {
  max-height: 350px;
  overflow: scroll;
}
