$color-bg-modal: #1890ff;

.modal.login-signup {
  padding: 0;
  overflow: hidden;
  max-width: 80rem;
  border-radius: 1rem;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .modal-login,
  .modal.login-signup {
    &.ant-modal {
      height: auto !important;
    }
  }
}

.modal-login {
  display: flex;
  height: 100%;

  @media (min-width: $mobile) and (max-width: $tablet-special-case-1) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .modal-login-logo {
    height: 100%;
    background-color: $color-bg-modal;

    .modal-login-logo-full {
      padding: 4rem 2rem;
      height: 28rem;

      .modal-login-logo-full-1 {
        width: 100%;
        filter: brightness(0) invert(1);
      }
    }

    .modal-login-logo-half {
      display: flex;
      align-items: flex-end;
      padding-bottom: 5rem;
      height: 28rem;

      .modal-login-logo-half-1 {
        opacity: 0.35;
        transform: translate(5rem);
        object-fit: cover;
        object-position: 0% 100%;
        width: 20rem;
        height: 20rem;
      }

      .modal-signup-logo-half-1 {
        opacity: 0.35;
        transform: translate(7rem);
        object-fit: cover;
        object-position: 0% 100%;
        width: 20rem;
        height: 20rem;
      }
    }
  }

  .modal-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    .modal-login-content-text1 {
      color: $color_text_1;
      font-size: 3rem;
      font-weight: bold;
      padding-bottom: 1rem;
    }

    .modal-login-content-text2 {
      color: $color_text_2;
      letter-spacing: 0.025rem;
      padding-bottom: 15px;
    }

    .success-icon {
      width: 8rem;
      height: 8rem;
    }

    .modal-login-content-verify1 {
      color: $color_text_1;
      font-size: 2.5rem;
      font-weight: bold;
      padding-bottom: 1rem;
    }

    .modal-login-content-verify2 {
      font-size: 1.7rem;
      padding-bottom: 3rem;
    }

    .modal-login-content-verify3 {
      color: $color_text_1;
      font-size: 2.5rem;
      font-weight: bold;
      padding-bottom: 5rem;
    }

    .reset-password-text {
      padding-bottom: 1rem;
      text-align: left;
    }

    .reset-password {
      // padding-top: 2rem;
      width: 40rem;
    }

    .confirm-reset-password {
      padding-top: 2rem;
      width: 40rem;
      padding-bottom: 2rem;
    }

    .bold {
      font-weight: bold;
      font-size: 2rem;
      &:hover {
        cursor: pointer;
        color: none;
      }
    }

    .align-verifymodal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .modal-login-content-text3 {
      color: $color_text_1;
      font-size: 1.5rem;
      font-weight: bold;
      padding-bottom: 4rem;
    }

    .modal-login-content-form-textbox {
      width: 40rem;
      padding-bottom: 2rem;
    }

    .ant-select-selection-placeholder {
      @include large-font-mixin;
    }

    .ant-select-arrow {
      margin-top: -9px;

      svg {
        width: 16px;
      }
    }

    .modal-login-content-form-password {
      width: 40rem;
      padding-bottom: 5rem;
    }

    .login-form-forgot {
      &:hover {
        cursor: pointer;
      }
    }

    .modal-login-content-form-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
    }

    .modal-login-content-text4 {
      color: $color_text_2;
    }

    .modal-login-content-text5 {
      color: $ctextcolor;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
    }

    .modal-login-content-text3 {
      padding-bottom: 2rem;
    }

    .modal-login-socialBtns {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      padding-bottom: 20px;

      div {
        width: 100%;

        .modal-login-social-button {
          width: max-content;
        }
      }

      .btn {
        width: 100%;
        max-width: 12rem;
        background-color: $very-light-grey-3;
        border: none;
        color: $placeholder;
        filter: grayscale(100%);

        &:hover {
          color: $color_text_2;
          filter: none;
          .btn-icon-google,
          .btn-icon-facebook,
          .btn-icon-linkedin {
            opacity: 1;
          }
        }

        .btn-text {
          margin-left: 1rem;
          width: 5rem;
        }

        .btn-icon-google,
        .btn-icon-facebook,
        .btn-icon-linkedin {
          width: 1.5rem;
          height: 1.5rem;
          opacity: 0.5;
        }
      }
    }
  }
}

.center-screen {
  position: relative;
  left: 50%;
  right: 50%;
  transform: translateY(40vh);
}

// mobile
@media (max-width: $mobile) {
  .ant-modal-centered .ant-modal {
    width: 100% !important;
    padding: 15px !important;
  }
  #login_modal,
  #sigup_modal,
  #forget_modal {
    .modal-login-logo {
      position: relative;
      padding-top: 31px;
      padding-bottom: 6px;
      .modal-login-logo-full {
        height: auto !important;
        img {
          max-width: 126px;
        }
      }
    }
    .modal-login-logo-half {
      height: auto !important;
      img {
        height: 84px;
        width: 76px;
        position: absolute;
        right: 0px;
        bottom: 0;
        transform: unset;
      }
    }
  }
  .ant-modal-close-x {
    display: none;
  }
  .modal-login .modal-login-content {
    padding: 30px 13px 50px;
    .mob_div {
      max-width: unset !important;
    }
    .modal-login-content-text1 {
      font-size: 16px;
      color: $header;
    }
    .modal-login-content-text2,
    .modal-login-content-text3 {
      font-size: 14px;
    }
    #login_username,
    #login_email,
    #login_name,
    .ant-select-selector,
    #login_website,
    #login_companyName {
      border: 1px solid $placeholder;
      display: flex;
      align-items: center;
    }
    #login_phone {
      border: 1px solid $placeholder;
      border-left: 0px;
    }
    .ant-input-password {
      border: 1px solid $placeholder;
    }

    #login_submit {
      width: 100%;
      max-width: 282px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 65px;
    }
    .modal-login-socialBtns .btn .btn-icon-google,
    .modal-login-socialBtns .btn .btn-icon-facebook,
    .modal-login-socialBtns .btn .btn-icon-linkedin {
      opacity: 1;
    }
    .modal-login-socialBtns .btn {
      filter: unset;
    }
    .phonenum {
      position: relative;
      top: -1px;
    }
    #login_countryCode {
      height: 100%;
    }
  }
  .svg_arrw svg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    fill: $white;
    transform: rotate(90deg);
    font-size: 24px;
    opacity: 0.4;
    top: 40px;
  }
}

//
@media (min-width: $mobile) {
  .mob_show {
    display: none;
  }
  .min_hgt {
    height: 42px;
    border: 1px solid $placeholder;
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
  //   .modal-login-socialBtns{display: unset!important;}
  //   #login_submit{max-width: 284px;height: 44px;}
  .modal-login .modal-login-logo {
    height: unset;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: pointer;
}

.modal-login {
  & &-logo {
    .signup-logo {
      &--bottom {
        height: 400px;
        &--small {
          height: 250px;
        }
      }

      &--top {
        height: 400px;
        &--small {
          height: 250px;
        }
      }
    }
  }
}

a.link-text {
  color: $bluecolor;
}

.signup-modal-verify-content {
  margin: 10px 0px 20px;
  text-align: center;
}

.signup-custom-style-form {
  width: 100%;
  text-align: center;

  &__contanier {
    width: 100%;
    max-width: 414px;
    display: inline-block;

    .modal-login-content-form-textbox__signup {
      width: 100%;
      padding: 0px;
      text-align: left;
    }
  }
}

.custom-submit {
  width: 100%;
  max-width: 300px;
}

.signup-phone {
  width: 100%;
  padding: 0px;
  text-align: left;
}
