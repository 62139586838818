.admin-event__right-header {
  @include display-flex(row);

  justify-content: flex-end;
  align-items: center;

  &__button {
    margin-right: 10px;
  }

  &__select {
    margin-right: 10px;
  }

  &__select-box {
    min-width: 150px;
    max-width: 200px;
  }

  &__heading {
    &__row {
      align-items: center;
      margin-right: 10px;

      span {
        font-weight: $semi-bold;
        margin-left: 5px;
      }
    }
  }

  &__sub-heading {
    position: relative;

    &__time {
      position: absolute;
      bottom: -35px;
      color: $bluecolor;
    }
  }
}

.admin-event__information {
  margin-top: 20px;
}

.admin-event__event-name {
  font-weight: bold;
  margin-right: 10px;
}

.admin-event__analytics {
  margin: 15px 0;

  .analytics-container__items {
    justify-content: center;

    div {
      margin-right: 15px;
    }
  }
}

.admin-event__datetime {
  margin: 20px 0;
}

.admin-event___datetime__status {
  color: red;
  margin-left: 5px;
}

.filters--right {
  @include display-flex(row);

  justify-content: flex-end;
}

.invited__filter {
  min-width: 160px;
}

.admin-bulk-invited-event {
  align-items: center;
}

.match-candidates {
  margin-right: 15px;
}

.new-user-indicator {
  color: $custom-red;
}

.job-information__event__filter {
  margin-top: 0px;
  margin-bottom: 10px;

  @media (min-width: 1400px) {
    margin-top: -50px;
  }
}
