.view-resume__header {
  color: $custom-orange;
  margin: 20px 0;

  button {
    border: none;
    background: transparent;
    color: $recruiter-color;
    font-weight: $semi-bold;
    outline: none;

    &:hover {
      background: transparent;
      color: $recruiter-color;
      font-weight: $bold;
    }
  }
}

.view-resume__container {
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;
  width: 100%;
  z-index: 1;
  position: relative;
  color: $recruiter-color;
  background: $white;
  padding: 20px;
  height: auto;
  max-width: auto;
  max-height: auto;
}

.resume-container {
  background-color: $white;
}

.resume-pdf-container {
  padding-bottom: 10px;
}

.view-resume {
  display: flex;
  flex-direction: row;

  @media (max-width: $mobile) {
    flex-direction: column;

    .profile-links-btn {
      margin-top: 30px;
    }
  }
}

.resume__container {
  display: flex;
  position: relative;
  width: 70%;
  z-index: 1;
  background: $resume_color;
  margin-top: 20px;
  justify-content: center;
  overflow-y: auto;
}

.view-resume__menu {
  max-width: 300px;
  width: 100%;
  min-width: 160px;
  top: -33px;
  left: -34px;
  border-radius: 5px;

  li {
    a {
      cursor: pointer;
    }

    &:hover {
      color: $recruiter-color;
      background-color: $white;
    }

    &:hover a {
      color: $recruiter-color;
    }
  }
}

.resume__icon--right {
  font-weight: $bold;
  align-content: center;
  align-items: center;
  height: 31px;

  &--gray {
    width: 6px;
    height: 6px;
    background-color: $sheader;
    justify-content: space-around;
    margin-bottom: 2px;
    border-radius: 5px;
  }

  span {
    width: 16px;
    height: 31px;

    svg {
      width: 30px;
      height: 40px;
      fill: $sheader;
    }
  }
}

.resume-skills-information__circle {
  &--mandatory {
    background-color: $recruiter-color;
    display: flex;
    flex-direction: row;
    width: 14px;
    height: 14px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 100%;
  }

  &--preferred {
    display: flex;
    flex-direction: row;
    width: 14px;
    height: 14px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 100%;
    background-color: $gray-2;
  }

  &--any {
    display: flex;
    flex-direction: row;
    width: 14px;
    height: 14px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 100%;
    background-color: $bluecolor;
  }
}

.skill-info__text {
  @include large-font-mixin;

  color: $sub-header;
  margin-top: 6px;
  font-weight: $medium;
  padding: 0 10px;
}

.view_resume_options {
  box-shadow: $shadow;
  display: flex;
  position: relative;
  width: 30%;
  z-index: 1;
  color: $sheader;
  background: $white;
  margin-top: 20px;
  padding: 5px 10px;
  height: max-content;
}

@media (max-width: 768px) {
  .view-resume__container {
    display: flex;
    max-width: auto;
    max-height: auto;
  }

  .resume__container {
    box-shadow: $shadow;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 600px;
    z-index: 1;
    position: relative;
    background: $resume_color;
    margin-top: 20px;
  }

  .view_resume_options {
    box-shadow: $shadow;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: $sheader;
    position: relative;
    background: $white;
    margin-top: 20px;
  }
}

.resume__container {
  div {
    width: 100%;
  }
  & > div > div > div {
    canvas,
    div {
      width: 100% !important;
    }
  }
}
