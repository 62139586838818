.event-analytics__container {
  background-color: $color_organizer;
  width: 100%;
  border-radius: 10px;
}

.filter {
  width: 25px;
  height: 25px;
  color: white;
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}

.alignCenter {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.analytics-cont {
  margin-top: 10px;
}

.event-analytics__container__wrapper {
  font-size: 15px;
  color: $sheader;
}

.event-analytics__container__wrapper-datetime {
  font-weight: bold;
  font-size: 15px;
  color: $sub-header;
}

.attendees-event-title {
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: $font14;
  color: $sheader;
}

.anaheading {
  margin-bottom: 10px;
}

.righttimer {
  margin: 0 0 0 auto;
  color: 'gray';
}

.event-timer {
  color: $color_organizer;
  font-weight: bold;
  cursor: pointer;
}

.header-select-text {
  color: $black-text;
}

.event-timer-text {
  color: $sub-header;
}

.attendees-management__tab .attendees-management__tab__container,
.analytics-modal {
  .header-Select {
    width: 100%;
    margin-bottom: 0px;
  }
}

.event-analytics__container__wrapper {
  width: 100%;
  z-index: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 30px 30px;

  &--icon--left {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 15px;
  }

  &__columns {
    margin-bottom: 0;
    margin-left: 10px;

    &__text--right {
      font-size: $font11;
    }

    &--right {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.created_eve {
  background-color: $primary-color;
  border-radius: $bradius;
  box-shadow: $shadow;
  overflow: hidden;
  position: relative;

  .circle_1 {
    max-width: 130px;
    width: 100%;
    background: #6dc9b7;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    right: -7px;
    top: -51px;
    z-index: 1;
  }

  .circle_2 {
    max-width: 85px;
    width: 100%;
    background: #5ac4af;
    height: 85px;
    border-radius: 50%;
    position: absolute;
    right: -23px;
    top: 50px;
    z-index: 0;
  }
}

.filter_events {
  margin-bottom: 15px;
}

.action_sec {
  display: flex;
  justify-content: space-between;
}
.analytics-modal {
  .ant-modal-header {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
}

.analytics-modal__event__box .action_sec div.left h6 {
  color: $sub-header;
  float: left;
  font-size: $font11;
  line-height: 23px;
  align-items: center;
}

.analytics-modal__event__analytics {
  color: $sheader;
  font-size: $font13;
  font-weight: bold;

  &__datetime {
    font-size: $font11;
  }
}
.organizer-analytics-modal__event__box {
  background: $white;
  margin-top: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.organizer-analytics-modal__heading__icon--right {
  font-weight: bold;
  align-content: center;
  align-items: center;
  height: 31px;

  &--gray {
    width: 4px;
    height: 4px;
    background-color: $sheader;
    justify-content: space-around;
    margin-bottom: 2px;
    border-radius: 5px;
  }

  span {
    width: 16px;
    height: 31px;

    svg {
      width: 30px;
      height: 40px;
      fill: $sheader;
    }
  }
}

.analytics-modal__header {
  color: $color_organizer;
  font-size: $font14;
  margin-top: 15px;
  display: block;
}

.modal-icon {
  font-size: 20px;
  font-weight: bold;
}

.analytics-modal__heading {
  color: black;
  font-weight: bold;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.analytics-modal__heading__title--left {
  font-size: $font14;
}

.analytics-modal__additional__information__container {
  padding-left: 20px;
}

.analytics-modal__additional__information {
  margin-top: 20px;
  margin-bottom: 20px;
}

.analytics-modal__close__button--white {
  border-color: white;
  font-size: $font13;
  color: $sheader;
}

.analytics-modal__additional__information__row {
  margin-top: 20px;

  &__key {
    font-size: $font13;
    color: $header;
  }
}

.modalEventName {
  color: $sheader;
  font-weight: bold;
  font-size: $font13;
}

.action_sec div.left svg {
  width: 18px;
  fill: gray;
  margin-right: 15px;
  position: relative;
  top: 5px;
}

.analytics-modal__heading__icon--right--gray,
.candidate-admin-information__heading__icon--right--gray {
  width: 6px;
  height: 6px;
  background-color: #707070;
  justify-content: space-around;
  margin-bottom: 2px;
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .analytics-modal__additional__information {
    padding-left: 0;
  }

  .created_eve {
    .circle_1 {
      max-width: 200px;
      width: 100%;
      background: #6dc9b7;
      height: 200px;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -51px;
      z-index: 1;
    }

    .circle_2 {
      max-width: 120px;
      width: 100%;
      background: #5ac4af;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      right: -23px;
      top: 70px;
      z-index: 0;
    }
  }
}

.skillDiv {
  background-color: $very-light-grey-3;
  color: $sheader;
  font-size: 11px;
  padding: 5px;
  margin-right: 10px;
}

.attendeesModal {
  width: 100%;
}

@media (max-width: $mobile) {
  .analytics-modal__additional__information {
    padding-left: 0;
  }

  .event-analytics__container__wrapper__columns__text--right {
    font-size: 15px;
    font-weight: bold;
  }

  .event-analytics__container__wrapper {
    padding: 10px 10px 20px;
  }

  .event-analytics__container__wrapper__columns {
    margin-top: 20px;
  }
  .organizer-analytics-modal__heading__title--left {
    color: $ctextcolor;
  }

  .modalEventName {
    letter-spacing: 0px;
    color: $ctextcolor;
  }

  .attendees-event-title {
    display: none;
  }

  .filter_events {
    margin-top: 10px;
  }

  .event-timer {
    color: $color_organizer;
    font-weight: bold;
    cursor: pointer;
  }

  .header-select-text {
    color: $sub-header;
  }

  .event-timer-text {
    color: $sub-header;
  }

  .analytics-modal__additional__information__row__key {
    font-size: 13px;
    letter-spacing: 0;
    color: $header;
  }

  .analytics-modal__additional__information__row__skills {
    margin-top: 20px;

    &__header {
      font-size: $font14;
      letter-spacing: 0px;
      color: $ctextcolor;
      margin-top: 20px;
    }
  }

  .analytics-modal__additional__information__container {
    padding-left: 0;
  }

  .analytics-modal__header {
    display: none;
  }

  .ant-modal-header {
    padding: 0;
  }

  .event-timer-text {
    margin-top: 10px;

    @media (max-width: $mobile) {
      padding-top: 15px;
      padding-bottom: 15px;

      &.event-timer-text.large-font {
        margin-left: 5px;
      }
    }
  }

  .analytics-modal__event__box .action_sec div.left h6 {
    color: $sub-header;
    float: left;
    line-height: 23px;
    display: flex;
    align-items: center;
  }

  .analytics-modal__event__analytics {
    font-size: $font14;
    letter-spacing: 0;
    color: $header;
    opacity: 1;

    &__datetime {
      letter-spacing: 0;
      color: $sub-header;
      font-size: $font13;
      font-size: 13px;
      opacity: 1;
    }
  }

  .analytics-modal__close__button--white {
    display: none;
  }

  .analytics-modal {
    top: 0;
    bottom: 0;
  }

  .attendees-event-analytics {
    letter-spacing: 0;
    color: $header;

    &-datetime {
      font-size: $font13;
      letter-spacing: 0;
      color: $sub-header;
    }
  }
}

.analytics-modal {
  .event-analytics__container__wrapper--icon--left {
    background-color: $organizer-shadow;
  }
}

.recruiter-analytics-modal {
  .event-analytics__container__wrapper--icon--left {
    background-color: $recruiter-shadow;
  }
}

@media (min-width: $desktop-min) {
  @media screen and (max-width: $mobile) {
    .analytics-modal__additional__information {
      padding-left: 0;
    }

    .created_eve {
      .circle_1 {
        max-width: 200px;
        width: 100%;
        background: #6dc9b7;
        height: 200px;
        border-radius: 50%;
        position: absolute;
        right: -7px;
        top: -51px;
        z-index: 1;
      }

      .circle_2 {
        max-width: 120px;
        width: 100%;
        background: #5ac4af;
        height: 120px;
        border-radius: 50%;
        position: absolute;
        right: -23px;
        top: 70px;
        z-index: 0;
      }
    }
  }

  .event-analytics__container {
    background-color: $color_organizer;
    width: 100%;
    border-radius: 10px;
  }

  .filter {
    width: 30px;
    height: 30px;
    color: white;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg)
      brightness(118%) contrast(119%);
  }

  .alignCenter {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .analytics-cont {
    margin-top: 10px;
  }

  .event-analytics__container__wrapper {
    font-size: 15px;
    color: $sheader;
  }

  .event-analytics__container__wrapper-datetime {
    font-weight: bold;
    font-size: 15px;
    color: $sub-header;
  }

  .attendees-event-title {
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    color: $sheader;
  }

  .anaheading {
    margin-bottom: 10px;
  }

  .righttimer {
    margin: 0 0 0 auto;
    color: 'gray';
  }

  .event-timer {
    color: $color_organizer;
    font-weight: bold;
    cursor: pointer;
  }

  .header-select-text {
    color: $black-text;
  }

  .event-timer-text {
    color: $sub-header;
  }

  .attendees-management__tab .attendees-management__tab__container,
  .analytics-modal {
    .header-Select {
      width: 100%;
      margin-bottom: 0px;
    }
  }

  .event-analytics__container__wrapper {
    width: 100%;
    z-index: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;

    &__columns {
      margin-left: 30px;

      &--right {
        color: white;
        display: flex;
        height: 63px;
        flex-direction: column;
        justify-content: center;
      }

      &__text--right {
        font-size: small;
      }
    }
  }

  .event-analytics__container__wrapper--icon--left {
    width: 63px;
    height: 63px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 15px;
  }

  .created_eve {
    background-color: $primary-color;
    border-radius: $bradius;
    box-shadow: $shadow;
    overflow: hidden;
    position: relative;

    .circle_1 {
      max-width: 130px;
      width: 100%;
      background: #6dc9b7;
      height: 130px;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -51px;
      z-index: 1;
    }

    .circle_2 {
      max-width: 85px;
      width: 100%;
      background: #5ac4af;
      height: 85px;
      border-radius: 50%;
      position: absolute;
      right: -23px;
      top: 50px;
      z-index: 0;
    }
  }

  .filter_events {
    margin-bottom: 15px;
  }

  .action_sec {
    display: flex;
    justify-content: space-between;
  }

  .analytics-modal__event__box .action_sec div.left h6 {
    color: $sub-header;
    float: left;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;
  }

  .analytics-modal__event__analytics {
    color: $sheader;
    font-size: $font18;
    font-weight: bold;
  }

  .analytics-modal__event__analytics__datetime {
    font-size: 16px;
  }
  .organizer-analytics-modal__event__box {
    background: $white;
    margin-top: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .analytics-modal__heading__icon--right,
  .candidate-admin-information__heading__icon--right {
    font-size: 30px;
    font-weight: bold;
    align-content: center;
    align-items: center;
    height: 31px;

    &--gray {
      width: 6px;
      height: 6px;
      background-color: $sheader;
      justify-content: space-around;
      margin-bottom: 2px;
      border-radius: 5px;
    }

    span {
      width: 16px;
      height: 31px;

      svg {
        width: 30px;
        height: 40px;
        fill: $sheader;
      }
    }
  }

  .analytics-modal__header {
    color: $color_organizer;
    font-size: 20px;
    margin-top: 15px;
  }

  .modal-icon {
    font-size: 20px;
    font-weight: bold;
  }

  .analytics-modal__heading {
    color: black;
    font-weight: bold;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .analytics-modal__heading__title--left {
    font-size: 20px;
  }

  .analytics-modal__additional__information__container {
    padding-left: 20px;
  }

  .analytics-modal__additional__information {
    margin-top: 20px;
    margin-bottom: 20px;

    &__row {
      margin-top: 20px;

      &__key {
        font-size: $font18;
        color: $header;
      }
    }
  }

  .analytics-modal__close__button--white {
    border-color: white;
    font-size: $font18;
    color: $sheader;
  }

  .modalEventName {
    color: $sheader;
    font-weight: bold;
    font-size: $font18;
  }

  .action_sec div.left svg {
    width: 23px;
    fill: gray;
    margin-right: 15px;
    position: relative;
    top: 5px;
  }

  .skillDiv {
    background-color: $very-light-grey-3;
    color: $sheader;
    font-size: 14px;
    padding: 5px;
    margin-right: 10px;
  }

  .attendeesModal {
    width: 100%;
  }
}
