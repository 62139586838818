.chat__main {
  &__heading {
    @include display-flex(row);

    margin-top: 20px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $mobile) {
      margin-top: 0;
      margin-bottom: -5px;
    }

    &__breadcrumb {
      margin: 10px 0;
    }

    &__back {
      display: none;

      @media (max-width: $mobile) {
        @include display-flex(row);

        svg {
          margin-right: 8px;
        }
      }
    }

    h5 {
      @include small-heading-mixin;

      font-weight: $semi-bold;
      margin: 0;

      @media (min-width: $desktop-min) {
        @include medium-heading-mixin;
      }

      @media (max-width: $mobile) {
        @include large-heading-mixin;
      }
    }

    &--left {
      @include display-flex(row);

      h5 {
        align-items: center;
      }

      svg {
        display: none;

        @media (max-width: $mobile) {
          display: block;
          margin-right: 8px;
        }
      }
    }

    &--right {
      @include display-flex(row);

      align-items: center;

      @media (max-width: $mobile) {
        @include medium-heading-mixin;

        margin: 10px 0;
      }

      h5 {
        @include small-heading-mixin;

        color: $ctextcolor;
        margin: 0 10px;

        @media (min-width: $desktop-min) {
          @include medium-heading-mixin;
        }
      }

      p {
        @include small-heading-mixin;

        margin-left: 20px;
        padding: 5px 35px;
        border-radius: 3px;
        font-weight: $semi-bold;
        border: 2px solid;
        cursor: pointer;

        @media (min-width: $desktop-min) {
          @include medium-heading-mixin;

          margin-left: 20px;
          padding: 6px 45px;
        }

        @media (max-width: $mobile) {
          margin-left: 15px;
          padding: 5px 20px;
          text-align: center;
        }
      }
    }
  }
}

.thread__input {
  padding-top: 10px;
  justify-self: flex-end;
  flex-shrink: 0;

  @media (max-width: $mobile) {
    margin: 0 10px;
  }

  &__first__name {
    @include small-heading-mixin;

    color: $icon_color;
    margin: 5px 0;
  }

  &__inputBox {
    @include display-flex(row);

    width: 100%;
    height: 60px;
    background-color: $very-light-grey-5;
    align-items: center;
    font-size: 20px;

    @media (min-width: $desktop-min) {
      height: 70px;
    }
  }

  span {
    @include display-flex(row);

    align-items: center;
    background-color: $very-light-grey-5;
    height: 100%;
    border: 1px solid $very-light-grey-5;

    input {
      height: 100%;
      background-color: $very-light-grey-5;
    }
  }
}

// For Kebab Menu items
.chat_menu {
  max-width: 300px;
  width: 100%;
  min-width: 160px;
  top: -33px;
  left: -34px;
  border-radius: 5px;

  @media (min-width: $desktop-min) {
    max-width: 300px;
    width: 100%;
    min-width: 160px;
  }

  li {
    cursor: pointer;

    &:hover {
      color: $custom-red;
      background-color: $white;
    }

    &:hover a {
      cursor: pointer;
      color: $custom-red;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.chats__loading {
  color: $white;
}
