//container-width
$eve_container: 1640px;

//
$input-color: #bfbfbf;
$input-bg: #f0f0f0;
$bluecolor: #1890ff;
$light_gray: #f5f5f5;

//font
$efont34: 34px;
$efont16: 16px;
$efont14: 14px;
$efont15: 15px;
$efont17: 17px;
$efont18: 18px;
$efont24: 24px;
$efont20: 20px;
$efont30: 30px;
$efont36: 36px;
$eheight53: 53px;

//
.mt180 {
  margin-top: 180px !important;
}

.ac {
  align-items: center;
}

//event
.form-group {
  position: relative;
}

.custom_contain {
  max-width: $eve_container;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  // overflow: hidden;
}
//
#event_title {
  padding: 20px 0 42px;
  // title-page
  .title_frnt {
    color: $titlefr_color;
    font-size: $efont34;
    font-weight: $bold;
    position: relative;
    padding-left: 35px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: -8px;
      width: 19px;
      height: 69px;
      background-color: $bluecolor;
    }
  }
}

.public-event-share__button {
  display: flex;
  justify-content: space-around;
  padding: 10px;

  @media (max-width: $mobile) {
    padding: 5px 0;
  }
}
// search input
.form-group.search_input {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: 0 0 0 auto;

  svg {
    position: absolute;
    right: 18px;
    font-size: 24px;
    top: 50%;
    fill: $gray;
    transform: translate(0, -50%);
  }

  input {
    color: $input-color;
    border-radius: $bradius3;
  }
}

//loc-input
.form-group.loc_input {
  position: relative;
  max-width: 398px;
  width: 100%;
  margin-right: 15px;

  svg {
    position: absolute;
    right: 18px;
    font-size: 24px;
    top: 50%;
    fill: $gray;
    transform: translate(0, -50%);
  }

  input {
    color: $input-color;
    background-color: $input-bg;
    border-radius: $bradius3;
  }
}

//select type event
.select_type,
.select_filter {
  max-width: 276px;
  width: 100%;
  border-radius: $bradius3;
  position: relative;

  .ant-select {
    width: 100%;

    div.ant-select-selector {
      color: $input-color;
      background-color: $input-bg;
      border-radius: $bradius3;
    }

    .ant-select-arrow {
      display: none;
    }
  }

  svg {
    position: absolute;
    right: 18px;
    font-size: 16px;
    top: 50%;
    fill: $gray;
    transform: translate(0, -50%);
  }
}

//filter
.select_filter {
  max-width: 168px;
  margin: 0 0 0 auto;
}

//active tab
.tab_recommnd {
  margin-top: 60px;

  ul {
    @include ul-default;

    li {
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;

      button {
        padding: 5px 20px;
        border-radius: $bradius3;
        background-color: $light_gray;
        font-size: $efont18;
        border: none;
        cursor: pointer;
      }
    }

    li.active {
      button {
        background-color: $bluecolor;
        color: $white;
      }
    }
  }
}

//slider-event
.slider-event {
  background-color: $white;
  border-radius: $bradius;
  box-shadow: $shadow;
  margin-top: 80px;
  margin-bottom: 80px;

  .left_text {
    .top_text {
      padding-left: 20px;

      button {
        max-width: 205px;
        width: 100%;
        background-color: $bluecolor;
        color: $white;
        border: none;
        padding: 10px 20px;
        margin-bottom: 36px;
        margin-top: 20px;
        font-size: $efont18;
      }

      h4 {
        font-size: $efont24;
        color: $black;
        font-weight: $bold;
      }

      h6 {
        font-size: $efont16;
        color: $gray;
        font-weight: $regular;
        margin-bottom: 25px;
      }

      h6.blue_text_ico {
        color: $bluecolor;
        font-weight: $bold;

        svg {
          fill: $bluecolor;
          margin-right: 15px;
          position: relative;
          top: 3px;
        }
      }

      span {
        svg {
          font-size: 18px;
          fill: $gray;
          margin-right: 15px;
          position: relative;
          top: 3px;
        }
      }

      p {
        font-size: $efont15;
        color: $gray;
        font-weight: $regular;
        margin-bottom: 23px;
      }
    }

    .blue_btm_box {
      background-color: $bluecolor;
      padding: 20px 20px 8px;
      border-radius: 0 0 0 10px;

      h6 {
        font-size: $efont14;
        color: $white;
        font-weight: $regular;
        margin: 0;
      }

      b {
        font-size: $efont24;
        color: $white;
        font-weight: $semi-bold;
      }

      p {
        color: $white;
        font-size: $efont18;
      }
    }
  }
  // right side
  .right_slider {
    .eve-slider {
      border-radius: 10px;

      img {
        width: 100%;
        height: 469px;
        object-fit: cover;
        border-radius: 0 10px 10px 0;
      }
    }
  }
}

//card section
.card_event {
  margin-top: 200px;
  margin-bottom: 100px;

  .card_box {
    position: relative;
    box-shadow: $shadow2;
    border-radius: 5px 5px 0 0;

    img {
      width: 100%;
      height: 326px;
      border-radius: 5px 5px 0 0;
    }

    .text_div {
      padding: 18px 18px 0;

      h5 {
        color: $black;
        font-size: $efont18;
        font-weight: $bold;
      }

      p {
        color: $ctextcolor;
        font-size: $efont16;
        padding-bottom: 20px;
        font-weight: $regular;
      }

      b {
        color: $bluecolor;
        font-size: $efont16;
        padding-bottom: 18px;
        font-weight: $semi-bold;
        display: block;

        svg {
          fill: $bluecolor;
          font-size: 24px;
          margin-right: 10px;
          position: relative;
          top: 3px;
        }
      }

      h6 {
        color: $ctextcolor;
        font-size: $efont16;
        padding-bottom: 20px;
        font-weight: $bold;

        span {
          color: $ctextcolor;
          font-size: $efont16;
          font-weight: $regular;
        }
      }
    }

    .action_sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 18px 18px;

      button {
        background-color: $bluecolor;
        color: $white;
        font-size: $efont18;
        padding: 9px 42px;
        border: none;
        border-radius: 3px;
      }

      svg {
        font-size: 22px;
        fill: $ctextcolor;
      }
    }
  }
}
// live event
.live-event {
  h5 {
    box-shadow: $shadow2;
    border-radius: 20px;
    height: 39px;
    position: absolute;
    right: 14px;
    top: 16px;
    z-index: 1;
    color: $titlefr_color;
    display: flex;
    background: $white;
    padding: 7px 14px;
    font-weight: $semi-bold;

    svg {
      fill: $titlefr_color;
      margin-left: 10px;
      font-size: 24px;
      width: 22px;
    }
  }
}

.live-event.redclr {
  h5 {
    color: $live_color;

    svg {
      fill: $live_color;
    }
  }
}

//getstarted
#howitworks {
  margin-bottom: 100px;
  margin-top: 108px;

  div {
    font-size: $efont24;
    display: block;
    text-align: center;
    color: $icon_color;
    margin-bottom: 18px;
  }

  .joinwithus {
    font-weight: 500;
    background: $bluecolor;
    max-width: 378px;
    margin: 0 auto;
    height: 61px;
    display: flex;
    box-align: center;
    align-items: center;
    box-pack: center;
    justify-content: center;
    color: $white;
  }
}
//media
@media (min-width: 1000px) {
  .custom_contain {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .custom_contain {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .custom_contain {
    max-width: 1400px;
  }
}

@media (min-width: 1550px) {
  .custom_contain {
    max-width: 1520px;
  }
}

@media (min-width: 1700px) {
  .custom_contain {
    max-width: 1640px;
  }
}

//mobile
//mobile
@media (min-width: $mobile) {
  .slider-event .left_text .top_text {
    height: 382px;
  }
}

//event detail page scss
.title_explore,
.public-event__container .title_explore {
  @include display-flex(row);

  position: relative;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }

  .exploretitle {
    font-weight: $bold;
    color: $header;
    margin-bottom: 0px;
    &:hover {
      cursor: pointer;
    }
  }

  svg {
    transform: rotate(180deg);
  }
}

//fullslider
.fullslider {
  .right_slider {
    border-radius: $bradius10;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: $bradius10;
    }

    ul.slick-dots {
      left: unset;
      margin-right: 5%;

      li {
        button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: transparent;
          border: 1px solid $white;
        }
      }

      li.slick-active {
        button {
          background-color: $white;
        }
      }
    }
  }

  .live-event {
    h5 {
      font-size: $efont16;
      top: 45px;
      right: 35px;
      font-weight: $semi-bold;
    }
  }
}
//
.bookeventsec {
  margin-top: 40px;

  h2 {
    color: $black;
    font-weight: $bold;
    display: inline-block;
  }

  .recommnded_btn {
    font-size: $efont14;
    background-color: $bluecolor;
    color: $white;
    width: min-content;
    height: min-content;
    padding: 7px 30px;
    border: none;
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 30px;
  }

  .blue_icon_btn {
    background-color: $bluecolor;
    padding: 8px 8px 2px;
    border-radius: $bradius12;
    border: none;
    color: $white;
    margin-right: 30px;

    svg {
      font-size: 24px;
    }
  }

  .bookevent_btn {
    background-color: $bluecolor;
    font-size: $efont18;
    padding: 19px 15px;
    border: none;
    color: $white;
    max-width: 276px;
    width: 100%;
  }
}

.flex_center_end {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $mobile) {
    justify-content: space-between;
    margin: 15px 0;
  }
}

//dateevent
.dateeventsec {
  ul {
    @include ul-default;

    li {
      display: inline-block;
      margin-right: 23px;
      color: $ctextcolor;
      font-weight: $medium;

      p {
        svg {
          width: 17px;
          font-size: 17px;
          fill: $ctextcolor;
          margin-right: 15px;
          position: relative;
          top: 3px;
        }
      }
    }

    li.blue_li {
      font-weight: $semi-bold;
      color: $bluecolor;

      svg {
        fill: $bluecolor;
      }
    }
  }
}

//onwards
.onwards {
  font-size: $efont24;
  color: $titlefr_color;
  font-weight: $semi-bold;

  span {
    font-size: $efont16;
    color: $ctextcolor;
    font-weight: $regular;
  }

  span.medium {
    font-weight: $medium;
  }
}

//tagsection
.tag_sec,
.dateeventsec .tag_sec,
.view-event-page .tag_sec {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  & > button {
    @include pseudo-normal-effect-tag(active);
    @include pseudo-normal-effect-tag(focus);
    @include pseudo-normal-effect-tag(hover);
  }

  h6 {
    color: $header;
    font-weight: $semi-bold;
    padding-bottom: 30px;

    @media (max-width: $mobile) {
      padding-bottom: 10px;
    }
  }

  button {
    color: $tgtextcolor;
    background-color: $very-light-grey-3;
    border: none;
    margin-right: 7px;
    margin-bottom: 8px;
    white-space: normal;
    height: auto;

    span {
      @include medium-font-mixin;
      word-break: break-all;

      @media (min-width: $desktop-min) {
        @include large-font-mixin;
      }
    }
  }
}
//para
div.para_content,
p.para_content {
  color: $sub-header;
  line-height: 38px;
  text-align: justify;
  margin: 90px 0;
}

//attending_companys
.attending_companys {
  h6 {
    font-size: $efont18;
    color: $titlefr_color;
    float: left;
    margin-bottom: 55px;
    font-weight: $semi-bold;
  }

  button {
    float: right;
    font-size: $efont18;
    color: $sub-header;
    border: none;
    background-color: $white;
  }

  ul {
    @include ul-default;

    clear: both;
    margin-top: 55px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      text-align: center;
      max-width: 212px;
      width: 100%;
      margin-bottom: 90px;

      img {
        max-width: 87px;
        width: 100%;
        height: 87px;
        object-fit: contain;
        margin-bottom: 22px;
        padding: 15px;
      }

      p {
        font-size: $efont17;
        color: $ctextcolor;
      }
    }
  }
}

//ticketpricing
.TicketPricing {
  overflow: hidden;

  h4 {
    color: $header;
    margin-bottom: 20px;
    font-weight: $semi-bold;
  }

  h5 {
    color: $ctextcolor;
    font-weight: $medium;
    max-width: 300px;
    width: 100%;
    margin-bottom: 20px;
    float: left;
  }

  p {
    color: $tgtextcolor;
    font-weight: $bold;
    float: left;
  }
}

//haveques
.haveques {
  margin-top: 50px;
  margin-bottom: 50px;

  h4 {
    font-size: $efont18;
    color: $titlefr_color;
    margin-bottom: 20px;
    font-weight: $semi-bold;
  }

  p {
    font-size: $efont16;
    color: $sub-header;
    margin-bottom: 20px;
    font-weight: $medium;
  }

  button {
    color: $bluecolor;
    border: 1px solid $bluecolor;
    max-width: 200px;
    width: 100%;
    height: 39px;
    background-color: $white;
  }
}

//faq
.faq_terms {
  .ant-collapse-header {
    @include medium-heading-mixin;

    padding: 0 !important;
    padding-bottom: 15px !important;
    position: relative;
    color: $titlefr_color;
    margin-bottom: 0;
    font-weight: $semi-bold;
    background: $white;

    .anticon {
      position: absolute;
      right: 0;
      top: -10px;

      svg {
        position: absolute;
        right: 0;
        display: block;
        top: -10px;
      }
    }
  }

  .ant-collapse-content-box {
    @include large-font-mixin;

    padding: 20px 0 !important;

    p {
      padding-left: 0 !important;
    }
  }
}
//color_dot
.color_dot {
  margin-left: 0;

  @media (min-width: $desktop-min) {
    margin-left: 25px;
  }

  p {
    color: $tgtextcolor;
    font-weight: $medium;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 10px;
      display: inline-block;

      @media (min-width: $desktop-min) {
        width: 18px;
        height: 18px;
      }
    }

    span.yellow {
      background-color: $custom-orange;
    }

    span.blue {
      background-color: $bluecolor;
    }

    span.green {
      background-color: $primary-color;
    }
  }
}

//organizer tags
.org_tag {
  margin-bottom: 10px;

  .pl30 {
    padding-left: 30px;
  }

  .tags {
    margin-top: 30px;
  }

  p.tag_title {
    color: $header;
    margin-bottom: 20px;
    font-weight: $semi-bold;
    margin-right: 24px;
    position: relative;
    top: 5px;
  }

  h4 {
    color: $titlefr_color;
    font-weight: $bold;
    position: relative;

    &::after {
      content: '';
      width: 57px;
      height: 8px;
      background-color: $bluecolor;
      position: absolute;
      left: 0;
      bottom: -13px;
    }
  }

  h5 {
    color: $titlefr_color;
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }

  ul {
    @include ul-default;

    li {
      display: inline-block;
      margin-right: 10px;
    }
  }

  p {
    color: $placeholder;
  }

  h6 {
    color: $ctextcolor;
    margin-top: 20px;
  }
}

@mixin pseudo-normal-effect($element) {
  &:#{$element} {
    background-color: $very-light-grey-3;
    color: $tgtextcolor;
  }
}

.tag-container {
  .tag-item {
    margin: 0 8px;

    button {
      @include pseudo-normal-effect(hover);
      @include pseudo-normal-effect(focus);
      @include pseudo-normal-effect(active);
    }
  }
  // .tag_sec button {
  // }
}

//media
//

@media (min-width: $desktop-min) {
  .tag_sec,
  .dateeventsec .tag_sec,
  .view-event-page .tag_sec {
    h6 {
      color: $header;
      font-weight: $semi-bold;
      padding-bottom: 30px;
    }

    button {
      border: none;
      padding: 6px 10px;
      margin-right: 7px;
      margin-bottom: 8px;
      color: $tgtextcolor;
      background-color: $very-light-grey-3;
      white-space: normal;
    }
  }
  //para
  div.para_content,
  p.para_content {
    color: $ctextcolor;
    line-height: 38px;
    text-align: justify;
    margin: 90px 0;
  }

  .TicketPricing {
    overflow: hidden;

    h4 {
      color: $header;
      margin-bottom: 20px;
      font-weight: $semi-bold;
    }

    h5 {
      color: $ctextcolor;
      font-weight: $medium;
      max-width: 300px;
      width: 100%;
      margin-bottom: 20px;
      float: left;
    }

    p {
      color: $tgtextcolor;
      font-weight: $bold;
      float: left;
    }
  }

  //haveques
  .haveques {
    margin-top: 50px;
    margin-bottom: 50px;

    h4 {
      font-size: $efont18;
      color: $titlefr_color;
      margin-bottom: 20px;
      font-weight: $semi-bold;
    }

    p {
      font-size: $efont16;
      color: $sub-header;
      margin-bottom: 20px;
      font-weight: $medium;
    }

    button {
      color: $bluecolor;
      border: 1px solid $bluecolor;
      max-width: 200px;
      width: 100%;
      height: 39px;
      background-color: $white;
    }
  }

  tags .org_tag {
    margin-bottom: 45px;

    .pl30 {
      padding-left: 30px;
    }

    .tags {
      margin-top: 30px;
    }

    p.tag_title {
      color: $header;
      margin-bottom: 20px;
      font-weight: $semi-bold;
      margin-right: 24px;
      position: relative;
      top: 5px;
    }

    h4 {
      font-size: $efont24;
      color: $titlefr_color;
      font-weight: $bold;
      position: relative;

      &::after {
        content: '';
        width: 57px;
        height: 8px;
        background-color: $bluecolor;
        position: absolute;
        left: 0;
        bottom: -13px;
      }
    }

    h5 {
      font-size: $efont20;
      color: $titlefr_color;
      padding: 0;
      margin: 0;
      margin-top: 50px;
    }

    ul {
      @include ul-default;

      li {
        display: inline-block;
        margin-right: 10px;
      }
    }

    p {
      color: $placeholder;
    }

    h6 {
      color: $ctextcolor;
      margin-top: 20px;
    }
  }

  .faq_terms {
    .ant-collapse-header {
      @include medium-heading-mixin;

      padding: 0 !important;
      padding-bottom: 15px !important;
      position: relative;
      color: $titlefr_color;
      margin-bottom: 0;
      font-weight: $semi-bold;
      background: $white;

      .anticon {
        position: absolute;
        right: 0;
        top: -10px;

        svg {
          position: absolute;
          right: 0;
          display: block;
          top: -10px;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 20px 0 !important;

      p {
        padding-left: 0 !important;
      }
    }
  }
  //color_dot
  .color_dot {
    margin-left: 25px;

    p {
      color: $tgtextcolor;
      font-weight: $medium;
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      span {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
        display: inline-block;
      }

      span.yellow {
        background-color: $custom-orange;
      }

      span.blue {
        background-color: $bluecolor;
      }

      span.green {
        background-color: $primary-color;
      }
    }
  }

  //organizer tags
  .org_tag {
    margin-bottom: 45px;

    .pl30 {
      padding-left: 30px;
    }

    .tags {
      margin-top: 30px;
    }

    p.tag_title {
      color: $header;
      margin-bottom: 20px;
      font-weight: $semi-bold;
      margin-right: 24px;
      position: relative;
      top: 5px;
    }

    h4 {
      color: $titlefr_color;
      font-weight: $bold;
      position: relative;

      &::after {
        content: '';
        width: 57px;
        height: 8px;
        background-color: $bluecolor;
        position: absolute;
        left: 0;
        bottom: -13px;
      }
    }

    h5 {
      color: $titlefr_color;
      padding: 0;
      margin: 0;
      margin-top: 50px;
    }

    ul {
      @include ul-default;

      li {
        display: inline-block;
        margin-right: 10px;
      }
    }

    p {
      color: $placeholder;
    }

    h6 {
      color: $ctextcolor;
      margin-top: 20px;
    }
  }

  .dateeventsec {
    ul {
      @include ul-default;

      li {
        display: inline-block;
        margin-right: 23px;
        color: $ctextcolor;
        font-weight: $medium;

        p {
          font-size: $efont16;

          svg {
            width: 17px;
            font-size: 17px;
            fill: $ctextcolor;
            margin-right: 15px;
            position: relative;
            top: 3px;
          }
        }
      }

      li.blue_li {
        font-weight: $semi-bold;
        color: $bluecolor;

        svg {
          fill: $bluecolor;
        }
      }
    }
  }

  //onwards
  .onwards {
    font-size: $efont24;
    color: $titlefr_color;
    font-weight: $semi-bold;

    span {
      font-size: $efont16;
      color: $ctextcolor;
      font-weight: $regular;
    }

    span.medium {
      font-weight: $medium;
    }
  }
}
