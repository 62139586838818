.notification {
  &__heading {
    margin-top: 30px;
    font-weight: 500;
    position: relative;

    @media (max-width: $mobile) {
      margin-top: 10px;
      font-weight: $semi-bold;
    }
  }

  &__container {
    padding: 30px 20px;
    background-color: $white;
    z-index: 1;
    box-shadow: $shadow;
    margin-top: 30px;
    position: relative;

    @media (min-width: $desktop-min) {
      padding: 44px 30px;
    }

    @media (max-width: $mobile) {
      padding: 20px 10px;
    }

    &__items {
      height: 55vh;
      overflow-y: auto;
      padding-bottom: 20px;

      @media (max-width: $mobile) {
        height: 60vh;
      }
    }
  }
}

.organizer_notification {
  .circle_box {
    background-color: $primary-color;
  }

  .notification__heading {
    color: $primary-color;
  }
}

.recruiter_notification {
  .circle_box {
    background-color: $recruiter-color;
  }

  .notification__heading {
    color: $recruiter-color;
  }
}

.candidate_notification {
  .circle_box {
    background-color: $candidate-color;
  }

  .notification__heading {
    color: $candidate-color;
  }
}

.notification__header-item {
  display: flex;
  flex-direction: row;

  &__link {
    display: flex;
    color: $gray-2;
    flex-direction: row-reverse;

    a {
      @include medium-font-mixin;

      color: $gray-2;
    }
  }
}

a.support_notification_link {
  color: $bluecolor;

  &:hover {
    cursor: pointer;
  }
}

.support_button__alignment {
  @include display-flex(row);

  align-items: center;
  justify-content: center;
  margin-left: -12px;

  span {
    margin-right: 5px;
  }
}
