// Margin Utility classes
.margin-10 {
  margin: 10px;
}

.margin-vertical-10 {
  margin: 10px 0;
}

.margin-horizontal-10 {
  margin: 0 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-vertical-20 {
  margin: 20px 0;
}

.margin-horizontal-20 {
  margin: 20px 0;
}

// Padding Utility classes
.padding-10 {
  padding: 10px;
}

.padding-vertical-10 {
  padding: 10px 0;
}

.padding-horizontal-10 {
  padding: 0 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-vertical-20 {
  padding: 20px 0;
}

.padding-horizontal-20 {
  padding: 20px 0;
}

// Flex Utility classes
.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-stretch {
  align-items: stretch;
}

// Cursor pointer utility class
.cursor-pointer {
  cursor: pointer;
}

$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 5px;
    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;
