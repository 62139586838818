.attendees-management__tab {
  box-shadow: 0 0 10px #0000001a;

  .attendees-management__tab__container {
    width: 100%;
    position: relative;
    background: $white;
    padding: 20px;

    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab {
      @include large-font-mixin;
    }
  }
}

.attendees-management__header {
  color: $color_organizer;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: $semi-bold;
  padding: 10px 0;
  font-size: 16px;

  @media (max-width: $mobile) {
    display: none;
  }
}

.management-table__scrollable {
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 60px;
}

.ant-switch-checked {
  background-color: $color_organizer;
}

.view-invoice-link {
  color: $color_organizer;
  text-decoration: underline;
}

.circle_boxad {
  background: $primary-color;
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.2;
  position: absolute;
  left: -124px;
  top: -100px;
}

.ant-switch-checked {
  background-color: $color_organizer;
}

.view-invoice-link {
  color: $color_organizer !important;
  text-decoration: underline !important;
}

.btn-container,
.management-info,
.filter-btn-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  margin: 0 0 0 auto;
  color: 'gray';
  float: right;
}

.mark-btn-container,
.auto-btn-container {
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: $mobile) {
    margin: 10px 0;
  }
}

.auto-mark-switch {
  margin-left: 20px;
}

.management-table__container__col--left {
  display: flex;
  align-items: center;
  align-content: center;

  @media (max-width: $mobile) {
    margin-bottom: 25px;
  }
}

.management-table__container__col---reverse {
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: $mobile) {
    flex-direction: row;
  }
}

.auto-mark-text {
  color: $color_organizer;
  border: none;
  margin-left: 4px;
  margin-right: 4px;

  &:hover {
    color: $color_organizer;
  }
}

@mixin pseudo-normal-effect($element) {
  &:#{$element} {
    background-color: $color_organizer;
    color: $white;
  }
}

.mark-present-btn {
  @include pseudo-normal-effect(hover);
  @include pseudo-normal-effect(focus);
  @include pseudo-normal-effect(active);

  width: 116px;
  height: 32px;
  background-color: $color_organizer;
  color: $white;
  border: 0;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 3px;

  @media (min-width: $desktop-min) {
    width: 140px;
  }

  span {
    @include large-font-mixin;
  }
}

.filter-select {
  border-radius: 5px;
  opacity: 1;
  width: 120px;
}

.attendees-management__tab
  .attendees-management__tab__container
  .ant-tabs-nav
  .ant-tabs-tab {
  color: $very-light-grey-4;
}

.attendees-management__tab
  .attendees-management__tab__container
  .ant-tabs-nav
  .ant-tabs-tab-active {
  color: $primary_color;
  font-weight: 500;
  outline: none;
}

.attendees-management__tab
  .attendees-management__tab__container
  .ant-tabs-ink-bar {
  width: 0;
  height: 2px;
  background-color: $primary-color;
  transform-origin: 0 0;
  transform-origin: 0 0;
}

.management-table__container--user {
  display: flex;
  align-items: center;
  align-content: center;
}

.attendees-management__responsive__hidden {
  margin-left: 5px;
}

.management-table__container {
  margin-top: 20px;
  margin-bottom: 0;
}
.management-table__id-column {
  width: 60px;
}

.management-table__attendance-column {
  width: 120px;
}

.management-table__status-column {
  width: 120px;
}

.auto-present-btn {
  width: 116px;
  margin-left: 1px;
  margin-right: 1px;

  span {
    @include small-heading-mixin();

    text-align: center;
    letter-spacing: 0;
    color: $tgtextcolor;
  }
}

.auto-mark-text span {
  @include small-heading-mixin();
}

.filter-select {
  width: 116px;
  border-radius: 3px;
}

.management-table__scrollable {
  span,
  th,
  td {
    @include medium-font-mixin;
  }
}

.view-invoice-link {
  @include medium-font-mixin;
}

.attendees-management__tab
  .attendees-management__tab__container
  .ant-tabs-tab.ant-tabs-tab-active
  div {
  color: $primary_color;
  font-weight: 500;
  outline: none;
}

@media (max-width: $mobile) {
  .management-table__container--user {
    color: $header;
    font-weight: bolder;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .attendees-management__responsive__hidden {
    display: none;
  }

  .attendees-management__tab
    .attendees-management__tab__container
    .ant-tabs-nav
    .ant-tabs-tab {
    color: $very-light-grey-4;
  }

  .attendees-management__tab
    .attendees-management__tab__container
    .ant-tabs-nav
    .ant-tabs-tab-active {
    color: $primary_color;
    font-weight: 500;
    outline: none;
  }

  .auto-present-btn {
    width: 116px;
    margin-left: 1px;
    margin-right: 1px;

    span {
      text-align: center;
      letter-spacing: 0;
      color: $tgtextcolor;
    }
  }

  .filter-select {
    width: 116px;
    border-radius: 3px;
  }

  .attendees-management__tab .attendees-management__tab__container {
    padding: 5px;

    @media (max-width: $mobile) {
      padding: 70px 15px 20px 15px;

      .ant-tabs-content-holder {
        width: 99%;
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .attendees-management__tab {
    box-shadow: 0 0 10px #0000001a;

    .attendees-management__tab__container {
      width: 100%;
      position: relative;
      background: $white;
      padding: 20px;

      .ant-tabs-nav .ant-tabs-tab-active,
      .ant-tabs-nav .ant-tabs-tab {
        font-size: $font16;
      }
    }
  }

  .attendees-management__header {
    color: $color_organizer;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .circle_boxad {
    background: $primary-color;
    width: 250px;
    border-radius: 50%;
    height: 250px;
    opacity: 0.2;
    position: absolute;
    left: -124px;
    top: -100px;
  }

  .ant-switch-checked {
    background-color: $color_organizer;
  }

  .view-invoice-link {
    color: $color_organizer;
    text-decoration: underline;
  }

  .circle_boxad {
    background: $primary-color;
    width: 250px;
    border-radius: 50%;
    height: 250px;
    opacity: 0.2;
    position: absolute;
    left: -124px;
    top: -100px;
  }

  .ant-switch-checked {
    background-color: $color_organizer;
  }

  .view-invoice-link {
    color: $color_organizer !important;
    text-decoration: underline !important;
  }

  .btn-container,
  .management-info,
  .filter-btn-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0 0 0 auto;
    color: 'gray';
    float: right;
  }

  .mark-btn-container,
  .auto-btn-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .management-table__container__col---reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .auto-mark-text {
    color: $color_organizer;
    border: none;
    margin-left: 4px;
    margin-right: 4px;

    &:hover {
      color: $color_organizer;
    }
  }

  .auto-btn-container {
    margin-left: 3px;
    margin-right: 3px;
  }

  .filter-select {
    width: 120px;
  }

  .attendees-management__tab
    .attendees-management__tab__container
    .ant-tabs-nav
    .ant-tabs-tab {
    color: $very-light-grey-4;
  }

  .attendees-management__tab
    .attendees-management__tab__container
    .ant-tabs-ink-bar {
    width: 0;
    height: 2px;
    background-color: $primary-color;
    transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .management-table__container--user {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .attendees-management__responsive__hidden {
    margin-left: 5px;
    font-size: $font18;
  }

  .management-table__container {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .auto-present-btn {
    width: 180px;
    height: 42px;
    margin-left: 1px;
    margin-right: 1px;

    span {
      text-align: center;
      letter-spacing: 0;
      color: $tgtextcolor;
    }
  }

  .filter-select {
    width: 180px;
    border-radius: 3px;
    input {
      border-radius: 10px;
    }
  }

  .circle_boxad {
    background: $primary-color;
    width: 250px;
    border-radius: 50%;
    height: 250px;
    opacity: 0.2;
    position: absolute;
    left: -124px;
    top: -100px;
  }
}

.attendees-management__bulk-invite {
  position: absolute;
  right: 20px;
  top: 20px;

  span {
    font-size: $font15;

    @media (max-width: $laptop-max) {
      font-size: $font12;
    }
  }
}

.pagination {
  @include display-flex(row);
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0;
}

.attendees-status__container {
  display: flex;
  flex-direction: row;
  &__cancel {
    color: red;
  }
}
