@import './theme.scss';
@import './variables.scss';
@import './mixins.scss';
@import './utils.scss';
@import './login-signup/modal.scss';
@import './components/events/events.scss';
@import './components/ManagementHeader/AttendeesManagement.scss';
@import './components/layout/main-layout.scss';
@import './components/layout/menu.scss';
@import './components/layout/res-menu.scss';
@import './components/layout/top-bar.scss';
@import './components/layout/event-home.scss';
@import './components/dashboard/dashboard.scss';
@import './components/profile/profileLinks.scss';
@import './components/ManagementHeader/Header.scss';
@import './components/recruitment/recruit.scss';
@import './components/recruitment/job-opportunities.scss';
@import './components/recruitment/job-lists.scss';
@import './components/recruitment/applied-candidates.scss';
@import './components/recruitment/view-resume.scss';
@import './components/recruitment/resume.scss';
@import './components/recruitment/resume-details.scss';
@import './components/recruitment//report-overview-modal.scss';
@import './components/profile/profile.scss';
@import './components/commons/modal-header.scss';
@import './components/events/explore-events.scss';
@import '../../src/containers/Candidate/styles/profile.scss';
@import './components/CrewManagement/crew-member-profile.scss';
@import '../styles/components/profile/candidate/quick-start.scss';
@import '../containers/Organizer/style/profileonboard.scss';
@import '../styles/components/events/join-event.scss';
@import '../styles/components/breadcrumbs/breadcrumbs.scss';
@import '../styles/components/quill-editor.scss';
@import '../styles/components/commons/kebab-menu.scss';

//Calendar
@import './components/calendar.scss';
@import './components/calendar-modal.scss';
@import './components/interview-slot.scss';
@import '../../src/containers/Organizer/style/edit-event.scss';
@import './components/recruitment/create-job.scss';
@import './components/recruitment/job-description.scss';
@import './components/recruitment/additional-details.scss';
@import './components/commons/organizer-profile-info-details.scss';
@import '../containers/Candidate/styles/profile.scss';
@import './components/recruitment/view-job-details.scss';
//Chats
@import './components/chats.scss';
@import './components/personalChats.scss';
@import './components/groupChats.scss';
//Notifications
@import './components/notification/notifications.scss';
@import './components/notification/header.scss';
@import './components/notification/notification-item.scss';
//Live Event Video Container css
@import './components/broadcast/live-event.scss';
//Candidate jobs
@import '../styles/components/profile/candidate/jobs/job-details.scss';
@import '../styles/components/profile/candidate/jobs/jobs.scss';
@import '../styles/components/profile/candidate/jobs/job-opportunities.scss';
@import '../styles/components/profile/candidate/jobs/job-results.scss';
@import './components/broadcast/broadcast-event.scss';
@import './components/landing-page.scss';
@import './components/events/share.scss';
@import './components/page-not-found.scss';

// Admin
@import './components/admin/organizer.scss';
@import './components/admin/recruiter.scss';
@import './components/admin/candidate.scss';
@import './components/admin/general-setting.scss';
@import './components/admin/skills.scss';
@import './components/admin/dashboard.scss';
@import './components/admin/events.scss';
@import './components/admin/job-details.scss';

@import './components/recruitment/reject-modal.scss';
@import '../styles//components/profile/candidate/jobs/job-analytics-modal.scss';
// Placeholder
@import './components/placeholder.scss';
@import './components/support/support.scss';

@import './components/bulk-booking.scss';

// Help-doc
@import './components/commons/help-doc.scss';

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }
}

//Removing the Up and down arrows in Number Box
.ant-form-item .ant-input-number-handler-wrap {
  display: none;
}

.ant-upload-text-icon {
  svg {
    margin-top: 3px;
  }
}

.ant-upload-list {
  a {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }

  &-item {
    border: 2px dashed $placeholder;
    width: 24rem;
    height: 4rem;
    border-radius: 5px;

    &-info {
      padding: 0.7rem 1rem;
      color: $label;

      span>div>span {
        padding-top: 3px;
      }
    }
  }
}

.modal-header {
  border-bottom: none;
}

.large-button {
  height: 32px;
  border-radius: 5px;

  @media (min-width: $desktop-min) {
    height: 40px;
  }

  span {
    @include medium-heading-mixin;
  }
}

.ant-modal-close-x {
  span {
    svg {
      width: 19px;
      height: 19px;
    }
  }
}

.skills-highlight-mandatory {
  background-color: $resume-button;
  padding: 1px 0;
  color: $white;
  opacity: 0.95;
  border-radius: 5px;
}

.skills-highlight-prefered {
  background-color: $gray-2;
  padding: 1px 0;
  color: $white;
  opacity: 0.95;
  border-radius: 5px;
}

.skills-highlight-other {
  background-color: $bluecolor;
  padding: 1px 0;
  color: $white;
  opacity: 0.85;
  border-radius: 5px;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
}

.placeholder-text {
  color: $gray;
  @include large-font-mixin;
}

.placeholder-past-event {
  margin-top: 20px;
}

.placeholder-recruit-jobs-application {
  margin-top: -15px;
  margin-bottom: 20px;

  @media (max-width: $mobile) {
    margin-top: 0;
  }
}

.topbar-notification-placeholder {
  padding: 10px 20px;
}

.candidate-jobs-placeholder {
  margin-top: 30px;
}

.app-version-update-message>div {
  background-color: $white  !important;
  color: $gray  !important;

  svg {
    fill: $bluecolor  !important;
  }
}

.mandatory_star__red {
  color: $red;
}

// Responsiveness

.show-mobile {
  display: none !important;
}

@media (max-width: $mobile) {
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

.ant-modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: 80vh;
      overflow-y: auto;
      overflow-x: hidden;

      @media (max-width: $mobile) {
        max-height: unset;
      }
    }
  }
}

.signupModal.ant-modal {
  .ant-modal-content {
    .ant-modal-body {
      max-height: unset;
    }
  }
}
