.applied-candidates__container {
  margin-top: 30px;
  margin-bottom: -10px;
  justify-content: space-evenly;

  @media (max-width: $tablet-max) {
    justify-content: flex-start;
  }

  &__heading--left {
    display: flex;
    align-items: center;
    align-content: center;
  }

  &__heading-text--left {
    @include medium-heading-mixin;

    color: $sheader !important; //used important because inherent title component was giving very dark black color not matching to design.
    font-weight: $regular;
  }

  &__filters--right {
    display: flex;
    flex-direction: row-reverse;
  }
}
// Because there are less no. of fields in table and the padding provided by default ant-table cause the component not look
// similar to designs that's why overridden default ant component padding
.applied-candidates__columns {
  font-size: $font12;
  padding-left: 50px !important;
}

.resume-download-btn {
  background-color: $recruiter-color;
  color: $white;
  margin-right: 2px;
  height: 32px;
  margin-bottom: 5px;
  border: 1px solid $recruiter-color;
  display: flex;
  align-items: center;

  span {
    font-size: $font12;
  }

  &:hover {
    color: $dark-yellow;
    border: 1px solid $recruiter-color;
  }
}

.form-group {
  &righttimer {
    margin: 0 0 0 auto;
    color: $gray;
  }
}

.applied-candidates__filter {
  &-btn-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $sheader;
    border-radius: 5px;
    font-weight: $regular;
    margin-top: 15px;
    font-size: $font10;
    margin-bottom: 20px;
  }

  &-select {
    display: flex;
    justify-content: space-around;
    margin-left: 5px;
    min-width: 120px;

    .ant-select-selection-item {
      font-size: $font14;
    }

    .ant-select-selection-placeholder {
      font-size: $font14;
    }
  }
}

.job-application__container {
  position: absolute;
  right: 10px;

  .recruit-evaluation-timer-text {
    @include display-flex(row);

    width: max-content;
    align-items: center;

    @media (max-width: $mobile) {
      margin: 5px 10px 10px 0;
    }

    .recruit-evaluation-timer {
      @include large-font-mixin;

      margin: 0px 10px;
    }
  }
}

.job-applications {
  @media (max-width: $mobile) {
    margin-top: 30px;
  }
}

@media (max-width: $mobile) {
  .applied-candidates__container {
    margin-top: 20px;
    justify-content: space-evenly;

    &__heading--left {
      display: flex;
      align-items: center;
      align-content: center;
    }

    &__heading-text--left {
      color: $sheader !important;
      font-weight: $semi-bold;
    }

    &__filters--right {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .resume-download-btn {
    width: min-content;
    background: $recruiter-color 0% 0% no-repeat padding-box;
    border-radius: 3px;
    padding-top: 8px;
    padding-left: 5px;
    padding-right: 5px;

    span {
      color: $white;
      font-size: $font12;
      align-self: center;
      text-align: center;
      justify-content: center;
    }
  }

  .applied-candidates__filter {
    &-btn-container {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 12px;
      width: 100%;
      color: $gray;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &-select {
      justify-content: space-around;
      margin: 4px;
      color: $sheader;
      border-radius: 5px;
      font-weight: $medium;

      .ant-select-selector {
        color: $sheader;
      }
    }
  }
  // Because there are less no. of fields in table and the padding provided by default ant-table cause the component not look
  // similar to designs that's why overridden default ant component padding
  .applied-candidates__columns {
    font-size: $font12;
    padding-left: 60px !important;
  }
  .applied-candidates__scrollable {
    overflow-x: scroll;
  }
}

@media (min-width: $desktop-min) {
  .applied-candidates__container {
    margin-top: 20px;
    justify-content: space-evenly;

    &__heading--left {
      display: flex;
      align-items: center;
      align-content: center;
    }

    &__heading-text--left {
      color: $sheader !important;
      font-weight: $semi-bold;
    }

    &__filters--right {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .resume-download-btn {
    background-color: $recruiter-color;
    color: $white;
    height: 32px;
    margin-right: 2px;
    margin-bottom: 0px;

    &:hover {
      color: $dark-yellow;
    }

    span {
      font-size: $font16;
    }
  }

  .form-group {
    &righttimer {
      margin: 0 0 0 auto;
      color: $gray;
    }
  }

  .applied-candidates__filter {
    &-btn-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: $sheader;
      border-radius: 5px;
      font-weight: $medium;
      font-size: $font16;
      margin-bottom: 15px;
    }

    &-select {
      display: flex;
      justify-content: space-around;
      margin-left: 10px;
      font-size: $font16;
    }
  }

  // Because there are less no. of fields in table and the padding provided by default ant-table cause the component not look
  // similar to designs that's why overridden default ant component padding
  .applied-candidates__columns {
    font-size: $font16;
    padding-left: 100px !important;
  }

  .job-application__container {
    position: absolute;
    right: 10px;

    .recruit-evaluation-timer-text {
      @include display-flex(row);

      width: max-content;

      .recruit-evaluation-timer {
        margin: 2px 10px;
      }
    }
  }
}

.reload-icon {
  margin-right: 20px;
  margin-bottom: 5px;
  svg {
    font-size: 20px;
    align-self: center;
  }
}

.reload-icon-organizer {
  margin-right: 10px;
}
svg {
  font-size: 20px;
}

.reload-icon-candidate {
  margin-right: 10px;
  margin-top: 5px;
}
svg {
  font-size: 20px;
}
