%pointer {
  cursor: pointer;
}

#candidate-breadcrumb {
  a {
    color: $candidate-color;
    @extend %pointer;
    font-weight: $semi-bold;
  }

  span {
    color: $candidate-color;
  }
}

#recruiter-breadcrumb {
  margin: 10px 0;
  color: $recruiter-color;
  a {
    @extend %pointer;
    font-weight: $semi-bold;
    color: $recruiter-color;
  }

  span {
    color: $recruiter-color;
  }
}

#organizer-breadcrumb {
  a {
    color: $primary-color;
    @extend %pointer;
    font-weight: $semi-bold;
  }

  span {
    color: $primary-color;
  }
}

ul.breadcrumbs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: $medium;

  li {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
      padding-left: 20px;

      &::before {
        position: absolute;
        content: '>';
        top: 0;
        left: 0;
        display: block;
      }
    }

    &:not(:last-child) {
      padding-right: 10px;
    }
  }
}
