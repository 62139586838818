.admin {
  &_skill-normalization {
    &--select {
      width: 300px;
    }
  }

  &-skill__demand-supply {
    width: 250px;

    &-top {
      width: 250px;
    }
  }

  &-skill__top-skills {
    &_container {
      display: flex;
      flex-direction: column;
    }

    &-job,
    &-candidate {
      margin-bottom: 10px;
    }
  }
}
