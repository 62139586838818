@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.jobs {
  &-title {
    margin-bottom: 20px;
    margin-top: 10px;

    p {
      @include special-case-font-mixin;

      color: $candidate-color;
      font-weight: $semi-bold;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

.jobs-outer-container {
  padding-top: 0;
}

.jobs-tab-container {
  padding: 20px;

  @media (max-width: $mobile) {
    padding: 20px 10px;
  }
}

.job-opportunities {
  &__choice {
    .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-nav .ant-tabs-tab {
      @include large-font-mixin;

      text-align: center;
    }
  }
}

.contribute-skill-modal {
  &.recruiter {
    .ant-btn-primary {
      background-color: $recruiter-color;
    }
  }
  &.candidate {
    .ant-btn-primary {
      background-color: $candidate-color;
    }
  }
}
