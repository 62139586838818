.document-container {
  user-select: none;
  margin-bottom: 30px;

  @media (max-width: $laptop-special-case-1) {
    @include display-flex(row);

    justify-content: center;
  }

  @media (min-width: $desktop-min) {
    @include display-flex(row);

    justify-content: center;
  }

  .react-pdf__Page {
    padding-top: 30px;
    canvas {
      @media (max-width: $mobile) {
        width: 100vw !important; //to overwrite fixed width of Document
        height: 100vh !important; //to overwrite fixed height of Document
      }
    }
  }
}

.help-doc__pagination {
  @include display-flex(row);

  justify-content: center;
}
