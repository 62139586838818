@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.OrganizerProfile,
.RecruiterProfile {
  position: relative;

  .header-container {
    .main-header {
      @include extra-large-heading-mixin;
    }
  }

  .edit-icon {
    display: none;
    padding: 7px 10px;
    border-radius: 5px;
    margin-bottom: 20px;

    @media only screen and (max-width: $mobile) {
      display: inline-block;
      position: absolute;
      right: 1%;
      top: 0.5%;
      z-index: 1;
    }

    & > svg {
      width: 30px;
      fill: white;
    }
  }

  .company-profile__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .sub-header {
      color: $sub-header;
      margin-top: -25px;
    }

    & > button {
      margin-right: 20px;
      margin-top: -20px;
      width: 180px;
      border: none;
      color: $white;

      @media only screen and (max-width: $mobile) {
        display: none;
      }
    }
  }

  .main__container {
    padding: 2px;

    .status__wrapper {
      @media only screen and (max-width: $mobile) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .status {
        @include medium-large-heading-mixin;

        font-weight: $semi-bold;
        margin-top: -30px;

        @media only screen and (max-width: $mobile) {
          margin-top: 10px;
        }
      }
    }

    .representative-team-profile__item-container {
      &.highlighted {
        background-color: $main-background;
        padding: 20px 20px;
        border-radius: 5px;
        margin-bottom: 30px;
      }

      .shadow-picture {
        width: 180px;
        height: 180px;

        @media only screen and (max-width: $mobile) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .company-information {
      .shadow-picture {
        width: 180px;
        height: 180px;
      }
    }

    .crew-management {
      padding: 20px 20px;

      &__title {
        @include extra-large-font-mixin;

        font-weight: $bold;
        margin-bottom: 30px;

        &.organizer {
          color: $custom-green;
        }
      }

      &__member-data {
        &__types {
          display: flex;

          h3 {
            @include large-font-mixin;

            width: 280px;
            margin-bottom: 20px;
            color: $gray;
          }
        }

        &__details {
          display: flex;

          & > h3 {
            @include large-font-mixin;

            width: 280px;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

.OrganizerProfile {
  .edit-icon {
    background-color: $custom-green;
  }

  .company-profile__wrapper {
    & > button {
      background-color: $custom-green;
    }
  }
}

.RecruiterProfile {
  .edit-icon {
    background-color: $custom-orange;
  }

  .company-profile__wrapper {
    & > button {
      background-color: $custom-orange;
    }
  }
}

.invite-member-modal__members-container {
  .ant-input[disabled] {
    color: gray;
    background-color: white;
    cursor: not-allowed;
    opacity: 1;
  }
}
