.approve-modal {
  &__confirm-button--right {
    @include display-flex(row);
    justify-content: flex-end;
    margin-top: 40px;
  }
}

.informatio__header-container {
  display: flex;

  .information__menu-container {
    @include display-flex(row);

    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }
}

.information__manage-menu-management-event-btn {
  color: $custom-red;
}

.recruiter-details__table__container {
  overflow: auto;
}

.ant-table-column-sorter-full {
  margin-left: 20px;
}
