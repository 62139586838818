@import '../../mixins.scss';

.center {
  text-align: center;
}
//TODO: nor_header needs to change to some standard name as per bem,It is used in multiple files(8-9)
.nor_header {
  padding-bottom: 10px;
  color: $header;
  font-weight: 600;
}

.CandidateProfile .nor_header {
  color: $candidate-color;
}

.ant-select {
  width: 100%;
}

.steps-content.pro_steps_content .profile__information__header {
  font-weight: 400;
  color: $black;
  margin-bottom: 16px;
  display: block;
}

.country-code {
  .ant-select-arrow {
    margin-top: -9.5px;

    svg {
      width: 16px;
    }
  }
}

.highest-education-dropdown {
  .ant-select-arrow {
    svg {
      margin-top: -1px;
      width: 16px;
    }
  }
}

.education-details {
  margin-bottom: 25px;

  @media (max-width: $mobile) {
    margin-bottom: 20px;
  }

  .skill-remove-column {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  .remove-education-details-mobile {
    display: none;
    color: $custom-red;
    font-weight: $medium;

    @media (max-width: $mobile) {
      display: block;
    }
  }
}

.address {
  .ant-select-arrow {
    margin-top: -9.5px;

    svg {
      width: 16px;
    }
  }
}

.RecruiterProfile,
.RecruiterCrewMemberProfile,
.OrganizerProfile,
.CandidateProfile,
.OrganizerCrewMemberProfile {
  padding: 30px 0 0;
  background-color: $main-background;
  position: relative;
  z-index: 100;
  @media (max-width: $mobile) {
    padding-top: 0px;
  }
}

.OrganizerProfile,
.organizer_board {
  .circle_box {
    background-color: $primary-color;
  }
}

.RecruiterProfile,
.recruiter_board {
  .circle_box {
    background-color: $recruiter-color;
  }
}

.CandidateProfile,
.candidate_board {
  .circle_box {
    background-color: $candidate-color;
  }
}

.RecruiterCrewManagementModal .submit-button {
  background-color: $recruiter-color;
}

.adminCrewManagementModal .submit-button {
  background-color: $bluecolor;
}

.OrganizerCompanyInformationModal .nor_header,
.OrganizerRepresentativeModal .nor_header,
.OrganizerEditCrewProfileModal .nor_header,
.OrganizerCrewManagementModal .nor_header {
  color: $primary-color;
}

.OrganizerCompanyInformationModal .profile__information__header,
.OrganizerRepresentativeModal .profile__information__header,
.OrganizerEditCrewProfileModal .profile__information__header,
.OrganizerCrewManagementModal .profile__information__header,
.RecruiterCompanyInformationModal .profile__information__header,
.RecruiterCompanyInformationModal .profile__information__header,
.PersonDetailModal .profile__information__header,
.RecruiterRepresentativeModal .profile__information__header {
  color: $black;
}

.ant-select-arrow {
  margin-top: -9.5px;

  svg {
    width: 16px;
  }
}

.container {
  margin-bottom: 30px;
  padding: 42px 30px;
  box-shadow: 0 2px 10px $black;
  background-color: $white;
  position: relative;
}

.header-container {
  margin-bottom: 25px;
  position: relative;
  z-index: 11;
}

.company-information-modal-item__header {
  font-weight: medium;
}

.company-profile {
  .upload-image-hint-text {
    @media (max-width: $mobile) {
      font-size: 10px;
      display: block;
      word-wrap: break-word;
      width: 150px;
      white-space: normal;
      margin-top: -15px;
    }
  }
}

//TODO: If Possible change name to standard Bem,It is used in multiple files
.main-header {
  color: $header;
  font-weight: $bold;
  margin-bottom: 0;
  position: relative;
  top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 55%;
}

//TODO: If Possible change name to standard Bem,It is used in multiple files
.sub-header {
  color: $sub-header;
  font-weight: $light;
  padding-top: 15px;
}

.shadow-picture {
  position: relative;
  background: $very-light-grey-4;

  svg {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 70%;
    height: 70%;
    fill: gray;
    padding: 1.5px;
  }
}

.edit-profile-button {
  width: 119px;
  height: 31px;
  border-radius: 5px;

  div {
    @include medium-heading-mixin;

    color: $white;
    font-weight: $regular;
    text-align: center;
  }
}

.edit-profile-button:hover,
.edit-profile-button.ant-btn:focus {
  color: $white;
  border-color: $white;
}

.OrganizerProfile .edit-profile-button {
  background-color: $primary-color;
}

.CandidateProfile .edit-profile-button {
  background-color: $candidate-color;
}

.RecruiterProfile .edit-profile-button {
  background-color: $recruiter-color;
}

.crew-management__header--top,
.crew-management__sub-header {
  font-weight: $semi-bold;
  margin-bottom: 24px;
}

.OrganizerProfile .crew-management__header--top,
.OrganizerProfile .crew-management__sub-header {
  color: $primary-color;
}

.representative-team-profile__item-container {
  padding: 20px;
  background-color: $main-background;
  margin-bottom: 40px;
}

.RecruiterProfile .main__container .container .crew-management {
  &__title {
    color: $recruiter-color;
    font-size: 18px;
    font-weight: $bold;
  }

  &__member-data {
    overflow: scroll;
  }

  &__member-data__types {
    display: flex;

    & > h3 {
      color: $gray;
    }
  }

  &__member-data__details {
    display: flex;
  }
}

.RecruiterProfile .crew-management__header--top,
.RecruiterProfile .crew-management__sub-header {
  color: $recruiter-color;
}

.black-header {
  color: $black-text;
  font-weight: $semi-bold;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 24px;
}
//TODO: If Possible change name to standard Bem,It is used in multiple files(8-9)
.profile__information__header {
  color: $sub-header;
  font-weight: $medium;
  margin-bottom: 12px;
}
//TODO: If Possible change name to standard Bem,It is used in multiple files(8-9)
.profile__information__value {
  color: $black-text;
  font-weight: $regular;
  word-wrap: break-word;
  margin-bottom: 25px;
}

.add-profile-link {
  width: 100%;
  max-width: 300px;
  border: 1px dashed $placeholder;
  border-radius: 3px;
  color: $placeholder;
  height: 42px;
}

.add-crew-member {
  width: 100%;
  max-width: 250px;
  border: 1px dashed $placeholder;
  border-radius: 3px;
  color: $placeholder;
  height: 42px;

  @media (max-width: $mobile) {
    max-width: none;
  }
}

.shadow-picture {
  margin-top: -18px;
  width: 150px;
  height: 150px;
}

.representative-center {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.text-upload {
  border: none;
  background-color: $white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.item-container {
  padding: 10px;
  margin-bottom: 10px;
  background-color: $main-background;
  position: relative;
}

.edit-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  font-size: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 10px #00000029;
  color: $custom-green;
}

.representative-info-text {
  color: $sheader2;
  font-weight: $semi-bold;
  margin-bottom: 30px;

  &.organizer {
    color: $custom-green;
  }

  &.recruiter {
    color: $custom-orange;
  }
}

.add-profile-picture-button {
  border: none;
  background-color: $main-background;
  margin-left: -50px;
}

.edit-button-organizer,
.edit-button-recruiter {
  width: 16px;
  height: 16px;
}

.crew-header {
  margin-bottom: 20px;
}

.black-text {
  color: $black-text;
  margin-bottom: 20px;
}

.OrganizerCompanyInformationModal .ant-modal-header,
.OrganizerCrewManagementModal .ant-modal-header,
.RecruiterCrewMemberProfile .ant-modal-header,
.RecruiterCompanyInformationModal .ant-modal-header,
.PersonDetailModal .ant-modal-header,
.OrganizerRepresentativeModal .ant-modal-header,
.RecruiterRepresentativeModal .ant-modal-header {
  display: none;
}

.invited-text {
  color: $custom-orange;
  font-weight: $semi-bold;
}

.active-text {
  color: $custom-green;
  font-weight: $semi-bold;
}

.edit-crew-profile-modal__status {
  color: $header;
  font-weight: $semi-bold;
  font-size: 18px;
  margin-bottom: 24px;
}

.crew-management__pending-invitations {
  padding-top: 15px;

  .cursor {
    &:hover {
      cursor: pointer;
    }
  }
}

.removeLogo {
  font-size: 1.3rem;
  color: $custom-red;

  &:hover {
    cursor: pointer;
  }
}

.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  text-align: center;
}

.ant-row {
  .ant-avatar {
    margin-left: 100px;
  }
}

.button-group {
  margin-top: 20px;

  .crew-modal-footer {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
}

.ant-input-number {
  width: 100%;
}

.ant-input-number-input {
  width: 100%;
  color: $black;
  font-size: $font14;
}

.cancel-button {
  border: none;
  color: $black-text;
  width: 100%;
  text-align: center;
}

.submit-button {
  color: $white;
  background-color: $custom-green;
  width: 100%;
  text-align: center;
}

.crew-header {
  margin-bottom: 50px;
}

.black-text {
  color: $black-text;
  margin-bottom: 12px;
}

.invited-text {
  color: $custom-orange;
  font-weight: $semi-bold;
}

.active-text {
  color: $custom-green;
  font-weight: $semi-bold;
  padding-top: 4px;
  &:hover {
    cursor: pointer;
  }
}

.remove-text {
  color: $custom-red;
  font-weight: $semi-bold;
  padding-top: 4px;
}

.edit-crew-profile-modal__status {
  color: $header;
  font-weight: $semi-bold;
  font-size: 18px;
  margin-bottom: 24px;
}

.text-upload {
  border: none;
  background-color: $white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-profile-link {
  width: 100%;
  width: 100%;
  height: auto;
  max-width: 300px;
  border: 1px dashed $placeholder;
  border-radius: 3px;
  color: $placeholder;
  padding: 8px 0;
  max-width: 415px;
}

.delete-invited {
  margin-left: 5px;
  color: $custom-red;
}

.addlink {
  margin-top: 40px;

  @media (max-width: $mobile) {
    margin-top: -20px;
  }

  .add-profile-link {
    height: 42px;
    max-width: 412px;
    margin-bottom: 20px;

    input {
      margin-bottom: 20px;
    }
  }

  .addbutn {
    button {
      border: none;
      background-color: $white;
      line-height: 44px;

      svg {
        fill: $gray-4;
        width: 20px;
        height: 20px;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.removeLogo {
  font-size: 1.3rem;
  color: $custom-red;

  &:hover {
    cursor: pointer;
  }
}

.submit-button {
  width: 100%;
  height: 35px;
  max-width: 147px;
  padding: 20px 0;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  text-align: right;
  margin: 0 0 0 auto;

  &:hover {
    background-color: $primary-color;
    color: $white;
  }

  span {
    font-size: $font14;
  }
}

.crew_btn {
  max-width: 200px;
  width: 100% !important;
}

.cancel-button {
  text-align: left !important;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 500;
  color: $tgtextcolor !important;
}

.active-text .delete-invited {
  position: relative;
  top: -8px;
  right: -7px;
}

.mt60 {
  margin-top: 60px !important;
}

.mt45 {
  margin-top: 45px;
}

.mt40 {
  margin-top: 40px;
}

.mb0 {
  margin-bottom: 0 !important;
}

#OrganizerCrewManagementModal,
#RecruiterCrewManagementModal {
  padding-left: 20px;
}

.job-profile-ui-box__column--center {
  display: flex;
  align-items: center;
}

.mt150 {
  margin-top: 150px !important;
}

.OrganizerCompanyInformationModal,
.OrganizerRepresentativeModal,
.OrganizerCrewManagementModal,
.RecruiterRepresentativeModal,
.RecruiterCompanyInformationModal {
  .ant-input,
  .ant-select-selector {
    color: $black-text;
  }
}

.OrganizerCompanyInformationModal,
.OrganizerRepresentativeModal,
.RecruiterRepresentativeModal,
.RecruiterCompanyInformationModal {
  .ant-form-item-control-input {
    margin-bottom: 10px;
  }
}

.RecruiterCompanyInformationModal {
  .ant-input {
    margin-bottom: 0;
  }

  @media (max-width: $mobile) {
    .company-address {
      span {
        font-size: $font14;
      }
    }

    .company-profile {
      .industry-type {
        span {
          font-size: $font14;
        }
      }
    }
  }
}
.ant-form {
  color: $sheader2;
}

.OrganizerCompanyInformationModal,
.OrganizerRepresentativeModal,
.RecruiterRepresentativeModal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .OrganizerCompanyInformationModal .ant-select-selector,
  .ant-select-selector {
    align-items: center;
  }
}

.RecruiterCompanyInformationModal
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.RecruiterCompanyInformationModal .ant-select-selector,
.ant-select-selector {
  align-items: center;
}

.profile__edit {
  display: block;
}

.profile__edit__icon {
  display: none;
}

.profile__button_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.crew-management__container__header--mobile {
  display: none;
}

.crew-management__button {
  display: flex;
  align-items: center;
}

.status {
  justify-content: space-between;
}

.OrganizerProfile .company-information__header,
.OrganizerProfile .nor_header,
.OrganizerCompanyInformationModal .nor_header,
.OrganizerRepresentativeModal .nor_header,
.OrganizerCompanyInformationModal .company-information-modal-item__header {
  color: $primary-color;
}

.RecruiterProfile .company-information__header,
.RecruiterProfile .nor_header,
.RecruiterCompanyInformationModal .nor_header,
.RecruiterCompanyInformationModal .company-information-modal-item__header {
  color: $recruiter-color;
}

.CandidateProfile .company-information__header,
.CandidateProfile .nor_header,
.PersonDetailModal .nor_header {
  color: $candidate-color;
  margin-top: 25px;
}

.RecruiterCompanyInformationModal .submit-button {
  background-color: $recruiter-color;
}

.OrganizerCompanyInformationModal .company-information-modal-item__header,
.RecruiterCompanyInformationModal .company-information-modal-item__header {
  margin-bottom: 20px;
}

.ant-row .ant-avatar {
  margin-left: 0 !important;
}

.CandidateProfile .shadow-picture {
  width: 154px;
  height: 154px;
}

.representative-modal__header {
  font-weight: $medium;
}

.representative-modal__header-container {
  @include display-flex(row);

  justify-content: space-between;

  @media (max-width: $mobile) {
    align-items: baseline;
  }
}

.representative-modal__change-password-btn {
  background-color: $very-light-grey-2;
  padding: 8px 30px;
  border-radius: 2px;
  margin-bottom: 30px;
  margin-right: 20px;
}

.representative-modal__basic__information {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: $semi-bold;
}

.representative-modal__information__header {
  margin-bottom: 10px;
}

.OrganizerRepresentativeModal .representative-modal__header,
.OrganizerRepresentativeModal .representative-modal__basic__information {
  color: $primary-color;
}

.RecruiterRepresentativeModal .representative-modal__header,
.RecruiterRepresentativeModal .representative-modal__basic__information {
  color: $recruiter-color;
}

.RecruiterRepresentativeModal .submit-button {
  background-color: $recruiter-color;
}

.OrganizerRepresentativeModal .submit-button {
  background-color: $primary-color;
}

.crew-management__container {
  margin-bottom: 5px;
  .crew-management__button {
    .view-profile {
      cursor: pointer;
      color: $placeholder;
    }
  }
}

@media (max-width: $mobile) {
  .crew-management__header--hidden {
    display: none;
  }

  .add-members {
    margin: 0 auto;
    width: 200px;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .crew-management__container {
    padding-left: 10px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
      margin-top: 20px;
      padding-left: 15px;
    }

    &__header--mobile {
      display: flex;
    }
  }

  .parent_sec-create .main-container {
    padding-bottom: 0;
  }

  .candidate_board .bot_box_mob {
    background-color: $candidate-color;
  }

  .candidate_board {
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      width: 100%;

      &::after {
        width: 1px;
        width: 30%;
        height: 100%;
        height: 1px;
        position: absolute;
        left: 60%;
        background: gray;
        content: '';
      }
    }
  }

  .recruiter_board {
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      width: 100%;

      &::after {
        width: 1px;
        width: 30%;
        height: 100%;
        height: 1px;
        position: absolute;
        left: 80%;
        background: gray;
        content: '';
      }
    }
  }

  .organizer_board {
    .ant-steps-horizontal.ant-steps-label-horizontal
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      width: 100%;

      &::after {
        width: 1px;
        width: 30%;
        height: 100%;
        height: 1px;
        position: absolute;
        left: 80%;
        top: 40px;
        background: gray;
        content: '';
      }
    }
  }

  .pro_steps_content {
    div.add_propic {
      & > div.pro_div > span.shadow-picture {
        width: 60px;
        height: 59px;
      }
    }
  }

  .profile_step {
    .title.pro_title {
      margin-top: 0;
    }
  }

  .profile_step .ant-steps-item {
    width: auto;
  }

  .crew-management__button {
    text-align: right;

    .crew-management__button__edit {
      color: $custom-red;
      font-size: 16px;
      font-weight: $semi-bold;
    }

    .crew-management__button__view-profile {
      color: $custom-orange;
      font-weight: $semi-bold;
    }
  }
}

.information__header--hidden {
  display: none;
}

@media (max-width: $mobile) {
  .profile__edit {
    display: none;
  }

  .parent_sec {
    margin-bottom: 0;
  }

  .profile__edit__icon {
    display: block;
    align-content: center;
    justify-content: center;

    svg {
      width: 21px;
      fill: $white;
      height: 21px;
    }
  }

  .profile__button_container .edit-profile-button {
    width: 41px;
    height: 41px;
    align-content: center;
    justify-content: center;
    padding: 0;
  }

  .main-header {
    text-align: left;
    font-weight: bold;
    letter-spacing: 0;
    color: $header;
  }

  .sub-header {
    font-size: 14px !important;
    margin-bottom: 0 !important;
  }

  .organizer-profile__circle--hidden {
    display: none;
  }

  .OrganizerCrewMemberProfile {
    padding: 0;
  }

  .upload-component__company-logo {
    width: 113px !important;
    height: 111px !important;
    margin-top: -30px;
    margin-left: 0 !important;
  }

  .company-information__header {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: $semi-bold;
  }

  .container {
    padding: 10px;
    margin-bottom: 0;
  }

  .profile__information__container {
    padding: 10px;
    // margin-bottom: -90px;
  }

  .removeLogo {
    @media (max-width: $mobile) {
      @include small-font-mixin;
    }
  }

  .profile__information__header {
    font-size: 13px;
    font-weight: $medium;
    margin-bottom: 5px;
  }

  .industry-wrapper {
    @media (max-width: $mobile) {
      margin-top: 20px;
    }
  }

  .steps-content.pro_steps_content {
    .parent_sec .main-container .content_bx & {
      height: auto;
      overflow: unset;
    }

    .profile__information__header {
      font-weight: $medium;
      margin-bottom: 5px;
      color: $sub-header;
    }
  }

  .profile_step .pro_steps_content .c_innerbox {
    padding-left: 0;
  }

  .profile__information__value {
    font-size: 16px;
  }

  .representative-info-text {
    font-size: 16px;
  }

  .edit-button {
    top: 0;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-component__user-image {
    margin-top: 10px;
    margin-left: 0 !important;
  }

  .add-profile-picture-button span {
    font-size: 10px !important;
  }

  .crew-management__header--top,
  .crew-management__sub-header {
    font-weight: $semi-bold;
    font-size: 16px !important;
    margin-bottom: 10px;
  }
  //TODO: nor_header needs to change to some standard name as per bem,It is used in multiple files(8-9)
  .nor_header {
    letter-spacing: 0;
    font-weight: $semi-bold;
  }

  .CandidateProfile .basic-information__container {
    padding: 10px;
  }

  .item-container {
    background-color: $very-light-grey-2;
    display: flex;
    padding: 20px;
    align-items: center;

    .ant-row {
      margin: 0 !important;

      .ant-col {
        padding-left: 0 !important;
      }
    }
  }

  .work-experience-text {
    font-size: 16px;
  }

  .work-experience-value {
    font-size: 14px;
  }

  .field-text {
    font-size: 16px;
  }

  .education-text {
    font-size: 14px;
  }

  .skill-black {
    font-weight: $regular;
    font-size: 12px;
  }

  .purple-header {
    font-size: 12px;
    margin-bottom: 24px;
  }

  .CandidateProfile .circle {
    display: none;
  }

  .OrganizerProfile,
  .RecruiterProfile {
    margin-top: 10px;
    padding-top: 0;
  }

  .CandidateProfile .upload-component__user-image {
    width: 114px;
    height: 113px;
    margin-top: -20px;
  }

  .representative-team-profile__item-container {
    position: relative;
    padding: 10px;
    align-items: center;
  }

  .profile__footer {
    width: 110%;
    height: 70px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .OrganizerProfile .profile__footer {
    background-color: $primary-color;
  }

  .RecruiterProfile .profile__footer {
    background-color: $recruiter-color;
  }

  .profile_header--margin {
    margin-top: 10px;
  }

  .ant-modal {
    width: 100% !important;
    height: 100vh !important;
    top: -10px;
    margin: 8px auto;
    max-width: calc(100vw);
  }

  .ant-modal-centered .ant-modal {
    top: -10px;
    padding: 0 !important;
  }

  .main-header {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 0;
  }

  .cancel-button {
    padding-left: 16px;
    font-size: 13px;
    font-weight: 500;
  }

  .submit-button {
    width: 153px !important;
    height: 37px;
    padding: 20px 0;
    font-size: $font16;
  }

  .person-detail-modal__heading--hidden {
    display: none;
  }

  .information__header--hidden {
    display: block;
  }

  .educational-information-row__responsive--hidden {
    display: none;
  }

  .skill-value-column {
    margin-left: -55px;
  }
  .PersonDetailModal {
    .skill-value-column {
      margin-top: -30px;
      margin-bottom: 20px;
    }
  }

  .PersonDetailModal .ant-modal-body,
  .WorkExperienceModal .ant-modal-body,
  .EducationDetailModal .ant-modal-body {
    padding-left: 20px;
  }

  .PersonDetailModal .EducationInfo,
  .PersonDetailModal .AdditionalInfo,
  .WorkExperienceModal .EducationInfo,
  .WorkExperienceModal .AdditionalInfo,
  .EducationDetailModal .EducationInfo,
  .EducationDetailModal .AdditionalInfo {
    margin-top: 0;
  }

  .ant-steps {
    height: 56px;
    flex-direction: row;
  }

  .ant-steps-item {
    width: 20%;
  }

  .RecruiterCompanyInformationModal .upload-component__responsive--right {
    position: absolute;
    right: -30px;
    padding-right: 30px !important;
    margin-top: -10px;
  }

  .OrganizerCompanyInformationModal .upload-component__responsive--right {
    position: absolute;
    right: -150px;

    @media (max-width: $mobile) {
      right: 20px;
      margin-top: -10px;
    }
  }

  .RecruiterCompanyInformationModal
    .upload-component__responsive--right
    .upload-component__company-logo,
  .OrganizerCompanyInformationModal
    .upload-component__responsive--right
    .upload-component__company-logo {
    width: 60px !important;
    height: 59px !important;
  }

  .company-information-modal-item__sub-heading {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .OrganizerCompanyInformationModal .ant-modal-header,
  .OrganizerCrewManagementModal .ant-modal-header,
  .RecruiterCrewMemberProfile .ant-modal-header,
  .RecruiterCompanyInformationModal .ant-modal-header,
  .PersonDetailModal .ant-modal-header,
  .OrganizerRepresentativeModal .ant-modal-header,
  .RecruiterRepresentativeModal .ant-modal-header {
    display: block;
  }

  .ant-upload .text-upload > span,
  .ant-upload .text-upload > span {
    font-size: 10px;
  }

  .representative-modal__header {
    font-size: 14px;
    font-weight: medium;
  }

  .representative-modal__basic__information {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: $semi-bold;
  }

  .representative-modal__information__header {
    color: $sub-header;
  }

  .upload_cer .upload-certification-hint {
    display: none;
  }

  .company-information-modal-item__header {
    font-size: 14px;
    font-weight: $semi-bold;
  }

  .OrganizerCompanyInformationModal .company-information-modal-item__header,
  .RecruiterCompanyInformationModal .company-information-modal-item__header {
    margin-bottom: 0;
  }

  .skill-name-column {
    margin-bottom: 15px;
    margin-bottom: 10px;
  }

  .PersonDetailModal .custom_skilline,
  .WorkExperienceModal .custom_skilline,
  .EducationDetailModal .custom_skilline {
    margin-bottom: 50px;
  }
}

@media (min-width: $desktop-min) {
  .shadow-picture {
    width: 180px;
    height: 180px;
  }

  .ant-input {
    font-size: $font16;
  }

  .edit-profile-button {
    width: 168px;
    height: 43px;

    div {
      text-align: center;
      color: $white;
      font-weight: $regular;
    }
  }

  .CandidateProfile .shadow-picture {
    width: 216px;
    height: 214px;
  }

  .submit-button {
    max-width: 172px;
    width: 100%;

    span {
      font-size: $font16;
    }
  }

  .edit-button {
    width: 51px;
    height: 51px;
  }

  .edit-button-organizer,
  .edit-button-recruiter {
    width: 25px;
    height: 25px;
  }
}

.billing-address__remove {
  color: $custom-red;
  margin-bottom: 24px;

  svg {
    font-size: 23px;
  }
}

.billing-address__add {
  color: $custom-green;

  svg {
    font-size: 23px;
  }
}

.onboarding-profile-container-height {
  height: 62vh;

  @media (max-width: $mobile) {
    height: auto;
  }
}
.candidate_board {
  .pro_steps_content .skill-set .ant-steps-item-finish .ant-steps-item-icon {
    border: 3px solid $candidate-color;
  }
  .pro_steps_content
    .skill-set
    .ant-steps-item-finish
    .ant-steps-item-tail::after {
    background-color: $candidate-color;
  }
  .pro_steps_content .skill-set .ant-steps-item-finish .ant-steps-item-title {
    color: $candidate-color !important;
  }
  .pro_steps_content .skill-set .ant-steps-item-finish svg {
    fill: $candidate-color;
  }
}

.recruiter-candidate-profile {
  .circle_box {
    background-color: $recruiter-color;
  }

  .nor_header {
    color: $recruiter-color;
  }
}

.resume-download-link {
  margin-left: 5px;

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid $tgtextcolor;
  }
}

.job-related-details__cerificates {
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    margin-bottom: 0;
  }
}

.representative-ui-box {
  height: 230px;
}

.job-related-details__resume__button {
  margin-top: 10px;
}

.profile-links__align {
  margin-top: 20px;
}

.candidate-profile-completion {
  margin: 0px 20px;
  align-items: center;
  p {
    margin: 0px 8px;
    color: $candidate-color;
    font-weight: $semi-bold;
  }
}

.company-cover__right.upload-image-hint-text {
  margin-left: 20px;
  margin-top: 20px;
}

.hidden-star {
  visibility: hidden;
}
