%gap {
  gap: 15px;
}

%label-block {
  @include large-font-mixin;

  color: $gray;
  margin: 15px 0;
}

%text-color-candidate {
  color: $candidate-color;
}

%recruiter-color {
  color: $recruiter-color;
}

.CandidateProfile,
.RecruiterProfile {
  .organizer-profile-info {
    .header-container {
      p {
        @extend %text-color-candidate;

        @include extra-large-font-mixin;
      }
    }

    .title {
      @include large-heading-mixin;

      @media (max-width: $mobile) {
        margin-top: 30px;
        margin-left: 10px;
      }
    }

    .basic-information {
      @extend %gap;

      display: grid;
      grid-template-columns: 1fr 1.3fr 1fr 1fr;

      padding: 30px 0;

      @media (max-width: $mobile) {
        @include display-flex(column);

        padding: 30px 20px;
      }

      .label {
        @extend %label-block;

        @include small-heading-mixin;
      }

      p {
        @include small-heading-mixin;

        color: $label;
        word-break: break-all;
      }

      &__logo {
        position: absolute;
        top: 15%;
        right: 20%;

        @media screen and (max-width: $mobile) {
          position: absolute;
          top: 0%;
          right: -10%;
          width: 200px;
        }

        @media (max-width: $mobile-special-case-2) {
          right: -14%;
        }

        img {
          width: 150px;
          position: absolute;

          @media (max-width: $mobile) {
            width: 120px;
            top: 80px;
            left: 0px;
          }

          @media (max-width: $mobile-special-case-2) {
            left: 15px;
          }
        }
      }

      &__description {
        grid-column: span 3;

        .label {
          @include small-heading-mixin;

          padding-right: 100px;
        }
      }
    }

    .sub-title {
      @include small-heading-mixin;
      color: $sub-header;

      @media (max-width: $mobile) {
        margin-top: 20px;
        margin-left: 20px;
      }
    }

    .company-address {
      @extend %gap;

      @include display-grid(5, 1fr);

      padding-right: 250px;
      margin-top: 20px;

      @media (max-width: $mobile) {
        @include display-flex(column);

        padding: 0 20px;
      }

      .label {
        @extend %label-block;

        @include small-heading-mixin;
      }

      p {
        @include small-heading-mixin;

        color: $label;
      }
    }

    .company-profile-link {
      @media (max-width: $mobile) {
        margin-left: 20px;
      }

      p {
        @include small-heading-mixin;

        font-weight: $semi-bold;
        color: $sub-header;
        margin-bottom: 10px;
      }
    }
  }
}

.CandidateProfile {
  .organizer-profile-info {
    .header-container {
      p {
        @extend %text-color-candidate;
      }
    }

    h1 {
      @extend %text-color-candidate;
    }
  }
}

.RecruiterProfile {
  .organizer-profile-info {
    .header-container {
      p {
        @extend %recruiter-color;
      }
    }

    h1 {
      @extend %recruiter-color;
    }
  }
}
