.job-lists__container {
  margin-top: 20px;
  margin-bottom: -20px;

  &__heading--left {
    display: flex;
    align-items: center;
    align-content: center;
  }
  //used important because inherent title component was giving very dark black color not matching to design.
  &__heading-text--left {
    font-weight: $semi-bold;
    color: $sheader !important;
  }

  &__filters--right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.job-lists__container {
  &__filter-btn-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 150px;
    color: $gray;
  }

  &__filter-select {
    width: 200px;
    margin-right: 10px;
    padding: 10px;
  }

  .ant-select-selector {
    min-width: 140px;
  }
}

.job-lists-table__scrollable {
  .ant-table-cell {
    font-size: $font12;
  }
  .ant-switch-checked {
    background-color: $recruiter-color;
  }
}
.recruiter-analytics-modal__heading {
  display: flex;
  flex-direction: flex-start;
  font-size: $font14;
  color: $header;
  font-weight: $medium;

  &__icon--right {
    font-size: 30px;
    font-weight: bold;
    align-content: center;
    align-items: center;
    height: 31px;
    cursor: pointer;

    &--gray {
      width: 4px;
      height: 4px;
      background-color: $sheader;
      justify-content: space-around;
      margin-bottom: 2px;
      border-radius: 5px;
    }

    span {
      width: 16px;
      height: 31px;

      svg {
        width: 10px;
        height: 10px;
        fill: $sheader;
      }
    }
  }
}

.job-title__val {
  margin-top: 12px;
  margin-left: 2px;
}

.job-analytics-header-select-text {
  color: $header;
  margin-top: 12px;
}

@media (max-width: $mobile) {
  .filter-btn-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .job-lists-table__scrollable {
    overflow-x: auto;

    .ant-table-cell {
      padding-left: 30px;
      padding-right: 30px;
    }

    .ant-switch-checked {
      background-color: $recruiter-color;
    }
  }
}

@media (min-width: $desktop-min) {
  .job-lists__container {
    margin-top: 20px;

    &__heading--left {
      display: flex;
      align-items: center;
      align-content: center;
    }
    //used important because inherent title component was giving very dark black color not matching to design.
    &__heading-text--left {
      font-weight: $semi-bold;
      color: $sheader !important;
    }

    &__filters--right {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .form-group {
    &righttimer {
      margin: 0 0 0 auto;
      color: $gray;
    }
  }

  .job-lists__container {
    &__filter-btn-container {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 150px;
      color: $gray;
    }

    &__filter-select {
      width: 200px;
      margin-right: 10px;
      padding: 10px;
    }

    .ant-select-selector {
      min-width: 150px;
    }
  }

  .job-lists-table__scrollable {
    .ant-table-cell {
      font-size: $font16;
    }

    .ant-switch-checked {
      background-color: $recruiter-color;
    }
  }

  .recruiter-analytics-modal__heading {
    display: flex;
    flex-direction: flex-start;
    font-size: $font14;
    color: $header;
    font-weight: $medium;

    &__icon--right {
      font-size: 30px;
      font-weight: bold;
      align-content: center;
      align-items: center;
      height: 34px;
      cursor: pointer;

      &--gray {
        width: 5px;
        height: 5px;
        background-color: $sheader;
        justify-content: space-around;
        margin-bottom: 2px;
        border-radius: 5px;
      }

      span {
        width: 16px;
        height: 34px;

        svg {
          width: 20px;
          height: 30px;
          fill: $sheader;
        }
      }
    }
  }
}
