.explore-events__search-input input {
  border: 1px solid $gray;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  flex-direction: row;
}

.explore-events__search-input svg {
  position: absolute;
  right: 18px;
  font-size: 24px;
  top: 50%;
  fill: $gray;
  transform: translate(0, -50%);
}

.explore-events__header {
  margin-top: 10px;
}

.recruiter_events .explore-events__header--title {
  color: $recruiter-color;
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0;
  align-items: center;
}

.candidate_events .explore-events__header--title {
  color: $candidate-color;
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0;
}

.explore-events__filters {
  margin-top: 10px;
}

.explore-events__location svg {
  position: absolute;
  right: 18px;
  font-size: 24px;
  top: 30%;
  fill: $gray;
  transform: translate(0, -50%);
  width: 23px;
  height: 24px;
}

.explore-events__location {
  width: 250px;
  border-radius: 5px;
  opacity: 1;
}

.explore-events__location input {
  border-radius: 3px;
  background: $white;
  background: $white 0% 0% no-repeat padding-box;
  border: 1px solid $tgtextcolor;
}

.explore-events__types {
  width: 100%;
  border-radius: 3px;
  position: relative;
  border-radius: 5px;
  opacity: 1;
  max-width: 276px;
  border-radius: 3px;
  position: relative;
  margin-left: 10px;
}

.explore-events__types .ant-select div.ant-select-selector,
.explore-events__types .ant-select div.ant-select-selector {
  color: $placeholder;
  border: 1px solid $tgtextcolor;
}

.explore-events__types .ant-select {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: $white 0% 0% no-repeat padding-box;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.explore-events__types .ant-select .ant-select-arrow,
.select_filter .ant-select .ant-select-arrow {
  display: none;
}

.explore-events__types svg {
  position: absolute;
  right: 18px;
  font-size: 16px;
  top: 30%;
  fill: $gray;
  transform: translate(0, -50%);
}

.explore-events__options {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.explore-events__options ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.explore-events__options ul li {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.explore-events__options ul li button {
  padding: 5px 10px;
  @include large-font-mixin();
}

.recruiter_events .explore-events__options ul li.active button {
  background-color: $recruiter-color;
  border-color: $recruiter-color;
  color: $white;
}

.candidate_events .explore-events__options ul li.active button {
  background-color: $candidate-color;
  border-color: $candidate-color;
  color: $white;
}

.recommended-event__slider {
  background-color: $white;
  border-radius: $bradius;
  box-shadow: $shadow;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex !important;
  flex-direction: row;

  @media (max-width: $mobile) {
    flex-direction: column-reverse;
  }

  .recommended-event__slider__text--left {
    height: 364px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .recommended-event__slider__text--left--top {
      padding-left: 20px;
      height: 300px;

      .recommended-event__slider__text__upper {
        h6 {
          font-weight: $regular;
          color: $gray;
        }

        h6.blue_text_ico {
          font-weight: $bold;

          svg {
            margin-right: 15px;
            position: relative;
            top: 3px;
          }
        }

        span {
          svg {
            fill: $gray;
            margin-right: 15px;
            position: relative;
            top: 3px;
            width: 14px;
            height: 14px;
          }
        }
      }

      .recommended-event__slider__text__lower {
        h6 {
          font-weight: $semi-bold;
        }

        span {
          svg {
            margin-right: 15px;
            position: relative;
            top: 3px;
            width: 16px;
            height: 16px;
          }
        }
      }

      p {
        @include large-font-mixin;
        color: $gray;
        font-weight: $regular;
        margin-bottom: 5px;
        line-height: 1.2em;
        height: 2.4em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        width: 100%;
        height: 28px;
        max-width: 137px;
        color: $white;
        border: none;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 0 20px;
        font-size: $font12;
        text-align: center;
      }

      h4 {
        color: $black;
        font-weight: $bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .recommended-event__booking {
      padding: 12px 11px 8px;
      border-radius: 0 0 0 10px;

      h6 {
        color: $white;
        font-weight: $regular;
        margin: 0;
      }

      b {
        color: $white;
        font-weight: $semi-bold;
      }

      p {
        color: $white;
        font-weight: $semi-bold;
      }
    }
  }

  .recommended-event__slider--right {
    height: 364px;

    img {
      height: 100.5%;
      width: 100%;

      @media (min-width: $desktop-min) {
        height: 142%;
      }
    }

    .eve-slider {
      border-radius: 10px;

      img {
        object-fit: cover;
        width: 100%;
        height: 364px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
}

.recruiter_events
  .recommended-event__slider
  .recommended-event__slider__text--left
  .recommended-event__slider__text--left--top {
  .recommended-event__slider__text__lower span {
    svg {
      fill: $recruiter-color;
      margin-right: 15px;
      position: relative;
      top: 3px;
      width: 16px;
      height: 16px;
    }
  }

  .recommended-event__slider__text__lower h6 {
    color: $recruiter-color;
  }

  button {
    background-color: $recruiter-color;
    color: white;
  }
}

.recruiter_events {
  .recommended-event__slider {
    &__text--left {
      .recommended-event__booking {
        background-color: $recruiter-color;
      }
    }
  }
}

.candidate_events {
  .recommended-event__slider {
    &__text--left {
      .recommended-event__slider__text--left--top {
        .recommended-event__slider__text__lower span {
          svg {
            font-size: 18px;
            fill: $candidate-color;
            margin-right: 15px;
            position: relative;
            top: 3px;
          }
        }
      }
    }
  }

  .recommended-event__slider__text__lower h6 {
    color: $candidate-color;
  }
}

.candidate_events
  .recommended-event__slider
  .recommended-event__slider__text--left
  .recommended-event__booking {
  background-color: $candidate-color;
}

.candidate_events
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__jobs {
  color: $candidate-color;

  svg {
    fill: $candidate-color;
  }
}

.recruiter_events
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__jobs {
  color: $recruiter-color;

  svg {
    fill: $recruiter-color;
  }
}

.recruiter_events
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__view
  .recommended-events-card__container__row__details__view__button
  .recommended-events-card__container__row__details__view__button--center {
  color: $white;
  background-color: $recruiter-color;
}

.candidate_events
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__view
  .recommended-events-card__container__row__details__view__button
  .recommended-events-card__container__row__details__view__button--center {
  color: $white;
  background-color: $candidate-color;
}

.recommended-events__container__heading {
  letter-spacing: 0;
  color: $titlefr_color;
  opacity: 1;
}

.candidate_events
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details
  .recommended-events-card__container__row__view-details__button
  .recommended-events-card__container__row__view-details__button--center {
  color: $candidate-color;
  background-color: $white;
  border-radius: 5px;
  height: 35px;
}

.candidate_events
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details {
  background-color: $candidate-color;
  opacity: 0.9;
}

.recruiter_events
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details
  .recommended-events-card__container__row__view-details__button
  .recommended-events-card__container__row__view-details__button--center {
  color: $recruiter-color;
  background-color: $white;
  border-radius: 5px;
  padding: 14px;
}

.recruiter_events
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details {
  background-color: $recruiter-color;
  opacity: 0.9;
}

@media (max-width: $mobile) {
  .explore-events__search-input {
    input {
      border: 1px solid $gray;
      border-radius: 5px;
      opacity: 1;
      display: flex;
      flex-direction: row;
    }

    svg {
      position: absolute;
      right: 18px;
      font-size: 24px;
      top: 50%;
      fill: $gray;
      transform: translate(0, -50%);
    }
  }

  .explore-events__header--title--hidden {
    display: none;
  }

  .explore-events__header {
    margin-top: 10px;
  }

  .recruiter_events .explore-events__header--title {
    color: $recruiter-color;
    font: normal normal medium 18px/27px Poppins;
    letter-spacing: 0;
    align-items: center;
  }

  .candidate_events .explore-events__header--title {
    color: $candidate-color;
    font: normal normal medium 18px/27px Poppins;
    letter-spacing: 0;
  }

  .explore-events__filters {
    margin-top: 10px;
  }

  .explore-events__location svg {
    width: 19px;
    height: 19px;
  }

  .explore-events__location {
    width: 48%;
  }

  .explore-events__types {
    width: 48%;
  }

  .explore-events__types .ant-select {
    width: 100%;
  }

  .explore-events__options ul li button {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  .recommended-event__slider {
    .recommended-event__slider__text--left {
      .recommended-event__slider__text--left--top {
        button {
          width: 162px;
          height: 33px;
          color: $white;
          border: none;
          padding: 10px 10px;
          font-size: $font12;
          margin-bottom: 20px;
        }

        h4 {
          font-size: $font16;
          font-weight: bold;
        }

        .recommended-event__slider__text__upper {
          span {
            svg {
              top: 3px;
              width: 19px;
              height: 18px;
            }
          }
        }

        .recommended-event__slider__text__lower {
          margin-top: 20px;

          span {
            svg {
              top: 3px;
              width: 20px;
              height: 21px;
            }
          }
        }

        p {
          color: $header;
          line-height: 1.2em;
          height: 2.4em;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .recommended-event__booking {
        b {
          font-weight: $semi-bold;
        }

        p {
          font-weight: $semi-bold;
        }
      }
    }

    .recommended-event__slider--right {
      .eve-slider {
        border-radius: 10px;

        img {
          height: 215px;
          border-radius: 0;
        }
      }
    }
  }

  .recommended-event__slider {
    display: flex;
    flex-direction: column-reverse;
  }

  .explore-events__circle--hidden {
    display: none;
  }

  .explore-events__options ul li {
    margin-right: 5px;
    width: 48%;
  }

  .recommended-event__slider__text--left--top {
    padding-right: 10px;
  }

  .recommended-event__booking {
    border-bottom-right-radius: 10px !important;
  }
}

@media (min-width: $desktop-min) {
  .explore-events__search-input input {
    border: 1px solid $gray;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: row;
  }

  .recommended-event__slider
    .recommended-event__slider__text--left
    > .recommended-event__slider__text--left--top
    > button {
    max-width: 205px;
    height: 48px;
  }

  .explore-events__search-input svg {
    position: absolute;
    right: 18px;
    font-size: 24px;
    top: 50%;
    fill: $gray;
    transform: translate(0, -50%);
  }

  .explore-events__header {
    margin-top: 10px;
  }

  .recruiter_events .explore-events__header--title {
    color: $recruiter-color;
    font: normal normal medium 18px/27px Poppins;
    letter-spacing: 0;
    align-items: center;
  }

  .candidate_events .explore-events__header--title {
    color: $candidate-color;
    font: normal normal medium 18px/27px Poppins;
    letter-spacing: 0;
  }

  .explore-events__filters {
    margin-top: 10px;
  }

  .explore-events__location svg {
    width: 19px;
    height: 19px;
  }

  .explore-events__location {
    width: 250px;
  }

  .explore-events__types {
    width: 250px;
  }

  .explore-events__types .ant-select {
    width: 250px;
  }

  .explore-events__options ul li button {
    padding: 10px 15px;
    margin-bottom: 10px;
    margin-right: 10px;
  }

  .recommended-event__slider {
    .recommended-event__slider__text--left {
      .recommended-event__slider__text--left--top {
        button {
          width: 205px;
          height: 48px;
          color: $white;
          border: none;
          padding: 10px 20px;
          font-size: $font18;
          margin-bottom: 36px;
          margin-top: 20px;
        }

        h4 {
          font-weight: bold;
        }

        .recommended-event__slider__text__upper {
          span {
            svg {
              top: 3px;
              width: 19px;
              height: 18px;
            }
          }
        }

        .recommended-event__slider__text__lower {
          margin-top: 20px;

          h6 {
            font-size: $font13;
            margin-bottom: 14px;
          }

          span {
            svg {
              top: 3px;
              width: 20px;
              height: 21px;
            }
          }
        }

        p {
          color: $header;
          margin-bottom: 23px;
          line-height: 1.2em;
          height: 2.4em;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .recommended-event__booking {
        b {
          font-weight: $semi-bold;
        }

        p {
          font-weight: $semi-bold;
        }
      }
    }

    .recommended-event__slider--right {
      .eve-slider {
        border-radius: 10px;

        img {
          height: 516px;
          border-radius: 0;
        }
      }
    }
  }

  .explore-events__circle--hidden {
    display: none;
  }

  .explore-events__options ul li {
    margin-right: 5px;
  }

  .recommended-event__slider__text--left--top {
    padding-right: 10px;
  }

  .recommended-event__slider {
    background-color: $white;
    border-radius: $bradius;
    box-shadow: $shadow;
    margin-top: 30px;
    height: 516px;
    margin-bottom: 50px;

    .recommended-event__slider__text--left {
      height: 516px;

      .recommended-event__slider__text--left--top {
        padding-left: 20px;
        height: 438px;

        .recommended-event__slider__text__upper {
          h6 {
            font-weight: $regular;
            color: $gray;
            margin-bottom: 10px;
          }

          h6.blue_text_ico {
            font-weight: $bold;

            svg {
              margin-right: 15px;
              position: relative;
              top: 3px;
            }
          }

          span {
            svg {
              font-size: 18px;
              fill: $gray;
              margin-right: 15px;
              position: relative;
              top: 3px;
              width: 19px;
              height: 18px;
            }
          }
        }

        .recommended-event__slider__text__lower {
          h6 {
            font-weight: $semi-bold;
          }

          h6.blue_text_ico {
            font-weight: $bold;

            svg {
              margin-right: 15px;
              position: relative;
              top: 3px;
            }
          }

          span {
            svg {
              font-size: 18px;
              margin-right: 15px;
              position: relative;
              top: 3px;
              width: 24px;
              height: 24px;
            }
          }
        }

        p {
          font-size: $font15;
          color: $gray;
          font-weight: $regular;
          margin-bottom: 10px;
          line-height: 1.2em;
          height: 2.4em;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h4 {
          color: $black;
          font-weight: $bold;
        }
      }

      .recommended-event__booking {
        padding: 12px 11px 8px;
        border-radius: 0 0 0 10px;

        h6 {
          color: $white;
          font-weight: $regular;
          margin: 0;
        }

        b {
          color: $white;
          font-weight: $semi-bold;
        }

        p {
          color: $white;
          font-weight: $semi-bold;
        }
      }
    }

    .recommended-event__slider--right {
      .eve-slider {
        border-radius: 10px;

        img {
          object-fit: cover;
          width: 100%;
          height: 516px;
          border-radius: 0 10px 10px 0;
        }
      }
    }
  }

  .recruiter_events
    .recommended-event__slider
    .recommended-event__slider__text--left
    .recommended-event__slider__text--left--top {
    .recommended-event__slider__text__lower span {
      svg {
        font-size: 18px;
        fill: $recruiter-color;
        margin-right: 15px;
        position: relative;
        top: 3px;
        width: 24px;
        height: 24px;
      }
    }

    .recommended-event__slider__text__lower h6 {
      color: $recruiter-color;
    }

    button {
      background-color: $recruiter-color;
      color: white;
    }
  }
}

.candidate_events .recommended-event__slider__text--left--top button {
  background-color: $candidate-color;
  color: $white;
}

.book-event-explore-event {
  &:hover {
    cursor: pointer;
  }
}
