//Colors
$color_white: #ffffff;
$color_main_container: #fafafa;
$color_border_line: #d9d9d9;
$color_text_1: #434343;
$color_text_2: #8c8c8c;
$color_organizer: #47bfa8;
$color_recruiter: #ffbf40;
$color_candidate: #6963aa;
$resume_color: #d9d9d9;

$border_line: 0.1rem solid $color_border_line;
