@import '../../variables.scss';

.recruiter_events,
.candidate_events {
  .purchase-overview__modal {
    min-width: 75vw;

    .purchase-overview__flex {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1100px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .purchase-overview {
      &__title {
        font-size: 24px;
        font-weight: $bold;
      }

      &__sub-title {
        font-size: 18px;
        font-weight: $semi-bold;
        letter-spacing: 0.2px;
      }

      &__tickets {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        div {
          width: 350px;
        }

        & p {
          margin-left: 10px;
          font-weight: $bold;
        }
      }

      &__billing-address {
        margin: 50px 0;
        width: 350px;
        height: auto;
        h3 {
          color: $tgtextcolor;
          font-size: 18px;
        }
      }

      &__promo-code--success {
        color: $tgtextcolor;
        font-size: 18px;
        margin-bottom: 1rem;
      }

      &__promo-code {
        color: $ctextcolor;
        font-weight: $bold;
        letter-spacing: 1px;
        margin-bottom: 2rem;
      }

      &__payment {
        color: $tgtextcolor;
        font-size: 18px;
      }

      & > .ant-select {
        width: 30vw;
        color: $black;
      }

      &__recap {
        margin-left: 10rem;
        padding: 3rem;
        background-color: $very-light-grey-2;
        width: 30vw;
        height: 60vh;
        border-radius: 5px;

        &--title {
          color: $ctextcolor;
          font-weight: $semi-bold;
          font-size: 18px;
        }

        &--sub-title {
          color: $ctextcolor;
          font-weight: $bold;
          font-size: 18px;
        }

        hr {
          border-top: 1px dashed $sub-header;
        }

        &--flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $black;
          white-space: nowrap;
          margin-bottom: 10px;

          & > button {
            border: none;
            font-weight: $bold;
            font-size: 18px;
          }
        }

        &--discount {
          color: $ctextcolor;
          font-weight: $bold;
          margin-top: 1.5rem;
        }

        &--total-charges {
          display: flex;
          justify-content: space-between;
          padding: 1rem 0;
        }
      }

      &__recap--data,
      &__recap--charges {
        margin-bottom: 1rem;
      }
    }

    .back-or-proceed {
      padding-top: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      & > button {
        padding: 1rem 5rem;
        border-radius: 5px;
      }

      .proceed {
        margin-right: 4rem;
        color: $white;
        border: none;
      }
    }
  }
}

.recruiter_events {
  .purchase-overview__modal {
    .purchase-overview {
      &__title {
        font-size: 24px;
        color: $recruiter-color;
      }

      &__tickets {
        & p {
          color: $recruiter-color;
        }
      }

      &__recap {
        &--flex {
          & > button {
            background-color: $recruiter-color;
          }
        }
      }
    }

    .back-or-proceed {
      .proceed {
        background-color: $recruiter-color;
      }
    }
  }
}

.candidate_events {
  .purchase-overview__modal {
    .purchase-overview {
      &__title {
        font-size: 24px;
        color: $candidate-color;
      }

      &__tickets {
        & p {
          color: $candidate-color;
        }
      }

      &__recap {
        &--flex {
          & > button {
            background-color: $candidate-color;
            color: $white;
          }
        }
      }
    }

    .back-or-proceed {
      .proceed {
        background-color: $candidate-color;
      }
    }
  }
}
