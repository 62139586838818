.job-description {
  &__container {
    @include medium-font-mixin;

    display: flex;
    flex-direction: column;
    position: relative;
    height: min-content;
    z-index: 1;
    background: $white;
    box-shadow: $shadow;
    padding-bottom: 30px;

    @media (max-width: $mobile) {
      padding: 0 10px;
    }
  }

  &__heading {
    @include medium-heading-mixin;

    color: $recruiter-color;
    font-weight: $medium;
    margin: 26px;

    @media (max-width: $mobile) {
      margin: 26px 0;
    }
  }

  &-industry-type__dropdown {
    margin-left: 26px;
    margin-top: 16px;

    @media (max-width: $mobile) {
      margin-left: 0;
    }

    .ant-select-selector {
      height: 100%;
      padding: 3px;
    }
  }

  &-pay__select {
    display: flex;
    flex-direction: row;
    margin-left: 26px;
    margin-top: 16px;

    @media (max-width: $mobile) {
      margin-left: 0;
    }
  }

  &-pay__currency {
    width: 100px;
  }

  &-pay__value {
    width: 250px;
    margin-left: -30px;

    @media (max-width: $mobile) {
      width: 100%;
      margin-left: 8px;
    }
  }

  &__sub-heading {
    @include small-heading-mixin;

    color: $sub-header;
    margin-left: 26px;
    margin-top: 36px;
    font-weight: $medium;

    @media (max-width: $mobile) {
      margin-left: 0;
    }
  }

  &__textarea {
    width: 700px;
    margin-left: 26px;
    margin-top: 16px;

    @media (max-width: $mobile) {
      width: 100%;
      margin-left: 0;
    }

    .ant-input {
      height: 100%;
      padding: 3px;
    }
  }
}

@media (min-width: $desktop-min) {
  .job-description {
    &__container {
      @include medium-font-mixin;

      display: flex;
      flex-direction: column;
      position: relative;
      height: min-content;
      z-index: 1;
      background: $white;
      box-shadow: $shadow;
      padding-bottom: 30px;
    }

    &__heading {
      @include medium-heading-mixin;

      color: $recruiter-color;
      font-weight: $medium;
      margin: 26px;
    }

    &-industry-type__dropdown {
      margin-left: 26px;
      margin-top: 16px;

      .ant-select-selector {
        height: 100%;
        padding: 3px;
      }
    }

    &-pay__select {
      display: flex;
      flex-direction: row;
      margin-left: 26px;
      margin-top: 16px;
    }

    &-pay__currency {
      width: 100px;
    }

    &-pay__value {
      width: 200px;
      margin-left: -40px;
    }

    &__sub-heading {
      @include small-heading-mixin;

      color: $sub-header;
      margin-left: 26px;
      margin-top: 36px;
      font-weight: $medium;
    }

    &__textarea {
      width: 820px;
      margin-left: 26px;
      margin-top: 16px;

      .ant-input {
        height: 100%;
        padding: 3px;
      }
    }
  }
}

// todo media query for mobile version
.recruitment_header__join-event {
  @include display-flex(row);
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
  .join-event-button {
    background-color: $recruiter-color;
    color: white;
  }
}

@media (max-width: 767px) {
  .join-event-button {
    width: unset;
    height: 30px;
  }
}
