.support {
  &__heading {
    margin-top: 30px;
    font-weight: 500;
    position: relative;

    @media (max-width: $mobile) {
      margin-top: 10px;
      font-weight: $semi-bold;
    }
  }

  .title.pro_title p {
    padding-left: 0;
  }

  &__container {
    padding: 30px 20px;
    background-color: $white;
    z-index: 1;
    box-shadow: $shadow;
    height: unset;
    margin-top: 30px;
    position: relative;

    @media (min-width: $desktop-min) {
      padding: 44px 30px;
    }

    @media (max-width: $mobile) {
      padding: 20px 10px;
    }

    &__item {
      padding-bottom: 10px;

      &__title {
        margin-bottom: 10px;
      }

      &__submit {
        @include display-flex(row);

        justify-content: flex-end;
      }
    }
  }
}

.organizer_support {
  height: 90vh;

  .circle_box {
    background-color: $primary-color;
  }

  .support__heading {
    color: $primary-color;
  }
}

.support__container__item__submit {
  margin-top: 20px;
}

.support__container {
  .bulk-invite {
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;

      .ant-col-offset-2 {
        margin-left: 0;
      }
    }
  }
}

.recruiter_support {
  height: 90vh;
  .circle_box {
    background-color: $recruiter-color;
  }

  .support__heading {
    color: $recruiter-color;
  }
}

.candidate_support {
  height: 90vh;

  .circle_box {
    background-color: $candidate-color;
  }

  .support__heading {
    color: $candidate-color;
  }
}

.support__header-item {
  display: flex;
  flex-direction: row;

  &__link {
    display: flex;
    color: $gray-2;
    flex-direction: row-reverse;

    a {
      @include medium-font-mixin;

      color: $gray-2;
    }
  }
}

a.support_support_link {
  color: $bluecolor;

  &:hover {
    cursor: pointer;
  }
}
