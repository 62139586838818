.calendar-view-modal__container {
  &__add-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      height: auto;
      font-weight: $medium;
      span {
        @include medium-heading-mixin;
        font-weight: $semi-bold;
      }
    }
  }

  &__date {
    padding: 20px;
    color: $label;
    font-weight: $semi-bold;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__secondary-date {
    padding: 20px;
    color: $tgtextcolor;
    background-color: #f0f0f0;
    font-weight: $semi-bold;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__save-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__slots {
    font-weight: $semi-bold;
    margin-right: 30px;
  }

  &__slots--scroll {
    height: 350px;
    overflow-y: scroll;
  }

  &__slot-container {
    padding-left: 15px;
    border-left: 1px solid $tgtextcolor;
    margin-top: 5px;
    margin-bottom: 5px;

    &__range__division {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__edit-icon {
        margin-left: 20px;

        svg {
          fill: $gray;
          width: 30px;
          height: 30px;
        }
      }

      &__delete-icon {
        margin-left: 20px;

        svg {
          fill: $custom-red;
          width: 30px;
          height: 30px;
        }
      }
    }

    &__title {
      font-weight: $semi-bold;
      color: $black-text;
    }

    &__type {
      color: $ctextcolor;
      margin-bottom: 5px;
    }

    &__range {
      padding: 10px 22px;
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      max-width: max-content;

      span {
        color: $label;
        display: flex;
        align-items: center;
      }

      &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;

        svg {
          width: 10px;
          height: 8px;
        }
      }

      &__calendar {
        margin-left: 10px;

        svg {
          width: 18px;
          height: 18px;
          fill: $label;
        }
      }
    }
  }

  &__time-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    &--left {
      width: 8%;
    }

    &--center {
      width: 92%;
      height: 1px;
      border-top: 1px solid black;
    }
  }

  &__time {
    color: $color_text_2;
    margin-top: 10px;
    cursor: pointer;
    border: none;

    button {
      span {
        margin-left: -6px;
        padding: 0;
      }
    }
  }
}

.recruiter-calendar-modal {
  .responsive-modal-header__container {
    background-color: $recruiter-color;
  }

  .calendar-view-modal__header {
    color: $recruiter-color;
  }

  .calendar-view-modal__container {
    &__add-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-right: 10px;

      button {
        span {
          color: $recruiter-color;

          @media (min-width: $laptop-min) {
            margin-right: -10px;
          }
        }
      }
    }

    &__date {
      background-color: $recruiter-color;
    }

    &__save-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        background-color: $recruiter-color;

        span {
          color: white;
        }
      }
    }

    &__slot-container {
      &__range {
        background-color: $recruiter-color;

        span {
          color: $label;
        }
      }
    }

    &__time-divider {
      span {
        color: $recruiter-color;
      }

      &--center {
        border-top: 1px solid $recruiter-color;
      }
    }
  }
}

.candidate-calendar-modal {
  .responsive-modal-header__container {
    background-color: $candidate-color;
  }

  .calendar-view-modal__header {
    color: $candidate-color;
  }

  .calendar-view-modal__container {
    &__add-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button {
        span {
          color: $candidate-color;
        }
      }
    }

    &__date {
      background-color: $candidate-color;
    }

    &__save-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        background-color: $candidate-color;

        span {
          color: white;
        }
      }
    }

    &__slot-container {
      &__range {
        background-color: $candidate-color;

        span {
          color: white;
        }

        &__arrow {
        }

        &__calendar {
        }
      }
    }

    &__time-divider {
      span {
        color: $candidate-color;
      }

      &--center {
        border-top: 1px solid $candidate-color;
      }
    }
  }
}

@media (max-width: $mobile) {
  .recruiter-calendar-modal,
  .candidate-calendar-modal {
    .ant-modal-body {
      padding: 15px;
    }
  }

  .calendar-view-modal__header {
    display: none;
  }

  .calendar-view-modal__container {
    &__title--left {
      display: flex;
      align-items: center;
    }

    &__add-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        span {
          @include small-heading-mixin;
        }
      }
    }

    &__date {
      padding: 5px 10px;
      margin: 5px 0;
    }

    &__secondary-date {
      padding: 5px 10px;
    }

    &__save-button {
      button {
        height: auto;
      }
    }

    &__slot-container {
      &__range__division {
        &__edit-icon {
          margin-left: 20px;

          svg {
            fill: $gray;
            width: 22px;
            height: 22px;
          }
        }

        &__delete-icon {
          margin-left: 20px;

          svg {
            fill: $custom-red;
            width: 22px;
            height: 22px;
          }
        }
      }

      &__title {
        font-weight: $semi-bold;
        color: $black-text;
      }

      &__type {
        color: $ctextcolor;
        margin-bottom: 5px;
      }

      &__range {
        padding: 10px 22px;

        span {
          color: white;
        }

        &__arrow {
          margin-left: 4px;
          margin-right: 4px;

          svg {
            width: 10px;
            height: 8px;
          }
        }

        &__calendar {
          margin-left: 10px;

          svg {
            width: 18px;
            height: 18px;
            fill: $label;
          }
        }
      }
    }

    &__time-divider {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      &--left {
        width: 20%;
      }

      &--center {
        width: 80%;
        height: 1px;
        border-top: 1px solid black;
      }
    }

    &__time {
      color: $color_text_2;
      margin-top: 10px;
    }
  }
}

@media (min-width: $desktop-min) {
  .calendar-view-modal__container {
    &__add-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      button {
        span {
          @include medium-heading-mixin;

          margin-right: -40px;
          font-weight: $semi-bold;
        }
      }
    }
  }
}
