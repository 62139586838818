.join-event {
  margin-right: 25px;

  button {
    border-radius: 5px;
    border: 0;
    color: #fff;
  }

  a > button {
    background-color: $candidate-color;
  }

  &__recruiter {
    background-color: $recruiter-color;
  }
}
