@import '../../mixins.scss';

// @import "../../variables.scss";
body {
  font-family: 'Poppins', sans-serif;
}

$shadow: 0 0 10px #0000001a;
$bradius: 10px;
$primary-color: #47bfa8;
$recruiter-color: #ffbf40;
$icon_color: $placeholder;
$font16: 16px;

.ml55 {
  margin-left: 55px;
}

.mr55 {
  margin-right: 55px;
}

.mt25 {
  margin-top: 25px;
}

.pl30 {
  padding-left: 30px;
}

.clear {
  clear: both;
}

.relative {
  position: relative;
}

.floatl {
  float: left;
}

.floatr {
  float: right;
}

.mb0 {
  margin-bottom: 0 !important;
}

// title-sec
.circle_box {
  background: $primary-color;
  border-radius: 50%;
  width: 260px;
  height: 260px;
  opacity: 0.15;
  position: absolute;
  left: -124px;
  top: -100px;
}

.title_sec {
  h3 {
    color: $header;
    line-height: 46px;
    margin-top: 22px;
    float: left;
    font-weight: $bold;
  }

  .light_red {
    width: 40px;
    height: 40px;
    float: left;
    margin-top: 22px;
    border-radius: 50%;
    margin-left: 28px;
    background-color: $light_red;
    text-align: center;
    padding-top: 8px;

    svg {
      width: 23px;
      fill: $custom-red;
      transition: 0.2s;
    }

    &:hover svg {
      width: 19px;
    }
  }

  .light_green {
    width: 40px;
    height: 40px;
    float: left;
    margin-top: 22px;
    border-radius: 50%;
    margin-left: 28px;
    background-color: $light_green;
    text-align: center;
    padding-top: 8px;

    svg {
      width: 23px;
      fill: $custom-green;
      transition: 0.2s;
    }

    &:hover svg {
      width: 19px;
    }
  }

  .right_info {
    width: 21px;
    height: 21px;
    float: right;
    margin-top: 22px;
    border-radius: 50%;
    margin-left: 28px;
    text-align: center;

    svg {
      fill: $primary-color;
      font-size: 21px;
    }
  }
}

.profile-unverified-text {
  color: $custom-red;
}

.profile-verified-text {
  color: $custom-green;
}

// Event card container
.event-card-container {
  height: 400px;
  overflow-y: auto;
  padding: 10px 0;
}

// ongoing event
.par_ongoingsec {
  box-shadow: $shadow;
  border-radius: $bradius;
  overflow: hidden;
  height: 237px;

  .ongoing_evnt {
    width: 100%;
    z-index: 1;
    position: relative;
    background: $white;

    .text_sec {
      width: 100%;
      height: 237px;
      position: relative;
      padding-left: 25px;
      padding-top: 20px;
      padding-right: 20px;

      @media (max-width: $mobile) {
        height: auto;
      }

      h5 {
        color: $primary-color;
        line-height: 21px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 22px;
        font-weight: $semi-bold;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          background-color: $primary-color;
        }
      }

      h4 {
        @include large-font-mixin;

        color: $sheader2;
        line-height: 30px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: $semi-bold;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .date_sec {
        display: flex;
        margin-bottom: 50px;
        flex-wrap: wrap;

        h6 {
          color: $gray;
          line-height: 23px;
          font-weight: 400;
          display: flex;

          svg {
            fill: gray;
            width: 17px;
            margin-right: 15px;
            position: relative;
            top: 0;
          }
        }
      }

      .candidate {
        ul {
          list-style-type: none;
          display: flex;
          justify-content: space-between;
          padding: 0;
          margin-bottom: 10px;
          flex-wrap: wrap;

          li {
            p {
              color: $ctextcolor;
              line-height: 25px;
              font-weight: $medium;
            }

            b {
              color: $primary-color;
              line-height: 26px;
              font-weight: $semi-bold;
            }
          }
        }
        ul.candidate-event-analytics {
          list-style-type: none;
          display: flex;
          padding: 0;
          margin-bottom: 10px;
          flex-wrap: wrap;
          justify-content: unset;

          li {
            margin-right: 60px;

            @media (min-width: $desktop-min) {
              margin-right: 120px;
            }

            p {
              color: $ctextcolor;
              line-height: 25px;
              font-weight: $medium;
            }

            b {
              color: $primary-color;
              line-height: 26px;
              font-weight: $semi-bold;
            }
          }
        }
      }
    }

    .slider_sec {
      img {
        width: 100%;
        height: auto;
        border-radius: 0 10px 10px 0;
        height: 306px;
      }
    }
  }

  .live_event {
    position: relative;

    .item_img {
      img {
        width: 100%;
        height: 237px;
        border-radius: 0 0 10px 0;
        margin-bottom: -7px;
        image-rendering: pixelated;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.liveevent_icon {
  animation: blink 2s infinite;
}

.liveevent_icon,
.upcoming-event__icon {
  max-width: 135px;
  width: 100%;
  height: 37px;
  background: $white;
  box-shadow: 0 3px 15px #00000029;
  border-radius: 20px;
  position: absolute;
  right: 14px;
  top: 12px;
  text-align: center;
  padding: 5px 0;
  color: red;
  font-weight: 500;
  font-size: 14px;

  @media (min-width: $desktop-min) {
    right: 28px;
    top: 19px;
  }

  span {
    svg {
      font-size: 23px;
      width: 23px;
      fill: $custom-red;
      margin-left: 7px;
      line-height: 16px;
      vertical-align: sub;
    }
  }

  &:hover {
    cursor: pointer;
  }
}


// created events
.createdevents {
  .created_eve {
    background-color: $primary-color;
    padding-left: 15px;
    padding-top: 19px;
    padding-bottom: 19px;
    border-radius: $bradius;
    box-shadow: $shadow;
    overflow: hidden;
    position: relative;

    .circle_1 {
      max-width: 175px;
      width: 100%;
      background: #6dc9b7;
      height: 175px;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -51px;
      z-index: 1;
    }

    .circle_2 {
      max-width: 137px;
      width: 100%;
      background: #5ac4af;
      height: 137px;
      border-radius: 50%;
      position: absolute;
      right: -43px;
      top: 24%;
      z-index: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      position: relative;
      z-index: 1;

      li {
        overflow: hidden;

        .left {
          background-color: #a3d6cd;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          float: left;
          margin-right: 10px;

          svg {
            fill: $white;
          }
        }

        .right {
          float: left;

          p {
            color: $white;
            margin: 0;
            font-weight: $medium;
          }

          b {
            color: $white;
          }
        }

        p,
        b,
        .left {
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          p,
          b {
            color: $label;
          }

          .left {
            background-color: $label !important;
          }
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
}

// view-details
.viewdetails {
  text-align: right;
  padding-top: 10px;

  div {
    @include large-font-mixin;
    @include cursorPointer;

    color: $icon_color;
    font-weight: 500;
    display: block;
    width: 139px;
    float: right;

    svg {
      width: 18px;
      fill: $icon_color;
      margin-left: 15px;
      position: relative;
      top: 4px;
    }
  }
}

.viewdetails,
.vw-details {
  text-align: right;
  height: max-content;

  a {
    height: max-content;
  }

  div {
    color: $icon_color;
    font-weight: 500;
    display: block;
    width: 139px;
    float: right;

    svg {
      width: 15px;
      fill: $icon_color;
      margin-left: 15px;
      position: relative;
      top: 4px;
    }
  }
}

.recruiter_dashboard .par_upcoming {
  margin-top: -20px;

  @media (max-width: $tablet-max) {
    margin-top: 0px;
  }

  @media (max-width: $mobile) {
    margin-top: -30px;
  }
}

// upcoming-events
.par_upcoming {
  h5.upcoming {
    color: $primary-color;
    line-height: 21px;
    margin-bottom: 30px;
    position: relative;
    padding-left: 22px;
    font-weight: $semi-bold;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: $primary-color;
    }
  }
}

.par_past {
  margin-top: 4rem;
  margin-bottom: 50px;
}

// Date-wise -section
.datewise {
  h4 {
    color: $tgtextcolor;
    border-bottom: 1px solid $icon_color;
    padding-bottom: 12px;
  }

  .future_box {
    background: $white;
    padding: 16px 25px;
    margin-top: 15px;
    border-radius: $bradius;
    box-shadow: $shadow;

    .event-header {
      @include display-flex(row);
      justify-content: space-between;
      align-items: flex-start;

      .event-finished {
        background-color: $very-light-grey-2;
        color: $gray;
        font-weight: $semi-bold;
        padding: 5px 20px;
        border-radius: 10px;
        margin-right: -8px;

        span {
          @include medium-font-mixin;
        }
      }
    }

    h5 {
      color: $sheader2;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: left;
    }

    .action_sec {
      display: flex;
      justify-content: space-between;

      div.left {
        h6 {
          color: $gray;
          float: left;
          font-size: 15px;
          line-height: 23px;
          display: flex;
          align-items: center;
        }

        svg {
          width: 23px;
          fill: gray;
          margin-right: 15px;
          position: relative;
          top: 5px;
        }
      }

      .vw-details {
        div {
          font-size: $font11;
          color: $icon_color;
          display: block;
          text-align: right;
          width: 139px;

          @media (min-width: $tablet-min) and (max-width: $tablet-max) {
            margin-top: -10px;
          }
        }

        svg {
          width: 16px;
          fill: $icon_color;
          margin-left: 15px;
          position: relative;
          top: 4px;
        }

        .hover {
          @include cursorPointer;
        }
      }
    }
  }
}

// earnings
.earning {
  background-color: $white;
  margin-top: 0px;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: $shadow;
  overflow: hidden;

  @media (min-width: $desktop-min) {
    margin-top: -40px;
  }

  h5 {
    font-size: $font15;
    color: $icon_color;
    line-height: 27px;
    font-weight: 500;
  }

  p {
    font-size: $font15;
    color: $icon_color;
    line-height: 27px;
    font-weight: 400;

    span {
      font-size: $font15;
      padding-left: 10px;
      font-weight: 700;
    }
  }

  span {
    font-size: $font12;
  }

  h4 {
    font-size: $font18;
    color: $primary-color;
    line-height: 35px;
    font-weight: 600;
  }

  .floatr {
    margin-top: 19px;

    svg {
      width: 40px;
      height: 40px;
      fill: $primary-color;
    }
  }
}

// notifications
.notifications {
  background-color: $white;
  margin-top: 20px;
  padding: 20px 20px 10px;
  border-radius: 10px;
  box-shadow: $shadow;
  position: relative;
  overflow: hidden;

  .circle_light {
    width: 200px;
    height: 200px;
    background: $primary-color;
    border-radius: 50%;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: -78px;
  }

  h5 {
    color: $icon_color;
    line-height: 27px;
    font-weight: 500;
    float: left;
    font-size: $font15;
  }

  svg {
    width: 20px;
    float: right;
    fill: $icon_color;
  }

  ul {
    list-style-type: none;
    padding: 0;
    padding-top: 16px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      &:last-child() {
        margin-bottom: 0;
      }
      @media (max-width: $mobile) {
        margin-bottom: 20px;
        &:last-child() {
          margin-bottom: 20px;
        }
      }

      .left_bx {
        width: 45px;
        height: 45px;
        border-radius: 10px;
        float: left;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        svg {
          width: 22px;
          fill: $white;
        }
      }

      p {
        font-weight: 400;
        color: $gray;
        margin: 0;
        font-weight: $medium;
        font-size: $font14;
      }

      .left_bx,
      .no_icon,
      p {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        p {
          color: $label;
        }
        .left_bx,
        .no_icon {
          background-color: $label;
        }
      }
    }
  }

  .no_icon {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    right: -8px;
    box-shadow: 0 0 10px #7d7d7d;
    text-align: center;
    color: $white;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
  }
}

//tooltip
.ant-tooltip.ant-tooltip-placement-right {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: $white;
    color: $custom-red;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none;
  }
}

.ant-tooltip-placement-left {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background: $white;
    color: $ctextcolor;
    font-weight: 500;
    font-size: 14px;
  }
}

// crew-menu
.crew_menu {
  max-width: 300px;
  width: 100%;
  min-width: 160px;
  top: -33px;
  left: -34px;
  border-radius: 5px;

  li {
    a {
      cursor: pointer;
    }

    &:hover {
      color: red;
      background-color: $white;
    }

    &:hover a {
      color: red;
    }
  }
}

//slider dot button
.live_event {
  ul.slick-dots {
    left: unset;
    margin-right: 5%;

    li {
      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid $white;
      }
    }
  }
}

//recruiter theme color
.recruiter_dashboard .createdevents .created_eve .circle_1 {
  background: #ffcf6e;
}

.recruiter_dashboard .createdevents .created_eve .circle_2 {
  background: #ffc758;
}

.recruiter_dashboard .createdevents .created_eve ul li .left {
  background-color: #fed27c;
}

.recruiter_dashboard .circle_box,
.recruiter_dashboard .par_ongoingsec .ongoing_evnt .text_sec h5::before,
.recruiter_dashboard .notifications .circle_light,
.recruiter_dashboard .createdevents .created_eve,
.recruiter_dashboard .par_upcoming h5.upcoming::before {
  background-color: $recruiter-color;
}

.recruiter_dashboard .par_upcoming {
  @media (min-width: $desktop-min) {
    margin-top: -5px;
  }
}

.recruiter_dashboard .par_ongoingsec .ongoing_evnt .text_sec h5,
.recruiter_dashboard .par_ongoingsec .ongoing_evnt .text_sec .candidate ul li b,
.recruiter_dashboard .par_upcoming h5.upcoming,
.recruiter_dashboard .earning h4,
.recruiter_dashboard
  .par_upcoming
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__jobs {
  color: $recruiter-color;

  svg {
    fill: $recruiter-color;
  }
}

.recruiter_dashboard
  .par_upcoming
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__view
  .recommended-events-card__container__row__details__view__button
  .recommended-events-card__container__row__details__view__button--center {
  color: $white;
  background-color: $recruiter-color;
}

.recruiter_dashboard
  .par_upcoming.recommended-events-card__container
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__view
  .recommended-events-card__container__row__details__view__button
  .recommended-events-card__container__row__details__view__button--center {
  color: $white;
  background-color: $recruiter-color;
}

.recruiter_dashboard
  .par_upcoming
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details
  .recommended-events-card__container__row__view-details__button
  .recommended-events-card__container__row__view-details__button--center {
  color: $recruiter-color;
  background-color: $white;
  border-radius: 5px;
  padding: 14px;
}

.recruiter_dashboard
  .par_upcoming
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details {
  background-color: $recruiter-color;
  opacity: 0.85;
}

.recruiter_dashboard .earning .floatr svg,
.recruiter_dashboard .title_sec .right_info svg {
  fill: $recruiter-color;
}
.recruiter_dashboard,
.organizer_dashboard {
  .title_sec .right_info {
    width: auto;
    border-radius: unset;
    text-align: center;
    color: white;
    padding: 5px 10px;
    height: auto;
    &:hover {
      cursor: pointer;
    }
  }
}

.recruiter_dashboard .title_sec .right_info {
  background-color: $recruiter-color;
  border-radius: 5px;
}

.organizer_dashboard .title_sec .right_info {
  background-color: $primary-color;
  border-radius: 5px;
}

//candidate theme color
.candidate_dashboard .createdevents .created_eve .circle_1 {
  background: $candidate-color-light-shade;
  opacity: 0.85;
}

.candidate_dashboard .createdevents .created_eve .circle_2 {
  background: $candidate-color-light-shade;
  opacity: 0.85;
}

.candidate_dashboard .createdevents .created_eve ul li .left {
  background-color: $notifications-blue;
}

.candidate_dashboard .circle_box,
.candidate_dashboard .par_ongoingsec .ongoing_evnt .text_sec h5::before,
.candidate_dashboard .notifications .circle_light,
.candidate_dashboard .createdevents .created_eve,
.candidate_dashboard .par_upcoming h5.upcoming::before {
  background-color: $candidate-color;
}

.candidate_dashboard .par_upcoming {
  margin-top: -105px;
  @media (min-width: $desktop-min) {
    margin-top: -15px;
  }
}

.candidate_dashboard .par_ongoingsec .ongoing_evnt .text_sec h5,
.candidate_dashboard .par_ongoingsec .ongoing_evnt .text_sec .candidate ul li b,
.candidate_dashboard .par_upcoming h5.upcoming,
.candidate_dashboard .earning h4,
.candidate_dashboard
  .par_upcoming
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__jobs {
  color: $candidate-color;

  svg {
    fill: $candidate-color;
  }
}

.candidate_dashboard .earning .floatr svg,
.candidate_dashboard .title_sec .right_info svg {
  fill: $candidate-color;
}

.candidate_dashboard
  .par_upcoming
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__details
  .recommended-events-card__container__row__details__view
  .recommended-events-card__container__row__details__view__button
  .recommended-events-card__container__row__details__view__button--center {
  color: $white;
  background-color: $candidate-color;
}

.candidate_dashboard
  .par_upcoming
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details
  .recommended-events-card__container__row__view-details__button
  .recommended-events-card__container__row__view-details__button--center {
  color: $candidate-color;
  background-color: $white;
  border-radius: 5px;
  padding: 14px;
}

.candidate_dashboard
  .par_upcoming
  .recommended-events-card__wrapper
  .recommended-events-card__container
  .recommended-events-card__container__row__view-details {
  background-color: $candidate-color;
  opacity: 0.85;
  border-radius: 5px;
}

#recommended-events-card__details__button {
  padding: 13px 0px;
  margin-left: 10px;
  height: 32px;
  width: 130px;

  @media (min-width: $desktop-min) {
    width: 140px;
    margin-left: 25px;
  }
}

.recruiter_dashboard .ongoing-booked-events__join-button--right button {
  color: $white;
  background-color: $recruiter-color;
}

.candidate_dashboard .ongoing-booked-events__join-button--right button {
  color: $white;
  background-color: $candidate-color;
}

.candidate_dashboard .createdevents .created_eve ul li {
  p,
  b,
  .left {
    transition: all 0.3s ease-in-out;
  }

  .left {
    background-color: $candidate-color-light-shade;
  }

  &:hover {
    p,
    b {
      color: $label;
    }

    .left {
      background-color: $label;
    }
  }
}

@media (min-width: 1201px) and (max-width: 1599px) {
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (max-width: 1710px) and (min-width: 1199px) {
  .pl30 {
    padding-left: 15px;
  }
}

@media (min-width: $desktop-min) {
  .ant-form label {
    font-size: $font16;
  }

  .circle_box {
    border-radius: 50%;
    width: 310px;
    height: 310px;
  }

  .title_sec {
    .light_red {
      width: 40px;
      height: 40px;

      svg {
        width: 23px;
      }

      &:hover svg {
        width: 19px;
      }
    }

    .right_info {
      width: 29px;
      height: 29px;

      svg {
        fill: $primary-color;
        font-size: 29px;
      }
    }
  }

  .par_ongoingsec {
    height: 300px;

    .ongoing_evnt {
      .text_sec {
        height: 300px;

        h4 {
          font-size: 20px;
        }

        .date_sec {
          h6 {
            svg {
              width: 23px;
            }
          }
        }

        .candidate {
          ul {
            margin-bottom: 20px;

            li {
              margin-left: 5px;
            }
          }
        }
      }

      .slider_sec {
        img {
          height: 219px;
        }
      }
    }

    .live_event {
      position: relative;

      .item_img {
        img {
          width: 100%;
          height: 308px;
        }
      }
    }
  }

  .liveevent_icon {
    max-width: 135px;
    width: 100%;
    font-size: 14px;

    span {
      svg {
        font-size: 23px;
        width: 23px;
      }
    }
  }

  .createdevents {
    .created_eve {
      padding: 27px 22px;

      .circle_1 {
        max-width: 175px;
        width: 100%;
        height: 175px;
      }

      .circle_2 {
        max-width: 137px;
        width: 100%;
        height: 137px;
      }
    }
  }

  .viewdetails,
  .vw-details {
    div {
      width: 139px;

      svg {
        width: 18px;
      }
    }
  }

  .par_upcoming {
    margin-top: 15px;
  }

  .par_past {
    margin-top: 4rem;
    margin-bottom: 50px;
  }

  .datewise {
    .future_box {
      .action_sec {
        display: flex;
        justify-content: space-between;

        div.left {
          h6 {
            font-size: 15px;
          }

          svg {
            width: 23px;
            fill: gray;
          }
        }

        .vw-details {
          display: flex;
          flex-direction: column-reverse;

          div {
            font-size: $font16;
          }

          svg {
            width: 20px;
            margin-left: 10px;
            top: 4px;
          }
        }
      }
    }
  }

  .earning {
    padding: 14px;
    margin-top: 0;

    h5 {
      font-size: $font18;
    }

    p {
      font-size: $font18;
    }

    span {
      font-size: $font18;
    }

    h4 {
      font-size: $font24;
    }

    .floatr {
      svg {
        width: 56px;
        height: 56px;
      }
    }
  }

  .notifications {
    .circle_light {
      width: 200px;
      height: 200px;
    }

    svg {
      width: 20px;
    }

    ul {
      li {
        .left_bx {
          width: 63px;
          height: 63px;

          svg {
            width: 31px;
          }
        }
      }
    }

    .no_icon {
      width: 23px;
      height: 23px;
    }
  }

  .ant-tooltip.ant-tooltip-placement-right {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      font-size: 14px;
    }
  }

  .ant-tooltip-placement-left {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      font-size: 14px;
    }
  }

  .crew_menu {
    max-width: 300px;
    width: 100%;
    min-width: 160px;
  }

  .live_event {
    ul.slick-dots {
      li {
        button {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .upcoming-events {
    margin-top: 10px;
  }

  .ant-col-xl-15 {
    width: 100%;
    max-width: 75%;
  }

  .ant-col-xxl-18 {
    width: 100%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ongoing-events__quick-information {
    margin-top: -40px;
  }
}

@media (max-width: 1200px) {
  #sidebar,
  .places,
  .admin-logout,
  .admin-recruit,
  .right_info {
    display: none !important;
  }

  .pl30 {
    padding-left: 0;
  }

  .notifications ul li {
    margin-bottom: 35px;
    width: 100%;
    flex-wrap: wrap;
    display: block;
    float: left;
    margin: 10px 0;
  }

  .parent_sec .main-container {
    padding: 15px;
  }

  .par_ongoingsec {
    margin-bottom: 20px;
  }

  .earning {
    margin-top: 0;
  }
}

@media (max-width: $tablet-max) {
  .datewise .future_box .action_sec {
    display: block;
  }

  .date_sec {
    width: 100%;
  }

  .viewdetails,
  .vw-details {
    width: 100%;
  }

  .par_earn_notify {
    width: 100%;
    margin-bottom: 30px;
    padding-left: 0 !important;
  }

  .candidate_dashboard .par_earn_notify {
    margin-bottom: 120px;
  }

  .event_analytic ul li {
    margin-bottom: 20px;
  }

  .ongoing-events__mobile {
    display: flex;
    flex-direction: column-reverse;

    .live_event {
      height: 245px;
    }
  }

  .par_ongoingsec {
    height: auto;
  }

  .par_ongoingsec .ongoing_evnt .text_sec {
    padding-left: 10px;
    padding-right: 10px;

    h5 {
      font-weight: $semi-bold;
    }

    h4 {
      font-weight: $semi-bold;
    }
  }

  .par_ongoingsec .ongoing_evnt .text_sec h5::before {
    content: '';
    width: 9px;
    height: 30px;
  }

  .event-date-type--left h6 {
    svg {
      width: 19px;
      height: 19px;
    }
  }

  .par_ongoingsec .ongoing_evnt .text_sec .candidate ul {
    display: flex;

    li {
      b {
        font-weight: $semi-bold;
      }
    }
  }

  .viewdetails div {
    span {
      justify-content: center;
      align-items: center;

      svg {
        width: 12px;
        height: 10px;
        margin-left: 0;
        position: relative;
        top: 0;
      }
    }
  }

  .par_ongoingsec {
    box-shadow: none;
    margin-bottom: 0;
  }

  .analytics__mobile,
  .dashboard-earning-notification__mobile {
    padding-left: 0 !important;
    margin-top: 20px;
  }

  .candidate_dashboard .dashboard-earning-notification__mobile {
    padding-top: 0 !important;
  }

  .upcoming-events {
    display: flex;
    flex-direction: column-reverse;
  }

  .recruiter_dashboard,
  .candidate_dashboard,
  .organizer_dashboard {
    margin-top: -30px;
  }

  div.parent_sec div.main-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ant-col-xs-24 {
    max-width: 100% !important;
  }
}

.analytics-item {
  margin-bottom: 33px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: $desktop-min) {
    margin-bottom: 23px;

    .left {
      width: 63px;
      height: 63px;

      svg {
        width: 35px;
      }
    }

    .right {
      p {
        font-size: $font16;
      }

      b {
        font-size: $font18;
      }
    }
  }

  @media (max-width: $mobile) {
    margin-bottom: 23px;

    .left {
      width: 45px;
      height: 45px;

      svg {
        width: 28px;
      }
    }

    .right {
      p {
        font-size: $font13;
        font-weight: $medium;
      }

      b {
        font-size: $font14;
        font-weight: $semi-bold;
      }
    }
  }
  .left {
    width: 45px;
    height: 45px;

    svg {
      width: 25px;
    }
  }

  .right {
    p {
      font-size: $font12;
    }

    b {
      font-size: $font12;
    }
  }

  .event-analytics__container__wrapper--icon--left {
    width: 45px;
    height: 45px;

    img {
      width: 25px;
    }

    svg {
      fill: $white;
      width: 25px;
    }
  }

  .event-analytics__container__wrapper__columns--right {
    p {
      font-size: $font12;
    }

    b {
      font-size: $font12;
    }
  }

  @media (min-width: $desktop-min) {
    margin-bottom: 23px;

    .event-analytics__container__wrapper--icon--left {
      width: 63px;
      height: 63px;

      img {
        width: 35px;
      }

      svg {
        width: 35px;
      }
    }

    .event-analytics__container__wrapper__columns--right {
      & > p,
      & > b {
        font-size: $font16;
      }
    }
  }

  @media (max-width: $mobile) {
    margin-bottom: 23px;

    .event-analytics__container__wrapper--icon--left {
      width: 45px;
      height: 45px;

      img {
        width: 28px;
      }

      svg {
        width: 28px;
      }
    }

    .event-analytics__container__wrapper__columns--right {
      p {
        font-size: $font13;
        font-weight: $medium;
      }

      b {
        font-size: $font14;
        font-weight: $semi-bold;
      }
    }
  }
}

.ant-carousel .slick-dots {
  position: absolute;
  right: 20px;
  bottom: 40px;
  left: 50%;
  z-index: 15;
  width: 30%;
  display: flexbox !important;
  display: flex !important;
  flex-pack: center;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;

  @media (max-width: $tablet-max) {
    bottom: unset;
    top: 200px;
    right: unset;
    left: 50%;
  }

  li,
  li.slick-active {
    width: 10px;
    height: 10px;

    @media (min-width: $desktop-min) {
      width: 14px;
      height: 14px;

      button {
        width: 14px;
        height: 14px;
        border-radius: 100%;
      }
    }

    button {
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }
}
.par_ongoingsec .ongoing_evnt .text_sec .candidate ul li {
  p.ongoing-analytics__name {
    @media (min-width: 1200px) and (max-width: 1280px) {
      @include small-font-mixin();
    }
  }
}
.resume-download-link {
  margin: 5px 10px;
}

.password-set-helper-text {
  margin-top: -15px;
  margin-bottom: 10px;
  text-align: start;
  color: $gray;
}

.analytics-item-hyperlink {
  &:hover {
    cursor: pointer;
  }
}

.dashboard-header {
  @include display-flex(row);

  justify-content: flex-end;

  @media (max-width: $laptop-min) {
    margin-top: -62px;
    margin-left: 15px;
  }

  button {
    margin: 22px 15px;

    @media (max-width: $tablet-max) {
      display: none;
    }

    span {
      font-size: $font15;

      @media (max-width: $laptop-max) {
        font-size: $font14;
      }
    }
  }
}
// explore-similar-events
.explore-similar-events {
  margin-top: 25px;

  h5.similar-events {
    color: $primary-color;
    line-height: 21px;
    margin-bottom: 30px;
    position: relative;
    padding-left: 22px;
    font-weight: $semi-bold;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      background-color: $primary-color;
    }
  }
}

// ongoing-events
.ongoing-events__heading {
  margin: 5px 0px;

  h5.ongoing-section-title {
    color: $primary-color;
    font-weight: $semi-bold;

    &::before {
      background-color: $primary-color;
    }
  }

  h5.recruiter-ongoing {
    color: $recruiter-color;
    &::before {
      background-color: $recruiter-color;
    }
  }

  h5.candidate-ongoing {
    color: $candidate-color;
    &::before {
      background-color: $candidate-color;
    }
  }
}

.dashboard-analytics--left {
  margin-top: 30px;
  @media (max-width: $mobile) {
    margin-top: 15px;
  }
}
