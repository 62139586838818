.content__box {
  box-sizing: content-box;
}

.circle__background--top-left {
  background: $recruiter-color;
  width: 250px;
  border-radius: 50%;
  height: 250px;
  opacity: 0.2;
  position: absolute;
  left: -124px;
  top: -100px;
}

.recruitment {
  &__header {
    color: $custom-orange;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__responsive__hidden {
    margin-left: 5px;
  }
}

.recruitment__header {
  font-weight: $semi-bold;
  padding: 10px 0;
}

.recruitment__tab {
  box-shadow: $shadow;

  &__container {
    width: 100%;
    z-index: 1;
    position: relative;
    background: $white;
    padding: 20px;

    .ant-tabs-tab {
      color: $very-light-grey;
      font-weight: $medium;
      outline: none;

      &.ant-tabs-tab-active {
        color: $recruiter-color;

        .ant-tabs-tab-btn {
          color: $recruiter-color;
        }
      }
    }

    .ant-tabs-ink-bar {
      width: 0;
      height: 2px;
      background-color: $recruiter-color;
    }
  }
}

.job-applications__selector {
  display: inline-block;
}

.filter {
  width: 30px;
  height: 30px;
  color: $white;
}

.job-application-analytics__container {
  background-color: $custom-orange;
  border-radius: $bradius;
  box-shadow: $shadow;
  overflow: hidden;
  position: relative;

  &-background-circle-1 {
    max-width: 130px;
    width: 100%;
    background: $dark-yellow;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    right: -7px;
    top: -51px;
    z-index: 1;
  }

  &-background-circle-2 {
    max-width: 85px;
    width: 100%;
    background: $dark-yellow;
    height: 85px;
    border-radius: 50%;
    position: absolute;
    right: -23px;
    top: 50px;
    z-index: 0;
  }
}

.recruit-evaluation-timer {
  color: $custom-orange;
  font-weight: $bold;
  cursor: pointer;

  &-text {
    color: $sub-header;
  }
}

.righttimer {
  margin: 0 0 0 auto;
  color: $gray;

  a {
    color: inherit;
  }
}

.recruit-table__scrollable {
  overflow-x: auto;
}

.job-application-analytics-details__container {
  @include medium-heading-mixin;
  background-color: $custom-orange;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;

  &__wrapper {
    color: $sheader;
    width: 100%;
    z-index: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 20px;
  }

  &__wrapper__columns {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $mobile) {
  .header-Select {
    display: flex;
    margin: 3px;
    margin-right: 10px;
    margin-bottom: 20px;
    width: 400px;
  }

  .job-application-analytics__container {
    &-background-circle-1 {
      max-width: 200px;
      width: 100%;
      background: $dark-yellow;
      height: 200px;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -51px;
      z-index: 1;
    }

    &-background-circle-1 {
      max-width: 120px;
      width: 100%;
      background: $dark-yellow;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      right: -23px;
      top: 70px;
      z-index: 0;
    }
  }
}
.job-application-analytics-details__container {
  background-color: $custom-orange;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;

  &__wrapper {
    color: $sheader;
    width: 100%;
    z-index: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 20px;
  }

  &__wrapper__columns {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 15px;
  }
}

.job-application-analytics-details {
  &__container__wrapper--icon--left {
    width: 45px;
    height: 45px;
    background-color: $dark-yellow;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 15px;
  }

  &__container__wrapper__columns--right {
    color: $white;
    display: flex;
    height: 45px;
    flex-direction: column;
    justify-content: center;
  }

  &__container__wrapper__columns__text--right {
    @include small-heading-mixin;
    font-weight: $semi-bold;
  }
}

.filter {
  width: 30px;
  height: 30px;
  color: $white;
}

.header-select {
  margin-bottom: 15px;
  &-jobs {
    margin-left: 40px;
    color: $sub-header;
    font-weight: $regular;

    @media (max-width: $mobile) {
      margin-left: 0;
    }
  }

  &-jobs-text {
    @include small-heading-mixin;
    color: $header;
    font-weight: $medium;
    margin-bottom: 4px;
  }

  &-event {
    color: $sub-header;
    margin-left: 10px;

    .event__select {
      .ant-select-selection-item {
        font-size: $font14;
        font-weight: $regular;
        color: $gray;
      }
    }
  }

  &-event-text {
    @include small-heading-mixin;
    color: $header;
    font-weight: $medium;
    margin-bottom: 5px;
  }
}

.job-application-analytics__container {
  background-color: $custom-orange;
  border-radius: $bradius;
  box-shadow: $shadow;
  overflow: hidden;
  position: relative;

  &-background-circle-1 {
    max-width: 130px;
    width: 100%;
    background: $dark-yellow;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    right: -7px;
    top: -51px;
    z-index: 1;
  }

  &-background-circle-2 {
    max-width: 85px;
    width: 100%;
    background: $dark-yellow;
    height: 85px;
    border-radius: 50%;
    position: absolute;
    right: -23px;
    top: 50px;
    z-index: 0;
  }
}

.job-types__select {
  @include small-font-mixin;

  height: max-content;
  font-weight: $regular;
}

.recruit-evaluation-timer {
  &-text {
    @include medium-heading-mixin;

    color: $sub-header;
    font-weight: $medium;
  }
  color: $custom-orange;
  font-weight: $bold;
  cursor: pointer;
}

.righttimer {
  margin: 0 0 0 auto;
  color: $gray;
}

.view__details-btn {
  display: flex;
  width: fit-content;
  background-color: transparent;
  border: 1px solid $recruiter-color;
  color: $recruiter-color;
  padding: 5px 26px;

  &:hover {
    border: 1px solid $recruiter-color;
    color: $sub-header;
  }

  span {
    font-size: $font12;
  }
}

.select-jobs__badge {
  .ant-badge-count {
    background-color: $dark-yellow;
    color: $header;
  }
}

@media (max-width: $mobile) {
  .job-application-analytics__container {
    &-background-circle-1 {
      max-width: 200px;
      width: 100%;
      background: $dark-yellow;
      height: 200px;
      border-radius: 50%;
      position: absolute;
      right: -7px;
      top: -51px;
      z-index: 1;
    }

    &-background-circle-1 {
      max-width: 120px;
      width: 100%;
      background: $dark-yellow;
      height: 120px;
      border-radius: 50%;
      position: absolute;
      right: -23px;
      top: 70px;
      z-index: 0;
    }
  }

  .header-select {
    &-event-text {
      color: $sub-header;
      margin: 1px;
    }
  }

  .filter_events {
    margin-top: 10px;
  }

  .recruit-evaluation-timer {
    color: $custom-orange;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;

    &-text {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .view__details-btn {
    display: flex;
    width: fit-content;
    background-color: transparent;
    border: 1px solid $recruiter-color;
    color: $recruiter-color;
    // padding-bottom: 25px;

    &:hover {
      border: 1px solid $recruiter-color;
      color: $sub-header;
    }

    span {
      font-size: $font12;
    }
  }

  .job-types__select {
    max-height: 100px;
    margin-top: 2px;
  }

  .header-select-jobs-text {
    color: $sub-header;
    margin-left: 1px;
  }

  .ant-table-cell {
    overflow-x: scroll;
  }
}

@media (min-width: $desktop-min) {
  .job-types__select {
    margin-left: 4px;
    height: max-content;
    margin-top: 2px;
  }

  .job-application-analytics-details__container {
    @include medium-heading-mixin;

    background-color: $custom-orange;
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;

    &__wrapper {
      font-weight: $bold;
      color: $sheader;
      width: 100%;
      z-index: 1;
      position: relative;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding-bottom: 20px;
    }

    &__wrapper__columns {
      margin-top: 30px;
      margin-bottom: 10px;
      margin-left: 30px;
    }
  }

  .job-application-analytics-details {
    &__container__wrapper--icon--left {
      width: 63px;
      height: 63px;
      background-color: $dark-yellow;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      margin-right: 15px;
    }

    &__container__wrapper__columns--right {
      color: $white;
      display: flex;
      height: 63px;
      flex-direction: column;
      justify-content: center;
    }

    &__container__wrapper__columns__text--right {
      @include medium-heading-mixin;
      font-weight: bold;
    }
  }

  .view__details-btn {
    background-color: transparent;
    border: 1px solid $recruiter-color;
    color: $recruiter-color;

    &:hover {
      border: 2px solid $recruiter-color;
      color: $sub-header;
    }
    span {
      font-size: $font14;
    }
  }

  .header-select {
    margin-bottom: 20px;
    &-jobs {
      margin-left: 40px;
    }
    &-jobs-text {
      @include medium-heading-mixin;

      color: $sub-header;
    }

    &-event {
      margin: 1px;
      margin-right: 5px;
    }
    &-event-text {
      @include medium-heading-mixin;

      color: $sub-header;
    }
  }
}
