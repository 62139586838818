.groupChat {
  &__container {
    box-shadow: $shadow;
    z-index: 1;
    padding: 20px;
    position: relative;
    background: #fff;
    margin-top: 20px;

    @media (max-width: $mobile) {
      padding: 0;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
  }

  &__header {
    @include display-flex(row);

    align-items: center;
    justify-content: space-between;
    padding-right: 20px;

    @media (max-width: $mobile) {
      display: none;
    }

    h3 {
      @include medium-heading-mixin;

      font-weight: $semi-bold;
      margin-bottom: 0;
    }

    .form-group.search_input input {
      background-color: $white;
    }
  }

  &__body {
    @include display-flex(column);

    height: 80vh;
    margin-top: 15px;

    @media (max-width: $mobile) {
      height: 82vh;
      margin-top: 0;
    }

    &__heading {
      @include display-flex(row);

      justify-content: space-between;
      height: 12%;
      border-bottom: 1px solid $very-light-grey-3;

      @media (max-width: $mobile) {
        height: 80px;
      }

      &__details {
        @include display-flex(row);

        flex-grow: 1;
        justify-content: space-between;

        @media (max-width: $mobile) {
          @include display-flex(column);

          flex-grow: 1;
          padding: 15px 10px;
        }
      }

      &--left {
        @include display-flex(row);

        &__color {
          background-color: $recruiter-color;
          width: 8px;
          height: 100%;
          margin-right: 10px;

          @media (max-width: $mobile) {
            margin-right: 5px;
          }
        }

        span {
          @include display-flex(row);
          @include medium-heading-mixin;

          margin-left: 15px;
          align-items: center;
          align-self: center;
          color: $tgtextcolor;
          cursor: pointer;

          @media (max-width: $mobile) {
            margin-left: 0;
          }

          p {
            @include medium-font-mixin;

            margin-right: 5px;
            font-weight: $bold;

            @media (min-width: $desktop-min) {
              @include large-font-mixin;
            }
          }

          svg {
            font-size: percentage($number: 1.6);

            @media (max-width: $mobile) {
              font-size: percentage($number: 1.2);
            }
          }
        }
      }

      &--right {
        @include display-flex(row);

        align-items: center;
        margin-right: 20px;

        h3 {
          @include small-heading-mixin;

          color: $sub-header;
          margin: 0;
          margin-right: 30px;

          @media (min-width: $desktop-min) {
            @include medium-heading-mixin;
          }

          @media (max-width: $mobile) {
            margin-right: 15px;
          }
        }

        p {
          @include small-heading-mixin;

          margin-right: 20px;
          font-weight: $semi-bold;

          @media (min-width: $desktop-min) {
            @include medium-heading-mixin;
          }
        }
      }

      .side-menu {
        @media (max-width: $mobile) {
          padding-top: 20px;
          flex-basis: 5px;
        }
      }
    }

    &__thread {
      &__container {
        @include display-flex(column-reverse);

        overflow: scroll;
        overflow-x: hidden;
        flex-grow: 1;

        &::-webkit-scrollbar {
          display: none;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $icon_color;
          border-radius: 10px;
          min-height: 30px;
          max-height: 30px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $gray-3;
        }
      }

      &__card {
        @include display-flex(row);

        margin-top: 18px;
        margin-right: 10px;
        border-bottom: 1px solid $very-light-grey-3;

        @media (max-width: $mobile) {
          padding-top: 20px;
          margin-top: 10px;
          margin-right: 0;
          background-color: $main-background;
          justify-content: space-between;
        }

        &--left {
          @include display-flex(row);

          width: 88%;

          @media (max-width: $mobile) {
            width: 65%;
          }

          .userType {
            width: 8px;
            height: 50px;
            margin-right: 10px;

            @media (max-width: $mobile) {
              height: 80px;
              margin-top: -20px;
            }
          }

          &__profile {
            @include display-flex(row);

            width: fit-content;
          }

          &__details {
            @include display-flex(column);

            margin-left: 15px;
            width: 90%;

            > p {
              @include small-font-mixin;

              color: $tgtextcolor;
              font-weight: $medium;
              padding-bottom: 20px;
              word-break: break-all;
              padding-right: 20px;

              @media (min-width: $desktop-min) {
                @include medium-font-mixin;
              }

              @media (max-width: $mobile) {
                padding-top: 15px;
                margin-left: -55px;
                width: 180%;
              }

              @media (max-width: $mobile-special-case-1) {
                width: 190%;
              }
            }

            span {
              @include display-flex(row);

              margin: 0 0 10px 0;
              align-items: center;

              @media (max-width: $mobile) {
                @include display-flex(column);

                align-items: flex-start;
              }

              h5 {
                @include small-font-mixin;

                margin: 0;
                color: $black-text;
                font-weight: $semi-bold;

                @media (min-width: $desktop-min) {
                  @include medium-font-mixin;
                }

                @media (max-width: $mobile) {
                  @include medium-font-mixin;

                  margin-bottom: 5px;
                }
              }

              p {
                @include small-font-mixin;

                margin-left: 20px;
                color: $tgtextcolor;
                font-weight: $semi-bold;

                @media (max-width: $mobile) {
                  margin-left: 0;
                }
              }
            }
          }
        }

        &--right {
          @include display-flex(row);

          width: 12%;
          justify-content: flex-end;

          @media (max-width: $mobile) {
            width: 30%;
          }

          h6 {
            @include small-font-mixin;

            color: $gray-2;
            margin-right: 20px;

            @media (min-width: $desktop-min) {
              @include medium-font-mixin;
            }

            @media (max-width: $mobile) {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
