@import '../../variables.scss';

.events-analytics {
  &__title {
    color: $candidate-color;
  }

  &__subtitle {
    color: $label;
  }

  &__information {
    display: flex;
    justify-content: space-between;
    max-width: 60%;
    flex-wrap: wrap;
    gap: 10px;

    &__job {
      white-space: nowrap;
      display: flex;

      @media (max-width: $mobile) {
        flex-direction: column;
      }

      div {
        margin-left: 10px;
        width: 250px;

        @media (max-width: $mobile) {
          margin-left: 0;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__wrapper {
    margin: 10px 0;
    max-width: 100%;
    display: flex;
    justify-content: space-around;
    border-radius: 8px;
    padding: 25px 30px;
    background: linear-gradient(
      to right,
      $candidate-color,
      $candidate-color-light-shade
    );

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    .image-wrapper {
      padding: 8px;
      border-radius: 8px;
      background: linear-gradient(
        to bottom,
        $candidate-color-light-shade,
        $candidate-color
      );

      img {
        width: 30px;
        filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(94deg)
          brightness(89%) contrast(118%);
      }
    }

    &__card {
      display: flex;

      @media (max-width: $mobile) {
        &:not(:last-child) {
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }

      p {
        color: $white;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }

  &__more-information {
    max-width: 50%;
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    p {
      margin: 8px 0;
      @media (max-width: $mobile) {
        margin: 10px 0;
      }
    }
  }
}

%analytics-button {
  border: none;
  color: $white;
  border-radius: 5px;
}

.view-analytics-button {
  @extend %analytics-button;

  background-color: $candidate-color;
}

.recruiter__view-analytics-button {
  @extend %analytics-button;

  background-color: $recruiter-color;
  padding: 5.8px 15px;
  cursor: pointer;
  margin-left: 0px;
  min-width: 160px;

  @media (min-width: $desktop-min) {
    min-width: 180px;
  }

  @media (max-width: $mobile) {
    min-width: 120px;
    margin-left: 0;
  }
}

.recruiter.events-analytics {
  h1 {
    color: $recruiter-color;
  }

  p {
    color: white;
  }

  & p.headings {
    color: black;
  }

  &__wrapper {
    background: linear-gradient(to right, $recruiter-color, $recruiter-shadow);

    .image-wrapper {
      background: linear-gradient(
        to bottom,
        $recruiter-shadow,
        $recruiter-color
      );

      img {
        filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(94deg)
          brightness(89%) contrast(118%);
      }
    }
  }
}
