.interview-slots {
  display: none;
  width: 280px;
  height: 200px;
  background-color: $white;
  color: $black;
  border-radius: 6px;
  padding: 10px;
  overflow-y: auto;
  /* Position the tooltip */
  position: absolute;
  top: -175px;
  z-index: 99;
  box-shadow: 0 0 2px $black;

  &__container {
    border-bottom: 1px solid $gray-2;
    padding: 5px 0px;
    margin-bottom: 5px;
    &__circle {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-top: 5px;
    }
    &__dateTime {
      color: $gray-2;
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-left: 8px;
        width: 15px;
        height: 15px;
        fill: $gray-2;
      }
    }
    &--right {
      margin-left: 10px;
    }
  }
}
.calendar-item-list:hover .interview-slots {
  display: block;
}

.candidate_calendar {
  .interview-slots {
    &__container {
      &__circle {
        background-color: $candidate-color;
      }
      &__name {
        color: $candidate-color;
      }
    }
  }
}

.recruiter_calendar {
  .interview-slots {
    &__container {
      &__circle {
        background-color: $recruiter-color;
      }
      &__name {
        color: $recruiter-color;
      }
    }
  }
}
