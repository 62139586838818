.plain-container {
  .job-details {
    h3 {
      @include large-font-mixin;

      color: $sub-header;
      font-weight: $medium;
    }

    p {
      @include large-font-mixin;

      color: $label;
    }

    .title {
      margin-bottom: 10px;
    }

    .status-and-date {
      display: flex;
      justify-content: flex-end;
      margin-top: -40px;

      .date {
        padding-left: 60px;
      }
    }

    .designation,
    .description,
    .required-skills,
    .other-details {
      padding-bottom: 20px;
    }

    .required-skills {
      .wrapper {
        display: flex;
        align-items: center;

        .skills {
          width: 250px;

          .skill-name {
            @include large-font-mixin;

            margin-bottom: 10px;
          }
        }
        .skill-level {
          width: 200px;
          div {
            @include large-font-mixin;
            color: $bluecolor;
            font-weight: $medium;
            margin-bottom: 10px;
          }
        }
      }
    }

    .other-details {
      row-gap: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .minimum-work-experience {
        flex: 1 33%;
      }

      .minimum-qualification {
        flex: 1 33%;
        align-self: flex-end;
        margin-top: 3px;

        @media (min-width: $desktop-min) {
          margin-top: 7px;
        }
      }

      .salary-range {
        flex: 1 33%;
      }

      .job-type {
        flex: 1 25%;
      }

      .no-of-positions {
        flex: 1 25%;
        align-self: flex-end;
      }

      .location {
        flex: 1 25%;
      }

      .job-type {
        .type {
          span {
            @include large-font-mixin;

            background-color: $very-light-grey-2;
            padding: 5px 10px;
            margin: 0px 5px;
            border-radius: 3px;
          }
        }
      }

      .tags {
        flex: 1 100%;

        .name {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          span {
            background-color: $lightbluecolor;
            color: $bluecolor;
            margin-right: 5px;
            padding: 0 15px;
            border-radius: 3px;
            border: 1px solid $bluecolor;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .application-overview__container {
    @include display-flex(row);
    justify-content: space-between;

    .title {
      @include large-font-mixin;

      padding: 10px;
      background-color: $very-light-grey-2;
      width: 100%;
      font-weight: $medium;
    }

    .name {
      @include large-font-mixin;
      padding: 10px;
      color: $sub-header;
      font-weight: $medium;
    }

    .company-name {
      flex: 1;
    }

    .application-received {
      flex: 1.2;
    }

    .accepted {
      flex: 1;
    }

    .on-hold {
      flex: 1;
    }

    .selected {
      flex: 0.5;
    }
  }

  .view-detail {
    @include display-flex(row);

    justify-content: center;
    padding: 15px 0;
    button {
      border: none;
      padding: 5px 35px;
      background-color: $bluecolor;
      color: $white;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

.job-information__title {
  font-weight: $semi-bold;
}

.edit-job-modal__header {
  color: $bluecolor;
  font-weight: bold;
}

.jobs__event-filter {
  min-width: 250px;
  margin-left: 10px;
}
