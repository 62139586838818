.circle_box.cir_sml {
  left: -156px;
  top: -165px;
  background-color: $primary-color;
}

.title.pro_title {
  margin-top: 28px;

  h3 {
    @include extra-large-heading-mixin;
    font-weight: $bold;
    color: $header;
    margin-bottom: 8px;
  }

  p {
    @include section-heading-mixin;
    font-weight: $light;
    color: $sub-header;
    padding-left: 0px;
  }
}

div.pro_steps.ant-steps {
  max-width: 80%;
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 36px;

  .ant-steps-item-title {
    @include medium-large-heading-mixin;
    color: $titlefr_color;
    font-weight: $medium;
    margin-bottom: 10px;
  }

  .ant-steps-item-description {
    max-width: unset;
    @include small-font-mixin;
    color: $sub-header;
    font-weight: $medium;
  }
}

.pro_steps_content {
  box-shadow: $shadow2;
  padding: 35px 30px 30px;
  margin-bottom: 40px;

  h3.content_title {
    @include large-heading-mixin;
    color: $primary-color;
    font-weight: $semi-bold;
    line-height: 21px;
  }

  .content_subtitle,
  .green-header {
    @include small-heading-mixin;
    color: $primary-color;
    font-weight: $semi-bold;
    line-height: 19px;
    margin-bottom: 22px;
  }

  label,
  .info-header {
    font-weight: $regular;
    color: $label;
    margin-bottom: 16px;
    display: block;
  }

  .label-small-heading {
    @include small-heading-mixin;
  }

  .share_txt {
    color: $gray;
    @include large-font-mixin;
  }
  .ant-input-text {
    @include large-font-mixin;
    color: $label;
  }

  .ant-select-selector,
  .ant-input,
  .ant-input-number-input {
    align-items: center;
    width: 100%;
    color: $black;
  }

  .add_propic {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: -64px;
    flex-direction: column;
    align-items: center;

    .ant-upload-select-text {
      span {
        font-size: $font14;
        @media (max-width: $laptop-special-case-1) {
          font-size: $font10;
        }
      }
    }

    div.pro_div {
      span {
        width: 120px;
        height: 120px;

        img {
          background: $very-light-grey-4;
          border-radius: 50%;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .txt_upload {
      @include large-font-mixin;
      color: $sub-header;
      text-align: center;
      border: none;
    }

    .upload-image-hint-text {
      font-size: $font12;
      width: 100px;

      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  .add-detail {
    color: $primary-color;
    @include medium-heading-mixin;
    font-weight: $semi-bold;
    border: none;
    margin-bottom: 80px;
  }

  .add-detail.profile-onboard {
    margin-left: -13px;
    margin-bottom: 20px;

    @media (min-width: $mobile) {
      margin-left: -20px;
    }

    span {
      @include large-font-mixin;
    }
  }

  .addlink {
    margin-top: 40px;

    .add-profile-link {
      width: 100%;
      height: 42px;
      max-width: 412px;
      margin-bottom: 10px;
    }

    .addbutn {
      button {
        border: none;
        background-color: $white;
        line-height: 44px;

        svg {
          fill: $gray-4;
          width: 20px;
          height: 20px;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .profile-ad-link {
    width: 100%;
    height: 42px;
    max-width: 412px;
    margin-bottom: 10px;
  }

  .clck_upload {
    height: 42px;
  }

  .upload_sec {
    display: flex;
    p {
      margin-top: 2rem;
      @include medium-font-mixin;
      margin-left: 3rem;
      color: $gray;
    }
    .hint_text {
      margin-top: 20px;
    }
  }

  .c_innerbox {
    padding-left: 27px;
  }

  .invite_sec {
    display: flex;
    display: flex;
    align-items: center;
    p {
      @include medium-font-mixin;
      padding-left: 20px;
      color: $gray-3;
    }
  }

  .c_innerbox {
    padding-left: 27px;
  }

  .invite_sec {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .invited {
      @include large-font-mixin;
      color: $primary-color;
      font-weight: $semi-bold;
    }

    .invite {
      @include medium-heading-mixin;
      color: $white;
      font-weight: $semi-bold;
      background-color: $primary-color;
      max-width: 113px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 3px;
    }
  }

  .span_addtext {
    @include medium-font-mixin;
    color: $gray;
    padding-top: 9px;
    font-weight: 400;
    display: block;
  }

  .span_col {
    padding-top: 7px;

    .span_text1 {
      color: $primary-color;
      font-weight: 500;
    }
  }

  .span_text2 {
    color: $gray;
    padding-left: 20px;
  }

  .add-skill-button {
    width: 100%;
    max-width: 300px;
    color: $placeholder;
    height: 42px;
    margin-bottom: 30px;
  }

  .skill-set {
    .minus-circle {
      svg {
        @include large-heading-mixin;
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-title {
        color: $primary-color !important;
      }

      .ant-steps-item-icon {
        border: 3px solid $primary-color;
        color: $primary-color;
        z-index: 11;
        position: relative;
      }

      .ant-steps-item-icon {
        .ant-steps-icon {
          color: $primary-color;

          svg {
            fill: $primary-color;
          }
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        z-index: 11;
        position: relative;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        border: 3px solid $primary-color;
        z-index: 11;
        position: relative;
      }

      svg {
        fill: $primary-color;
      }

      .ant-steps-item-tail {
        &::after {
          background-color: $primary-color;
        }
      }

      .ant-steps-item-title {
        color: $primary-color !important;
      }
    }
  }

  .skill-remove-column {
    .minus-circle {
      color: $custom-red;
      margin-left: 20px;
    }
  }

  .custom_skilline {
    margin-bottom: 30px;

    .ant-col.skill-value-column {
      max-width: 630px;
      width: 100%;
      margin-left: -20px;
    }

    .ant-steps-label-vertical .ant-steps-item-tail {
      padding: 0 10px;
    }

    .ant-steps-small .ant-steps-item-tail {
      top: -3px;

      &::after {
        height: 3px;
      }
    }
  }
}

.job-profile {
  .custom_skilline {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $laptop-special-case-1) {
      grid-template-columns: 1fr;
    }

    .skill-remove-column {
      @media (max-width: $laptop-special-case-1) {
        display: none;
      }
    }

    .remove-skill-mobile {
      display: none;
      color: $custom-red;
      font-weight: $medium;

      @media (max-width: $laptop-special-case-1) {
        display: block;
        margin: 20px 0;
      }
    }

    .ant-steps-item {
      @media (max-width: $mobile-special-case-1) {
        width: 95px;
      }
    }
  }
}

.bot_box_mob {
  display: none;
}

.steps-action {
  margin-top: 34px;
  .nxt_btn {
    width: 100%;
    height: 43px;
    background-color: $primary-color;
    color: $white;
    @include large-font-mixin;
    line-height: 27px;
    max-width: 168px;
    border: none;
    cursor: pointer;
  }

  .pre_btn {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: $white;
  }

  .sve_con {
    max-width: 195px;
    width: 100%;
  }
}

$mobile: 767px;

@media (min-width: $mobile) {
  .mb40m {
    margin-bottom: -40px;
  }
}

.name {
  flex: 1 0 25%;
}

.phone {
  flex: 1 0 25%;
}

.email {
  flex: 1 0 25%;
}

.pic {
  flex: 1 0 25%;
}

.mb40m {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

@media (max-width: $mobile) {
  .bot_box_mob {
    width: 109%;
    height: 101px;
    display: block;
    background-color: $custom-green;
    height: 101px;
    width: 109%;
    position: absolute;
    margin-top: -81px;
    left: 0;
    margin: 0 -15px;
    right: -34px;
  }

  #header {
    box-shadow: $shadow3;
  }

  #header div.logo img {
    max-width: 106px;
  }

  #header.top-bar {
    padding: 15px 0 15px 0;
  }

  #header ul.right_menu li.admin-notify a span.img_frnt svg {
    width: 22px;
    margin-right: 0;
  }

  #header ul.right_menu li.admin-notify a span.img_frnt::after {
    left: 11px;
    top: 0;
  }

  .crew-header {
    .ant-select-selection-item {
      @include medium-font-mixin;
    }
  }

  div.pro_steps.ant-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;

    .ant-steps-item-icon {
      margin: 0 16px 0 0;
      width: 25px;
      height: 25px;
      line-height: 20px;

      .ant-steps-icon {
        @include small-font-mixin;
        svg {
          @include small-font-mixin;
        }
      }
    }

    .ant-steps-item-title {
      @include small-font-mixin;
      margin-bottom: 10px;
    }

    .ant-steps-item-description {
      display: none;
    }

    .ant-steps-item-title::after {
      background-color: $tgtextcolor !important;
      opacity: 0.4;
    }

    .ant-steps-item-finish,
    .ant-steps-item-active {
      .ant-steps-item-icon {
        background: $primary-color;
        border-color: $primary-color;

        .ant-steps-icon {
          svg {
            fill: $white;
          }
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background: $white;
        border-color: $primary-color;

        .ant-steps-icon {
          color: $primary-color;

          svg {
            fill: $primary-color;
          }
        }
      }
    }
  }

  .mob_hide,
  #header .logo::after,
  .ant-menu-item a::before {
    display: none;
  }

  div.pro_steps.ant-steps {
    display: none;
  }

  .title.pro_title {
    margin-top: 82px;

    @media (max-width: $mobile) {
      margin-top: 20px;
    }

    h3 {
      margin-bottom: 0px;
    }

    p {
      @include small-font-mixin;
      padding-left: 0px;
    }
  }

  .main-container {
    overflow: hidden;
  }

  .div.pro_steps.ant-steps {
    margin-top: 22px;
    flex-direction: row;
  }

  .pro_steps_content {
    margin-top: 20px;
    padding: 14px 13px 30px;
    margin-bottom: 0;
    z-index: 11;
    position: relative;
    background: $white;
    h3.content_title {
      @include large-font-mixin;
      margin-bottom: 30px;
    }

    .content_subtitle,
    .green-header {
      @include medium-font-mixin;
    }

    label,
    .info-header {
      color: $gray;
      margin-bottom: 12px;
    }

    .add_propic {
      position: absolute;
      top: 67px;
      max-width: 100px;
      right: 34px;

      img {
        width: 60px !important;
        height: 60px !important;
        margin-bottom: 0 !important;
      }

      .txt_upload span {
        @include small-font-mixin;
      }
    }

    .steps-action {
      .nxt_btn {
        height: 36px;
        margin-right: 0px;
        @include large-font-mixin;
        margin-top: 50px;
      }

      .sve_con {
        max-width: 167px;
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .clck_upload {
      height: 42px;
    }

    div.upload_sec {
      display: unset;

      p {
        @include small-font-mixin;
        padding-left: 0px;
        color: $gray-3;
        padding-top: 18px;
        font-weight: 400;
      }

      .clck_upload {
        display: flex;
        align-items: center;
        padding: 0 17px;

        .anticon {
          padding-left: 0;

          svg {
            @include large-heading-mixin;
            fill: $gray;
          }
        }

        span {
          @include small-font-mixin;
          padding-left: 10px;
          color: $gray;
        }
      }
    }

    .profile-ad-link {
      span {
        @include medium-font-mixin;
        color: $gray;
      }
    }
  }

  .mt51 {
    margin-top: 25px;
  }

  .m_relunset {
    position: unset;
  }

  .m_mt35 {
    margin-top: 25px;
  }

  .mb90 {
    margin-bottom: 0;
  }

  .m_pad0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .m_padlr21 {
    padding-right: 13px;
  }
  //invite button
  .pro_steps_content .invite_sec {
    .invite {
      @include medium-font-mixin;
      height: 36px;
    }
  }
}

.pro_steps {
  @media (max-width: $mobile) {
    display: none;
  }
}

// candiate-color
.candidate_board .pro_steps_content h3.content_title,
.candidate_board .pro_steps_content h3.content_title,
.candidate_board .pro_steps_content .content_subtitle,
.candidate_board .pro_steps_content .green-header,
.candidate_board
  div.pro_steps.ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  .ant-steps-icon,
.candidate_board .pro_steps_content .steps-action .pre_btn,
.candidate_board .pro_steps_content .span_text1,
.candidate_board .pro_steps_content .add-detail,
.candidate_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-icon,
.candidate_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-title,
.candidate_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-icon
  .ant-steps-icon {
  color: $candidate-color !important;
}

.candidate_board
  div.pro_steps.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon,
.candidate_board .pro_steps_content .steps-action .nxt_btn,
.candidate_board .circle_box.cir_sml,
.recruiter_board .bot_box_mob {
  background-color: $candidate-color;
}
.candidate_board
  div.pro_steps.ant-steps
  .ant-steps-item-active
  .ant-steps-item-icon {
  background-color: $white;
}

.candidate_board
  div.pro_steps.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon,
.candidate_board
  div.pro_steps.ant-steps
  .ant-steps-item-active
  .ant-steps-item-icon,
.candidate_board .pro_steps_content .steps-action .pre_btn,
.candidate_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-icon {
  border-color: $candidate-color;
}

.candidate_board .pro_steps_content .steps-action .pre_btn {
  background-color: $white;
}

// recruiter-
// candiate-color
.recruiter_board .pro_steps_content h3.content_title,
.recruiter_board .pro_steps_content h3.content_title,
.recruiter_board .pro_steps_content .content_subtitle,
.recruiter_board .pro_steps_content .green-header,
.recruiter_board .pro_steps_content .steps-action .pre_btn,
.recruiter_board .pro_steps_content .span_text1,
.recruiter_board .pro_steps_content .add-detail,
.recruiter_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-icon,
.recruiter_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-title,
.recruiter_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-icon
  .ant-steps-icon,
.recruiter_board .invited {
  color: $recruiter-color !important;
  svg {
    fill: $recruiter-color;
  }
}

.recruiter_board
  div.pro_steps.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon,
.recruiter_board .pro_steps_content .steps-action .nxt_btn,
.recruiter_board .circle_box.cir_sml,
.recruiter_board .pro_steps_content .invite_sec .invite,
.recruiter_board .bot_box_mob {
  background-color: $recruiter-color;
}

.recruiter_board
  div.pro_steps.ant-steps
  .ant-steps-item-active
  .ant-steps-item-icon {
  background-color: $white;
}

.recruiter_board
  div.pro_steps.ant-steps
  .ant-steps-item-finish
  .ant-steps-item-icon,
.recruiter_board
  div.pro_steps.ant-steps
  .ant-steps-item-active
  .ant-steps-item-icon,
.recruiter_board .pro_steps_content .steps-action .pre_btn,
.recruiter_board
  .pro_steps_content
  .skill-set
  .ant-steps-item-active
  .ant-steps-item-icon {
  border-color: $recruiter-color;
  border: 1px solid $recruiter-color;
}
div.pro_steps.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border: 1px solid $gray-2;
  span svg {
    fill: $gray-2 !important;
  }
}

.recruiter_board .pro_steps_content .steps-action .pre_btn {
  background-color: $white;
}
.recruiter_board {
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    svg {
      fill: $recruiter-color;
    }
  }
  div.pro_steps.ant-steps
    .ant-steps-item-active
    .ant-steps-item-icon
    .ant-steps-icon
    svg {
    fill: $recruiter-color;
  }
}
.profile_step {
  div.pro_steps.ant-steps .ant-steps-item-icon {
    margin: 0 16px 0 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 100%;
  }
}

.recruiter_board {
  .profile_step {
    div.pro_steps.ant-steps .ant-steps-item-icon {
      border-color: $recruiter-color;
    }
  }
}

.candidate_board {
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    svg {
      fill: $candidate-color;
    }
  }
  div.pro_steps.ant-steps
    .ant-steps-item-active
    .ant-steps-item-icon
    .ant-steps-icon
    svg {
    fill: $candidate-color;
  }
}
