.event-description-quill-editor {
  width: 140%;
  height: 200px;
  margin: 20px 0 60px 0;

  @media (max-width: $tablet-special-case-1) {
    width: 100%;
  }
}

.job-description-quill-editor {
  width: 90%;
  height: 200px;
  margin: 20px 0 60px 0;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }
}
