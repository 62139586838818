.candidate-jobs.CandidateProfile {
  padding-top: 15px;

  .jobs-title {
    p {
      font-size: $font16;
    }

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.create-job__button--right {
  display: flex;
  flex-direction: row-reverse;
}

.create-job__button {
  background-color: $dark-yellow;
  color: $white;
  width: min-content;
  height: min-content;
  padding: 5px;
  border-radius: 5px;
  padding-left: 25px;
  padding-right: 25px;

  span {
    font-size: $font14;
  }

  &:hover {
    color: $dark-yellow;
    border: 1px solid $recruiter-color;
  }
}

.job-lists__filter {
  &-btn-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: $sheader;
    border-radius: 5px;
    font-weight: $medium;
    margin-bottom: 10px;
  }

  &-select {
    display: flex;
    justify-content: space-around;
    margin-left: 10px;
  }
}

@media (max-width: $mobile) {
  .create-job__button--right {
    display: flex;
    justify-content: center;
  }

  .create-job__button {
    display: flex;
    background-color: $dark-yellow;
    justify-content: center;
    color: $white;
    border-radius: 5px;
    width: min-content;
  }
}

.job-opportunities {
  &__choice {
    .ant-tabs-nav .ant-tabs-tab-active {
      color: $candidate-color;
      font-weight: 500;
      outline: none;
    }

    .ant-tabs-ink-bar {
      width: 0;
      height: 2px;
      background-color: $candidate-color;
      transform-origin: 0 0;
    }
  }
}

@media (min-width: $desktop-min) {
  .create-job__button {
    background-color: $dark-yellow;
    color: $white;
    width: min-content;
    height: min-content;
    padding: 5px;
    border-radius: 5px;
    padding-left: 25px;
    padding-right: 25px;

    span {
      font-size: $font16;
    }
  }

  .pagination {
    @include display-flex(row);

    justify-content: flex-end;
    margin: 20px 0;
  }
}

.create-job__button__container {
  @include display-flex(row);
  align-items: center;
  justify-content: flex-end;
}

.recruitment__container {
  @media (max-width: $mobile) {
    @include display-flex(column);

    .create-job__button__container {
      max-width: 92%;
      margin-bottom: 20px;

      button {
        width: 78vw;
      }
    }
  }
}
