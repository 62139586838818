.reject-appliaction-modal__header {
  font-weight: $semi-bold;
  color: $recruiter-color;
  margin-top: 10px;
}

.reject__application__heading-1 {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 30px;
}

.reject__reason__details {
  margin-bottom: 10px;
}

.reject-application-modal {
  &__buttons {
    .submit-button {
      background-color: $recruiter-color;
    }
  }
}
