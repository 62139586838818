.additional-details {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: min-content;
    z-index: 1;
    background: $white;
    box-shadow: $shadow;
    padding-bottom: 30px;

    @media (max-width: $mobile) {
      padding-right: 15px;
    }
  }

  &__heading {
    @include medium-heading-mixin;

    color: $recruiter-color;
    font-weight: $medium;
    margin: 26px;
  }

  &__sub-heading {
    @include small-heading-mixin;

    color: $sub-header;
    font-weight: $medium;
    margin-left: 26px;
    margin-top: 10px;
  }

  &__title {
    @include small-heading-mixin;

    color: $sub-header;
    margin-left: 26px;
    margin-top: 36px;
    font-weight: $medium;
  }

  &__dropdown {
    margin-left: 26px;
    margin-top: 16px;
    margin-right: 12px;

    .ant-select-selector {
      height: 100%;
      padding: 5px;
    }
  }

  &-work-experience__description {
    margin-left: 30px;
    color: $sub-header;
  }
}

.additional-details__input-box {
  margin-left: 26px;
  margin-top: 16px;
  color: $placeholder;
  font-weight: $semi-bold;
}

.additional-details-work-experience {
  margin-left: 26px;
  margin-top: 16px;
  color: $placeholder;
  font-weight: $semi-bold;

  &__input-box {
    width: 120px;
    padding-left: 5px;
    padding-right: 5px;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__description {
    margin-top: -10px;
    font-size: $font12;
  }
}

.additional-details-location {
  margin-top: 46px;

  &__button {
    width: min-content;
    background-color: $recruiter-color;
    color: $white;
    margin-top: 5px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.additional-details-address__row {
  margin-bottom: 10px;
  margin-left: 26px;
  margin-top: 50px;
}

// todo media query for mobile version
