.notification-item {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 80px;
  padding-left: 30px;

  @media (min-width: $desktop-min) {
    height: 100px;
    padding-left: 40px;
  }

  @media (max-width: $mobile) {
    height: 60px;
    padding-left: 20px;
  }

  &.notification-item-new {
    background-color: $very-light-grey-3;
  }

  &::before {
    width: 12px;
    height: 80px;
    content: '';
    position: absolute;
    left: 0;

    @media (min-width: $desktop-min) {
      width: 14px;
      height: 100px;
    }

    @media (max-width: $mobile) {
      width: 8px;
      height: 60px;
    }
  }

  &.orange_color::before {
    background-color: $notifications-orange;
  }

  &.red_color::before {
    background-color: $notifications-red;
  }

  &.violet_color::before {
    background-color: $notifications-voilet;
  }

  &.blue_color::before {
    background-color: $notifications-blue;
  }

  &__time {
    position: absolute;
    top: 15px;
    right: 10px;
    color: $sub-header;

    @media (max-width: $mobile) {
      @include small-font-mixin;

      top: 10px;
      right: 5px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      font-weight: bold;

      @media (max-width: $mobile) {
        @include medium-font-mixin;
      }

      &.orange_color {
        color: $notifications-orange;
      }

      &.red_color {
        color: $notifications-red;
      }

      &.violet_color {
        color: $notifications-voilet;
      }

      &.blue_color {
        color: $notifications-blue;
      }
    }

    &__description {
      margin-top: 10px;
      font-weight: normal;

      @media (max-width: $mobile) {
        @include small-font-mixin;

        margin-top: 5px;
      }
    }
  }
}

.notify_box {
  .notification-item {
    height: 50px;
    margin-left: 10px;
    padding-left: 15px;

    &::before {
      width: 8px;
      height: 50px;
      content: '';
      position: absolute;
      left: 0;
    }

    &__container {
      &__description {
        margin-top: 15px;
        font-weight: normal;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (max-width: $mobile) {
          @include small-font-mixin;

          margin-bottom: -10px;
        }
      }
    }
  }
}

a.notification-item__link {
  cursor: pointer;
  height: max-content;
  margin: 2px;
  margin-top: 0px;

  p {
    word-wrap: break-word;
    width: 75px;
    @media (max-width: $mobile) {
      width: auto;
    }
  }
}

.notifications ul {
  padding-top: 0px;
}
