.candidate-admin-information__header-container {
  display: flex;
  margin: 20px 0;

  .candidate-admin-information__menu-container {
    display: grid;
    place-items: center;
  }
}

.candidate-admin-information__manage-menu-management-event-btn {
  color: $custom-red;
}
