.img-responsive {
  max-width: 485px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;

  @media (max-width: $mobile) {
    width: 90%;
    margin-top: 0;
  }
}

.right-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.landing-page {
  @include display-flex(row);

  width: 100%;
  height: 100vh;
  justify-content: center;

  &__container {
    @include display-flex(row);
    padding-top: 30px;
    width: 45%;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile) {
      margin-top: 30px;
      width: 100%;
    }

    @media (min-width: $desktop-min) {
      width: 40%;
      padding-top: 60px;
    }
  }

  &__outer-container {
    width: 100%;
    align-items: center;
  }

  &__inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    font-family: 'Poppins, sans-serif';
    width: 92%;

    @media (min-width: $desktop-min) {
      width: 100%;
    }

    div.landing_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      max-width: 1920px;
      @media (max-width: $laptop-min) {
        flex-direction: column;
        padding: 20px;
        margin-top: -30px;
      }
    }
  }

  &__explore-button {
    margin-left: 15px;

    @media (max-width: $mobile) {
      margin-left: 5px;
    }

    .explore-button {
      a {
        cursor: pointer;
      }
    }
  }

  &__button-container {
    padding-top: 60px;
    padding-left: 30px;

    @media (min-width: $laptop-min) {
      padding-left: 120px;
    }

    @media (max-width: $mobile) {
      padding-top: 50px;
      padding-left: 40px;
      margin-top: 40px;
    }

    button {
      width: unset;
    }
  }

  &__login-card {
    &__signin-button {
      &,
      &.ant-btn:hover,
      &.ant-btn:focus,
      &.ant-btn:active,
      &.ant-btn.active {
        @include display-flex(row);

        background-color: $bluecolor;
        align-items: center;
        justify-content: center;

        span {
          color: $white;
        }
      }
    }

    .ant-card-body {
      padding: 20px 20px;
      display: flex;
      flex-direction: row;

      @media (max-width: $mobile) {
        padding: 20px 10px;
      }
    }
  }
}

.landing-header-container.container {
  @include display-flex(row);
  padding: 0px;
  justify-content: center;

  #header {
    max-width: 1920px;

    @media (min-width: $desktop-min) {
      height: auto;
    }
    &.other_pge {
      background: $white;

      & .custom_container {
        img.logo,
        a {
          max-width: 180px;
          width: 100%;

          @media (max-width: $mobile) {
            width: 100px;
          }

          @media (min-width: $desktop-min) {
            max-width: 210px;
          }
        }
      }

      & .custom_container .right-menu ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          a {
            @include medium-large-heading-mixin;

            text-align: left;
            letter-spacing: 0;
            color: $ctextcolor;
            opacity: 0.75;
            position: relative;

            &:hover {
              cursor: pointer;
            }
          }
        }

        li:hover {
          cursor: pointer;
        }
      }
    }

    .mob_fix_head {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.custom_container {
  margin: 0;
  padding: 20px;

  @media (max-width: $mobile) {
    width: 100%;
    padding: 15px 20px;
  }

  @media (min-width: $desktop-min) {
    padding: 25px;
  }
}

.signup-form-item {
  padding-bottom: 20px;

  &--center {
    text-align: center;

    a:hover {
      cursor: pointer;
    }
  }
}
