// TODO: Add Media Queries For Mobile Screens
.job-results {
  .wrapper {
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }

    @media (max-width: $mobile-special-case-1) {
      gap: 30px;
    }
  }
  &__main {
    @include display-flex(row);

    justify-content: space-between;

    @media (max-width: $mobile) {
      margin-top: 30px;
      align-items: center;
    }

    @media (max-width: $mobile-special-case-1) {
      margin-top: 10px;
    }

    &__title {
      @include medium-large-heading-mixin;

      font-weight: $semi-bold;
      margin-bottom: 20px;

      @media (min-width: $desktop-min) {
        margin-bottom: 40px;
      }
    }

    &__filter {
      width: 160px;
      height: 25px;
    }
  }

  &__events {
    @include display-flex(row);

    align-items: center;

    @media (max-width: $mobile) {
      display: block;
    }

    div {
      &:nth-child(2) {
        @media (max-width: $mobile) {
          margin-left: 0;
        }
      }
    }

    & > h2 {
      @include small-heading-mixin;

      margin-right: 30px;
    }

    &__all-events {
      width: 400px;

      .ant-select-selector {
        .ant-select-selection-placeholder {
          font-size: $font14;
        }

        .ant-select-selection-item {
          font-size: $font14;
        }
      }
    }
  }

  .grid {
    margin-top: 20px;

    @media (max-width: $mobile-special-case-1) {
      margin-top: 0;
    }

    @media (max-width: $mobile) {
      overflow: auto;
    }
    &__headings {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      & > div {
        margin-bottom: 20px;
        margin-top: 20px;
        flex-grow: 1;

        @media (max-width: $mobile) {
          min-width: 100px;
        }

        @media (min-width: $desktop-min) {
          margin-top: 40px;
        }

        h2 {
          @include large-font-mixin;
        }
      }
    }

    .data {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      position: relative;

      & > div {
        margin-top: 35px;

        @media (max-width: $mobile) {
          min-width: 100px;
          white-space: break-spaces;
        }
      }

      .event__name {
        margin-left: 15px;

        @media (max-width: $mobile) {
          margin-left: 0;
        }
      }
    }

    .title,
    .selected,
    .eta,
    .rejected {
      @include small-font-mixin;
    }

    .title {
      background-color: $very-light-grey-2;
      margin-bottom: 20px;
      padding: 15px;
      color: $label;
    }

    .selected {
      @include display-flex(row);

      color: $custom-green;
      margin-left: 20px;
      align-items: center;

      &::before {
        content: '';
        width: 13px;
        height: 60px;
        background-color: $custom-green;
        position: absolute;
        left: 0;
      }
    }
    .eta__wrapper {
      align-items: center;
      margin-top: -20px;

      text-align: center;
      height: 60px;
      display: flex;

      &::before {
        content: '';
        width: 13px;
        height: 60px;
        background-color: $custom-orange;
        position: absolute;
        left: 0;
        margin-bottom: 10px;
      }
      .eta-submitted {
        align-items: center;
      }
      .eta {
        align-self: center;
        @include display-flex(row);
        color: $custom-orange;
        margin-left: 20px;
        align-items: center;
      }
      .eta-time {
        @include display-flex(row);

        color: $custom-orange;
        margin-left: 20px;
        align-items: center;
        text-align: left;
      }
    }

    .rejected {
      @include display-flex(row);

      color: $custom-red;
      margin-left: 20px;
      align-items: center;

      &::before {
        content: '';
        width: 13px;
        height: 60px;
        background-color: $custom-red;
        position: absolute;
        left: 0;
        margin-bottom: 10px;
      }
    }

    .job-title {
      .title,
      .position {
        @include small-font-mixin;
      }
    }

    .position {
      margin-left: 15px;

      &__link {
        @include small-font-mixin;

        position: relative;
        color: $gray;
        text-decoration: none;
        cursor: pointer;

        &::before {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $candidate-color;
          transform: scaleX(0);
          transition: transform 0.5s ease;
        }

        &:hover::before {
          transform: scaleX(1);
        }
      }
    }

    .location {
      margin-left: 15px;

      @media (max-width: $mobile) {
        margin-left: 0;
      }
    }

    .position,
    .event__name,
    .location {
      @include small-font-mixin;

      color: $gray;
      margin-bottom: 30px;
    }

    .position {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .interview {
      p {
        @include small-font-mixin;
        margin-left: 12px;
        color: $candidate-color;
        font-weight: $semi-bold;

        @media (max-width: $mobile) {
          margin-left: 0;
        }
      }
      .select-slot .select {
        width: 11vw;
        height: 29px;
        border: none;
        background-color: $custom-purple;
        color: $white;

        @media (max-width: $mobile) {
          width: 80px;
        }

        span {
          @include small-font-mixin;
        }
      }

      .select-slot .not-selected {
        display: none;
      }

      .select-slot {
        div {
          @media (max-width: $mobile) {
            margin-left: 3px;
          }
        }
      }
    }

    .analytics {
      & > div > button {
        border: 1px solid $candidate-color;
        border-radius: 5px;
        margin-bottom: 20px;

        @media (max-width: $mobile) {
          width: 80px;
        }

        span {
          @include small-font-mixin;
        }
      }
    }
  }
}

.scheduled-slots {
  &__title {
    color: $candidate-color;
  }

  &__details {
    @include display-flex(row);

    justify-content: space-between;
    margin-bottom: 20px;

    span {
      @include extra-large-font-mixin;

      font-weight: $semi-bold;
    }

    &__request-new-slot {
      align-self: flex-end;
      justify-self: flex-end;

      button {
        border: none;
      }

      span {
        @include small-font-mixin;

        color: $candidate-color;
        border: 1px solid $candidate-color;
        border-radius: 5px;
        padding: 5px 20px;
      }
    }
  }

  &__timing {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media (max-width: $laptop-max) {
      display: flex;
      flex-wrap: wrap;
    }

    .selected {
      @include display-flex(row);

      border: 1px solid $candidate-color;
      padding: 3px 15px;
      border-radius: 5px;
      width: 320px;
      height: 35px;
      white-space: nowrap;
      background-color: $candidate-color;

      p {
        @include small-font-mixin;

        color: $white;
        font-weight: $semi-bold;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-left: 12px;
        margin-top: 3px;
        fill: $white;
      }
    }

    .not-selected {
      @include display-flex(row);

      border: 1px solid $gray;
      border-radius: 5px;
      padding: 3px 15px;
      white-space: nowrap;
      width: 320px;
      height: 35px;
      background-color: $white;

      p {
        @include small-font-mixin;

        color: $label;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-left: 12px;
        margin-top: 3px;
        fill: $gray;
      }
    }

    .not-selected:hover {
      border: 1px solid $candidate-color;
      cursor: pointer;

      p {
        color: $candidate-color;
      }

      svg {
        fill: $candidate-color;
      }
    }

    .over,
    .selected,
    .not-selected {
      justify-content: center;
      align-items: center;

      svg {
        margin-top: 0;
      }
    }
  }

  &__save {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    height: 130px;

    button {
      border: none;
      align-self: flex-end;

      span {
        @include small-font-mixin;

        background-color: $candidate-color;
        color: $white;
        border-radius: 3px;
        padding: 4px 20px;
      }
    }
  }
}

.job-results__main__filter__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  div {
    margin-top: 2.2px;
  }

  @media (max-width: $mobile) {
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 20px;
  }

  &--right {
    width: 200px;
    margin-right: 20px;
    @media (min-width: $desktop-min) {
      width: 220px;
    }

    .job-results__main__filter {
      .ant-select-selector {
        .ant-select-selection-item {
          font-size: $font14;
        }
      }

      .ant-select-arrow {
        margin-top: -4.5px;
      }
    }

    .ant-select-arrow {
      margin-top: 1px;
    }
  }
}

.site-card-wrapper {
  padding: 30px;
  background: $very-light-grey-2;
  margin-bottom: 10px;
}

.second-card {
  margin-top: 20px;
}

a.disclaimer-link {
  color: $bluecolor;
  &:hover {
    cursor: pointer;
  }
}

.disclaimer-important {
  font-weight: bold;
}

.reload-icon-job-result {
  margin-top: 10px;
  margin-right: 10px;
}

.position-view-details {
  @include display-flex(row);
  justify-content: center;
  .view-job-details {
    margin-top: -6px;
    height: 29px;
    padding: 5px;
    border: 1px solid $candidate-color;
    border-radius: 5px;
    text-align: center;
    color: $candidate-color;
    align-self: flex-end;
    float: right;
    &:hover {
      cursor: pointer;
    }
  }
}
