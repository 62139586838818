* {
  box-sizing: border-box;
  box-sizing: border-box;
  box-sizing: border-box;
}

.side-menu {
  max-width: 312px;
  width: 18%;
  height: 100vh;
  background-color: $color_white;

  img {
    margin-right: 20px;
    filter: invert(79%) sepia(1%) saturate(2258%) hue-rotate(24deg)
      brightness(99%) contrast(87%);
  }

  a {
    color: $placeholder;

    &:hover {
      cursor: pointer;
    }
  }

  .logo {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 8rem;
    border-right: $border_line;
    border-bottom: $border_line;

    .img {
      max-width: 173px;
      width: 100%;
    }
  }

  .menu {
    width: 100%;
  }

  .item {
    margin-top: 2rem;
  }

  &.candidate {
    .fixed_side {
      li {
        &:hover,
        &:visited,
        &.ant-menu-item-active,
        &.ant-menu-item-selected {
          img {
            filter: invert(42%) sepia(17%) saturate(1370%) hue-rotate(206deg)
              brightness(92%) contrast(91%);
          }
        }
      }
    }
  }

  &.organizer {
    .fixed_side {
      li {
        &:hover,
        &:visited,
        &.ant-menu-item-active,
        &.ant-menu-item-selected {
          img {
            filter: invert(66%) sepia(14%) saturate(1445%) hue-rotate(119deg)
              brightness(97%) contrast(91%);
          }
        }
      }
    }
  }

  &.recruiter {
    .fixed_side {
      li {
        &:hover,
        &:visited,
        &.ant-menu-item-active,
        &.ant-menu-item-selected {
          img {
            filter: invert(83%) sepia(42%) saturate(1046%) hue-rotate(325deg)
              brightness(105%) contrast(103%);
          }
        }
      }
    }
  }
}

#sidebar {
  max-width: 312px;
  width: 19%;
  background: $color_white;
  position: relative;

  @media (min-width: 1300px) and (max-width: $desktop-min) {
    width: 18%;
  }

  .fixed_side {
    position: fixed;
    max-width: 312px;
    width: 19%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 112px;

    @media (min-width: 1300px) and (max-width: $desktop-min) {
      width: 18%;
    }

    ul {
      margin-top: 0;

      li {
        height: 38px;
        vertical-align: middle;
        line-height: 50px;
        color: $placeholder;
        margin-top: 21px;
        background-color: $color_white;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-right: 0;
          position: relative;
          fill: $placeholder;
        }

        a {
          margin-left: 8px;
          color: $placeholder;
          cursor: pointer;

          @media (min-width: $desktop-min) {
            margin-left: 15px;
          }
        }
      }

      li.ant-menu-item-active,
      li.ant-menu-item-selected {
        a {
          color: $primary-color !important;
        }

        svg {
          fill: $primary-color !important;
        }

        &::after {
          border-right: 7px solid $primary-color;
        }
      }
    }

    .helpdoc {
      color: $gray;
      margin-bottom: 20px;
      padding-left: 19px;
      display: block;
      text-align: left;
      font-weight: $medium;
    }

    .support_btn {
      width: 105.5%;
      display: flex;
      align-items: center;
      background-color: $primary-color;
      height: 45px;
      border: 0;
      text-align: left;
      font-weight: $medium;

      @media (min-width: $desktop-min) {
        width: 100%;
      }

      span {
        text-align: left;
        padding-left: 7px;
      }
    }

    .ant-layout-sider-children {
      height: 79%;
    }

    .botm_btns {
      position: fixed;
      bottom: 0;
      background: $color_white;
      padding-top: 10px;
      margin-top: 58%;
      max-width: 312px;
      width: 18%;

      svg {
        fill: $color_white;
        line-height: 20px;
        position: relative;
        top: 4px;
        width: 21px;
        height: 21px;
      }
    }
  }
}

//scrollbar

/* Hide scrollbar for Chrome, Safari and Opera */
.fixed_side::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.fixed_side {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fixed_side > .menu.ant-menu-inline {
  border-right: 0;
}

@media (min-width: $desktop-min) {
  #sidebar {
    .fixed_side {
      ul {
        margin-top: 10px;

        li {
          padding-left: 22px;
          margin-bottom: 30px;

          img {
            margin-right: 5px;
          }

          a {
            margin-left: $font18;
            color: $placeholder;
            cursor: pointer;
          }
        }
      }

      .helpdoc {
        font-size: $font16;
      }

      .support_btn {
        span {
          font-size: $font18;
        }
      }

      .botm_btns {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

@mixin menu-font-mixin {
  font-size: $font14;

  @media (min-width: $desktop-min) {
    font-size: $font16;
  }
}

.menu-font {
  @include menu-font-mixin;

  span {
    @include menu-font-mixin;
  }
}

@mixin menu-icon-mixin {
  width: 20px;
  height: 20px;

  @media (min-width: $desktop-min) {
    width: 26px;
    height: 26px;
  }
}

.menu-icon {
  @include menu-icon-mixin;
}
