.notification-header {
  &__title {
    font-weight: $semi-bold;
  }

  &__box {
    @include display-flex(row);
    justify-content: flex-end;

    @media (max-width: $mobile) {
      justify-content: flex-start;
    }

    &--right {
      @include display-flex(row);
      margin-top: 0;
      padding: 0;
      border-radius: 0;
      box-shadow: none;

      ul {
        display: flex;
        flex-direction: row;
        padding: 0;

        li {
          display: flex;
          margin-left: 20px;
          align-items: center;
          margin-bottom: 0;
          margin-top: 12px;

          @media (max-width: $mobile) {
            margin-left: 10px;

            .left_bx {
              margin-bottom: 5px;
            }
          }

          @media (max-width: $laptop-min) {
            width: unset;
          }
        }
      }
    }

    &__filter {
      margin-left: 25px;
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: $mobile) {
        display: none;

        &.show-mobile {
          display: block;
          position: absolute;
          top: -10px;
          right: 0;

          .ant-select-selector {
            color: $gray-2;
            font-weight: normal;
          }
        }
      }

      &__text {
        color: $placeholder;
      }
    }
  }
}

.organizer_notification {
  .notification-header {
    &__title {
      color: $primary-color;
    }
  }
}

.recruiter_notification {
  .notification-header {
    &__title {
      color: $recruiter-color;
    }
  }
}

.candidate_notification {
  .notification-header {
    &__title {
      color: $candidate-color;
    }
  }
}
