$primary-color: #47bfa8;
$font16: 16px;
$shadow: 0 0 10px #0000001a;
$bradius: 10px;

.main-layout {
  height: auto;
}

.main-container {
  background-color: $color_main_container;
  overflow: hidden;
}

.parent_sec {
  display: flex;
  clear: both;
  margin-bottom: 2rem;

  .main-container {
    flex: 1;
    padding-bottom: 5rem;

    .content_bx {
      .steps-content {
        overflow: auto;
      }
    }
  }
}

.height-auto {
  height: auto;
}

.main-layout__cookies {
  background-color: $black;
  color: $white;
}

.CookieConsent {
  background-color: $white;
  color: $white;
}
