span,
p {
  margin: 0;
  padding: 0;
}

.center {
  text-align: center;

  @media (min-width: $tablet-min) and (max-width: $tablet-max) {
    margin: 0 auto;
  }
}

.circle {
  width: 310px;
  height: 310px;
  opacity: 20%;
  background-color: $custom-purple;
  border-radius: 50%;
  position: absolute;
  top: -120px;
  left: -120px;
}

.container {
  margin-bottom: 30px;
  box-shadow: 0 2px 10px #00000029;
  background-color: $white;
  position: relative;

  &.basic {
    @media (max-width: $mobile) {
      padding-bottom: 100px;
      padding-left: 20px;
    }
  }

  &.work-experience,
  &.job-profile {
    @media (max-width: $mobile) {
      padding-left: 20px;
    }
  }
}

.header-container {
  margin-bottom: 25px;
  position: relative;
  z-index: 11;
}

.main-header {
  color: $header;
  font-weight: $bold;
  margin-bottom: 0;
  position: relative;
  top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 55%;
}

.sub-header {
  color: $sub-header;
  font-weight: $light;
  font-size: 18px;
  padding-top: 15px;
}

.purple-header {
  color: $custom-purple;
  font-weight: $semi-bold;
  font-size: 18px;
  margin-bottom: 24px;
}

.black-header {
  color: $black-text;
  font-weight: $semi-bold;
  font-size: 18px;
  margin-top: 50px;
  margin-bottom: 24px;
}

.info-header {
  color: $sub-header;
  font-weight: $medium;
  margin-bottom: 24px;
}

.info-text {
  color: $black-text;
  font-weight: $regular;
  word-wrap: break-word;
}

.text-upload {
  @include small-heading-mixin;

  border: none;
  background-color: $white;
  margin: 10px 0;
}

.item-container {
  padding: 30px 20px;
  margin-bottom: 0;
  background-color: $main-background;
  position: relative;
  white-space: break-spaces;
  margin-bottom: 30px;

  div {
    margin-right: 100px;
  }
}

.edit-button {
  position: absolute;
  right: 20px;
  padding: 12px;
  font-size: 30px;
  border-radius: 50%;
  box-shadow: 0 2px 10px #00000029;
  color: $custom-purple;
}

.work-experience-text,
.field-text {
  color: #1f1f1f;
  font-weight: $semi-bold;
}

.work-experience-value {
  color: $sheader;
  font-weight: $medium;
  margin-left: 25px;
}

.skill-black {
  color: #141414;
  font-weight: $medium;
}

.skill-level-size {
  font-size: 15px;
  color: $candidate-color;
  font-weight: $medium;
}

.skillset.recruiter {
  .skill-level-size {
    color: $recruiter-color;
  }
}

.education-text {
  color: $sheader;
  font-weight: $medium;
}

.add-education-button {
  margin-top: 30px;

  .add-education-button-hover {
    &:hover {
      cursor: pointer;
    }
  }
}

.resume-upload {
  margin: 10px 0;
}

.resume,
.skill-set {
  margin-bottom: 30px;

  .ant-upload-list-item {
    margin-bottom: 30px;
  }
}

.skill-set {
  &__levels {
    width: 900px;

    .minus-circle {
      margin-top: 5px;
    }
  }

  .addskills {
    display: flex;
    align-items: center;

    @media screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    .add-profile-link {
      @media screen and (max-width: $mobile) {
        margin-bottom: 10px;
        max-width: 100%;
      }
    }
  }

  h2 {
    margin-left: 30px;
    color: $sub-header;
  }
}

.add-skill {
  margin-bottom: 30px;

  &__description {
    color: $tgtextcolor;
    margin-left: 15px;
  }
}

.add-skill-button {
  width: 100%;
  max-width: 300px;
  color: $placeholder;
  height: 42px;
}

.add-skill-hint {
  margin-left: 20px;
}

.skill-line.skills {
  display: grid;
  grid-template-columns: 300px 300px 100px;
  align-items: flex-start;
  margin-top: 10px;
  gap: 10px;

  @media (max-width: $mobile) {
    grid-template-columns: 200px 200px 100px;
  }

  @media (max-width: $mobile-special-case-1) {
    grid-template-columns: 70px 150px 100px;
  }

  .skill-black {
    word-wrap: break-word;
    width: 200px;

    @media (max-width: $mobile) {
      width: auto;
    }
  }
}

.skill-name-column {
  margin-bottom: 30px;
  width: 410px;

  @media screen and (max-width: $mobile) {
    width: 100%;
  }

  input {
    height: 45px;
  }

  .skill__input {
    margin-top: -10px;
  }

  .skill-name-input {
    padding: 5px 10px;
    border: 1px solid #d8d8d8;
    height: 42px;
    color: #202020;
  }
}

.skill-value-column {
  width: 500px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $mobile) {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
  }
}

.skill-remove-column {
  text-align: center;
  font-size: 20px;
  margin-left: 0px;
  margin-top: -4px;

  @media (max-width: $mobile) {
    margin-top: 0;
  }

  .minus-circle {
    color: $custom-red;

    @media screen and (max-width: $mobile) {
      margin-right: 300px;
    }
  }
}

.black-text {
  color: $black-text;
  margin-bottom: 15px;
}

.upload-certification-hint {
  margin-top: 10px;
  font-size: small;
  color: lightgray;
}

.nor_header {
  padding-bottom: 24px;
  // color: $header;
  font-weight: 600;
}

.removeLogo {
  // TODO: Will be removing some time later
  // font-size: 1.3rem;
  color: $custom-red;

  &:hover {
    cursor: pointer;
  }
}

.cancel-button {
  border: none;
  color: $black-text;
  width: 100%;
  text-align: center;
  padding-left: 0;
  font-weight: $semi-bold;
  font-size: 20px;
}

.edit-wrapper {
  margin-top: -30px;
}

.remove-work-experience {
  &.desktop {
    display: block;

    .remove-field {
      margin-bottom: 20px;
    }
    @media (max-width: $mobile) {
      display: none;
    }
  }

  &.mobile {
    display: none;
    @media (max-width: $mobile) {
      display: block;
    }

    .remove-work {
      color: $custom-red;
      text-align: left;
      font-weight: $semi-bold;
      font-size: $font14;
      margin-bottom: 20px;
    }
  }
}

.personal-details {
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    margin-top: -5px !important;
  }
}

.PersonDetailModal {
  .ant-col-md-order-2 {
    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      order: 1;
    }
  }
}

.PersonDetailModal,
.WorkExperienceModal,
.EducationDetailModal {
  .main-header {
    color: $candidate-color;
    font-weight: $medium;
  }

  .sub-header__block {
    @include display-flex(row);

    justify-content: space-between;
    flex-wrap: wrap;

    .change-password {
      margin-bottom: 70px;

      &__button {
        border: none;
        background-color: $very-light-grey-2;
        width: 275px;
        height: 39px;

        @media screen and (max-width: $mobile) {
          width: 180px;
        }

        span {
          @include extra-large-font-mixin;

          font-weight: $semi-bold;
          color: $ctextcolor;
        }
      }
    }
  }

  .sub-header {
    font-weight: $semi-bold;
    letter-spacing: 1px;
  }

  .ant-modal-body {
    padding-left: 30px;

    @media (max-width: $mobile) {
      padding-left: 15px;
    }
  }

  .ant-input {
    color: $black-text;
  }

  .purple-header {
    color: $custom-purple;
    font-weight: $semi-bold;
    margin-bottom: 24px;
  }

  .info-header {
    color: $black-text;
    font-weight: $regular;
    margin-bottom: 15px;
  }

  .ant-select-selector {
    border-radius: 5px;
    color: $black;
  }

  .ant-select-selection-item {
    margin: 5px 5px 5px 0;
    border-radius: 5px;
    color: $black-text;
  }

  .ant-select-selection-item-content {
    margin-right: 20px;
    color: $sub-header;
  }

  .submit-button {
    color: $white;
    background-color: $candidate-color;
    width: 100%;
    text-align: center;
  }

  .remove-button {
    border: none;
    color: $custom-red;
    font-weight: $semi-bold;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0;
  }

  .add-profile-link {
    width: 100%;
    width: 100%;
    height: auto;
    max-width: 300px;
    border: 1px dashed $placeholder;
    border-radius: 3px;
    color: $placeholder;
    padding: 8px 0;
    max-width: 415px;

    &.add-education {
      margin-bottom: 20px;
    }
  }

  .addlink {
    margin-top: 40px;

    @media (max-width: $mobile) {
      margin-top: 0;
    }

    .add-profile-link {
      height: 42px;
      max-width: 412px;
    }

    .addbutn {
      button {
        border: none;
        background-color: $white;
        line-height: 44px;

        svg {
          fill: $gray-4;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .EducationInfo,
  .AdditionalInfo {
    margin-top: 40px;

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      margin-top: 20px;
    }

    .ant-input-number {
      height: 33px;
    }
  }

  .EducationInfo.wrapper {
    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: -10px;
    }
    div {
      @media (max-width: $mobile) {
        margin-bottom: 0;
      }

      div {
        gap: 40px;
      }
    }
  }

  .educationinfo-heading {
    margin-bottom: -10px !important;
  }

  .highest-education-dropdown {
    span {
      font-size: $font14;
    }
    .ant-select-arrow {
      margin-top: -9px;
    }
  }

  .removeLogo {
    color: $custom-red;

    &:hover {
      cursor: pointer;
    }
  }

  .center {
    text-align: center;
  }

  .skill-set {
    .add-skill {
      margin-bottom: 30px;
    }

    .minus-circle {
      svg {
        font-size: 26px;
        margin-top: 3px;
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-title {
        color: $candidate-color;
      }

      .ant-steps-item-icon {
        border: 3px solid $candidate-color;
        color: $candidate-color;
        z-index: 11;
        position: relative;
      }

      .ant-steps-item-icon {
        .ant-steps-icon {
          color: $candidate-color;

          svg {
            fill: $candidate-color;
          }
        }
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        z-index: 11;
        position: relative;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        border: 3px solid $candidate-color;
        z-index: 11;
        position: relative;
      }

      svg {
        fill: $candidate-color;
      }

      .ant-steps-item-tail {
        &::after {
          background-color: $candidate-color;
        }
      }

      .ant-steps-item-title {
        color: $candidate-color;
      }
    }
  }

  //minus btn
  .skill-remove-column {
    .minus-circle {
      color: $custom-red;
      margin-left: 20px;
    }
  }
  //skill line
  .custom_skilline {
    margin-bottom: 30px;

    .wrapper {
      align-items: flex-start;
    }

    .ant-col.skill-value-column {
      max-width: 630px;
      width: 100%;
    }

    .ant-steps-label-vertical .ant-steps-item-tail {
      padding: 0 10px;
    }

    .ant-steps-small .ant-steps-item-tail {
      &::after {
        height: 3px;
      }
    }
  }
}

.WorkExperienceModal {
  // .purple-header
  // {
  //     font-weight: 500;
  //     font-size: 16px;
  // }
  .main-header {
    margin-bottom: 27px;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .color {
    color: $gray;
  }

  .remove-button {
    font-size: 18px;
    font-weight: 600;
    padding: 30px 0 52px;
  }

  .cancel-button {
    padding-left: 0;
  }

  display: flex;
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: $mobile) {
    gap: 20px;
  }

  .wrapper {
    .years-and-months {
      display: flex;
      gap: 30px;
    }
  }

  .salary {
    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      margin-top: -30px;
    }
    .wrapper {
      display: flex;
    }
  }
}

.EducationDetailModal {
  .remove-button {
    padding: 0 0 80px;
    margin-top: 0;
  }

  .purple-header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
  }
}

//
.upload_cer {
  display: flex;
  margin-top: 20px;

  @media (max-width: $mobile) {
    margin-top: 0;
  }

  .upload-certification-hint {
    span {
      padding-left: 25px;
    }
  }
}

//upload
.ant-btn.upload_btn {
  padding: 7px 22px 31px;
}

//close

.skill-set {
  .minus-circle {
    svg {
      font-size: 26px;
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      color: $candidate-color !important;
    }

    .ant-steps-item-icon {
      background: $white;
      border: 3px solid $candidate-color;
      color: $candidate-color;
    }

    .ant-steps-item-icon {
      .ant-steps-icon {
        color: $candidate-color;

        svg {
          fill: $candidate-color;
        }
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border: 3px solid $candidate-color;
    }

    svg {
      fill: $candidate-color;
    }

    .ant-steps-item-tail {
      &::after {
        background-color: $candidate-color;
      }
    }

    .ant-steps-item-title {
      color: $candidate-color;
      opacity: 0.7;
    }
  }
}

.no-match {
  margin-top: 15px;
}

.purple-color {
  color: $custom-purple;
  font-weight: $semi-bold;
  margin-bottom: 30px;

  &:hover {
    cursor: pointer;
  }
}

.upload-image-hint-text {
  display: inline-block;
  margin-top: 15px;
  color: $sub-header;
  text-align: center;
  font-size: $font12;

  @media (max-width: $mobile) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.PersonDetailModal {
  .ant-steps-small .ant-steps-item-tail {
    @media (min-width: $mobile) {
      top: -6px;
    }
    @media (max-width: $mobile) {
      top: -8px;
    }
  }
}

.job-related-details__industry {
  padding: 5px;
  background-color: $very-light-grey-3;
  margin: 3px;
  max-width: max-content;
}

.file-container {
  margin: 5px;
}
