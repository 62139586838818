.broadcast-event__container {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px #0000001a;

  &__heading {
    font-weight: $semi-bold;
    margin-bottom: 15px;
  }
  &__sub-heading {
    font-weight: $semi-bold;
    color: $sheader;
    margin-bottom: 15px;
  }
  &__publish-form {
    width: 100%;
    &__button {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 24px;
      &--left {
        background-color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: white;
        }
      }
      &--left:hover,
      &--left.ant-btn:active,
      &--left.ant-btn:focus {
        background-color: $primary-color;
      }
    }
    &__name {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 24px;
      &__heading {
        margin-bottom: 10px;
        color: $label;
      }
      &__input {
        align-items: center;
        width: 100%;
        &__dropdown {
          margin-left: 20px;
          min-width: 350px;

          @media (max-width: $mobile) {
            margin-left: 5px;
            min-width: 100%;
          }
        }
        &__label {
          font-weight: $semi-bold;
        }
      }
    }
    &__link {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &__heading {
        margin-bottom: 15px;
        color: $label;
      }
    }
  }
  &__published {
    margin-bottom: 15px;
    &__name,
    &__link {
      color: $label;
    }

    &__buttons {
      margin-top: 5px;
      @media (min-width: $desktop-min) {
        margin-top: 10px;
      }
      &__row {
        &__edit {
          &__button {
            width: 20px;
            height: 20px;
            padding: 0px;
            border: none;
            margin-right: 20px;
          }
          svg {
            width: 20px;
            height: 20px;
            fill: $gray-2;
          }
          svg:hover {
            cursor: pointer;
          }
        }
        &__remove {
          svg {
            width: 20px;
            height: 20px;
            fill: $custom-red;
          }

          svg:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .ant-form-item {
    width: 100%;
  }
}

.organizer_events.broadcast-event {
  .broadcast-event__container {
    &__heading {
      color: $primary-color;
    }
  }
}

.broadcast-event-container {
  background-color: #14141408;
  border: 1px dashed #70707080;
  border-radius: 10px;
  margin: 20px 0px 0px 0px;
  padding: 25px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px;
  }

  &__event__title {
    font-weight: bold;

    @media (max-width: $mobile) {
      margin-top: 25px;
      &.edit_broadcast-link-modal__info {
        margin-top: 5px;
      }
    }
  }

  &__headings {
    margin: 10px 0px;

    .ant-col {
      font-weight: bold;
    }
  }
  &__edit-link {
    @include display-flex(row);

    position: absolute;
    right: 20px;
    top: 20px;
    align-items: center;
    border-color: $primary-color;

    @media (max-width: $mobile) {
      right: 8px;
      top: 8px;
      padding: 2px 10px;
    }

    svg {
      width: 15px;
      height: 15px;
      fill: $primary-color;
      margin-right: 5px;
      @media (max-width: $mobile) {
        width: 10px;
        height: 10px;
      }
    }
    span {
      @include medium-font-mixin;

      color: $primary-color;
    }
  }
  &__links {
    @include display-flex(row);

    margin: 10px 0px;
    align-items: center;

    .ant-col {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }
}

.edit-broadcast-link-modal {
  &__header {
    display: block;
    color: $primary-color;
    font-weight: bold;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.add-broadcast-link {
  @include display-flex(row);

  margin-top: 20px;
  padding: 20px 120px;
  align-items: center;
  border-color: $primary-color;

  span {
    color: $primary-color;
  }

  &.ant-btn-dashed:active {
    color: $primary-color;
  }

  &.ant-btn-dashed:focus {
    color: $primary-color;
  }
}

.broadcast-link-container {
  margin-top: 20px;

  @media (max-width: $mobile) {
    .addbutn {
      row-gap: 0px !important;
    }

    .profile-link-list__delete {
      margin-top: -20px;
      margin-bottom: 20px;
      margin-left: 5px;
    }
  }
}

.link-switch-modal {
  &__header {
    display: block;
    font-weight: bold;

    @media (max-width: $mobile) {
      display: none;
    }

    &.organizer {
      color: $primary-color;
    }

    &.recruiter {
      color: $recruiter-color;
    }

    &.candidate {
      color: $candidate-color;
    }
  }
}
