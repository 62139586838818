.resume__quick__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: $sub-header;
  font-weight: $semi-bold;
  padding: 10px;
  height: min-content;
  overflow: hidden;
}

.resume__details__options__left {
  margin-top: 30px;
  color: $header;
}

.resume__details__options__left--option {
  margin-top: 10px;
  margin-bottom: -10px;
}

.resume__details__profilelink__options__left {
  margin-top: 30px;
  margin-bottom: 20px;
}

.resume__details__options__right {
  margin-top: 30px;
  margin-left: 10px;
  font-weight: $medium;
  color: $black-text;
}

.resume__details__skills {
  margin-top: 20px;
  margin-right: 20px;
}

@mixin pseudo-normal-effect($element) {
  &:#{$element} {
    background-color: $resume-button;
    border-color: transparent;
    box-shadow: none;
  }
}

.resume__details-btn-1 {
  background-color: $resume-button;
  width: min-content;
  height: min-content;
  align-self: center;
  border-radius: 5px;
  font-weight: $semi-bold;

  &.button {
    width: min-content;
    height: min-content;
    max-width: max-content;
  }

  cursor: default;

  @include pseudo-normal-effect(hover);
  @include pseudo-normal-effect(focus);
  @include pseudo-normal-effect(active);
}

.resume__details-btn-2 {
  background-color: $resume_color;
  align-self: center;
  border-radius: 5px;
  font-weight: $semi-bold;

  &.button {
    width: min-content;
    height: min-content;
    max-width: max-content;
  }
}

.resume__details-btn-3 {
  background-color: $bluecolor;
  width: min-content;
  height: min-content;
  align-self: center;
  font-weight: $medium;
  border: none;
  padding: 6px 15px;
  border-radius: 5px;

  &.button {
    width: min-content;
    height: min-content;
    max-width: max-content;
  }

  span {
    color: $white;
  }
}

.img__user__resume__details {
  font-weight: $medium;
  margin-top: 10px;

  .resume_quick_details--user-profile-pic-preview,
  svg {
    width: 26px;
    height: 26px;
    margin-right: 15px;
    position: relative;
    top: 6px;
    border-radius: 50%;
    background: $very-light-grey;
    padding: 4px;
  }
}

.resume_quick_details--user {
  &-profile-view-text {
    margin-left: 15px;
    padding-top: 5px;
  }

  &-chat-button {
    margin: 10px 0;
    padding: 4px 15px;
    cursor: pointer;
    color: $recruiter-color;
    border: 1px solid $recruiter-color;
  }
}

.resume__details {
  &__wrapper__save-btn--reverse {
    display: flex;
    justify-content: center;
  }

  &__save-btn--reverse {
    background-color: $recruiter-color;
    width: min-content;
    height: min-content;
    color: $white;
    padding: 3px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
  }
}

.resume__candidate__status {
  width: 100%;
  align-items: center;

  &__select {
    color: $sheader2;
    font-weight: $medium;
  }

  .ant-select-selector {
    height: 38px !important;
  }
}
//overridden ant-default button style
.skill__level {
  &-btn {
    justify-content: center;
    background-color: $badge-color;
    border-radius: 50%;
    width: 28px !important;
    height: 28px !important;
    padding: 2px;
    margin: 5px;
    margin-top: 35px;

    span {
      color: $white;
      font-size: $font13;
      font-weight: $semi-bold;
    }
  }

  &__badge {
    .ant-badge-count {
      background-color: $badge-color;
      box-shadow: 0px 5px 10px #00000029;
    }
  }
}

@media (max-width: $mobile) {
  .resume__quick__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-shrink: inherit;
    color: $sub-header;
    font-weight: $semi-bold;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
  }

  .resume__details__options__left {
    margin-top: 30px;
    color: $header;
  }

  .resume__details__options__left--option {
    margin-top: 25px;
  }

  .resume__details__options__right {
    margin-top: 30px;
    margin-left: 10px;
    font-weight: $medium;
    color: $black;
  }

  .resume-profile-links {
    .logo {
      margin-right: 8px;
      fill: $gray;
      width: 50px;
      height: 50px;
      &:hover {
        fill: black;
        cursor: pointer;
      }

      &-background {
        fill: $very-light-grey-2;
      }
    }
  }
  //overridden default button style
  .skill__level {
    &-btn {
      justify-content: center;
      background-color: $badge-color;
      border-radius: 50%;
      width: 28px !important;
      height: 28px !important;
      padding: 1px;
      margin: 10px;
      margin-top: 30px;

      span {
        color: $white;
        font-size: $font12;
        font-weight: $semi-bold;
      }
    }

    &__badge {
      .ant-badge-count {
        background-color: $badge-color;
      }
    }
  }

  .resume__details-btn-1 {
    background-color: $resume-button;
    border-radius: 5px;

    span {
      display: flex;
      flex-direction: row;
      width: min-content;
      height: min-content;
      padding: 2px;
      font-weight: $semi-bold;
      align-self: center;
    }
  }

  .resume__details-btn-2 {
    background-color: $resume-color;
    border-radius: 5px;

    span {
      display: flex;
      flex-direction: row;
      width: min-content;
      height: min-content;
      padding: 2px;
      font-weight: $semi-bold;
      align-self: center;
    }
  }

  .resume__details-btn-3 {
    // background-color: $white;
    border-radius: 5px;

    span {
      display: flex;
      flex-direction: row;
      width: min-content;
      height: min-content;
      padding: 2px;
      font-weight: $semi-bold;
      align-self: center;
    }
  }

  .resume__details__profilelink__options__left {
    margin-bottom: -20px;
    margin-right: 20px;
  }

  .resume__candidate__status {
    width: 100%;
    align-items: center;
    margin-bottom: 35px;
    border: 2px $sub-header;
  }

  .resume__details__save-btn--reverse {
    display: flex;
    background-color: $recruiter-color;
    height: min-content;
    padding: 5px;
    justify-content: center;
    border-radius: 5px;
  }
}

@media (min-width: $desktop-min) {
  .resume__quick__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    color: $sub-header;
    font-weight: $semi-bold;
    padding-left: 10px;
    height: min-content;
    overflow: hidden;
  }

  .resume__details__options__left {
    margin-top: 30px;
    color: $header;
  }

  .resume__details__options__left--option {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .resume__details__profilelink__options__left {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .resume__details__options__right {
    margin-top: 30px;
    margin-left: 10px;
    font-weight: $medium;
    color: $black-text;
  }

  .resume-profile-links {
    .logo {
      margin-right: 8px;
      fill: $gray;
      width: 100px;
      height: 100px;
      &:hover {
        fill: black;
        cursor: pointer;
      }

      &-background {
        fill: $very-light-grey-2;
      }
    }
  }

  .img__user__resume__details {
    font-weight: $medium;
    margin-top: 10px;
  }

  .resume__details {
    &__wrapper__save-btn--reverse {
      display: flex;
      justify-content: center;
    }

    &__save-btn--reverse {
      background-color: $recruiter-color;
      width: min-content;
      height: 32px;
      color: $white;
      padding-left: 25px;
      padding-right: 25px;
      margin-right: 5px;
      border-radius: 5px;
      span {
        @include display-flex(row);
        justify-content: center;
        align-items: center;
      }
    }
  }

  .resume__details__skills {
    margin-top: 30px;
  }

  .resume__details-btn-1 {
    background-color: $resume-button;
    width: min-content;
    height: min-content;
    padding: 8px;
    align-self: center;
    border-radius: 5px;
    font-weight: $semi-bold;
  }

  .resume__details-btn-2 {
    background-color: $resume_color;
    width: min-content;
    height: min-content;
    padding: 8px;
    align-self: center;
    border-radius: 5px;
    font-weight: $semi-bold;
  }

  .resume__details-btn-3 {
    // background-color: $white;
    width: min-content;
    height: min-content;
    padding: 8px;
    align-self: center;
  }

  .resume__candidate__status {
    width: 100%;
    align-items: center;
  }
}

.img__user__resume__details:hover {
  cursor: pointer;
}
