//common
.mr20 {
  margin-right: 20px;
}

.ml20 {
  margin-left: 20px;
}

.event__search--right {
  margin-top: 20px;
  justify-content: flex-end;

  button {
    margin-left: 10px;
    height: 38px;
  }

  @media (min-width: $tablet-min) and (max-width: $tablet-special-case-1) {
    margin-top: -20px;
  }
}

div.parent_sec .main-container {
  padding-left: 15px;
  padding-right: 15px;
}

.info-container {
  @include flex;

  justify-content: space-between;
}

//title event
div.events_title {
  h3 {
    @include medium-heading-mixin;

    line-height: 46px;
    display: flex;
    width: 100%;
    font-size: 16px;

    @media (max-width: $mobile) {
      width: unset;
    }

    span {
      margin-top: 0;
      font-weight: $semi-bold;
      position: relative;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
  }
}

.create-event__title-heading {
  margin-top: 10px;
  margin-bottom: 10px;

  @media (min-width: $desktop-min) {
    margin-top: 22px;
  }
}

.create-event__title-heading,
.edit-event__title-heading {
  color: $primary-color;

  .create-event__title-sub-heading,
  .edit-event__title-sub-heading {
    margin-left: 8px;
  }
}

.analytics-modal__analytics-heading {
  font-weight: $semi-bold;
  margin-top: 25px;
  margin-bottom: 25px;
}

.book-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
//pass events text
.passevents {
  color: $header !important;

  &::before {
    background-color: $header !important;
  }
}

.pro_steps_content .upload_sec .hint_text.create-event-hint {
  margin-top: 10px;
}
//filter
#event_title.head_sec_title {
  padding-bottom: 20px;
  position: relative;
  z-index: 11;

  div.filter_events {
    justify-content: flex-end;
    margin-top: 15px;

    .select_filter {
      margin: unset;

      .ant-select-selector {
        border: none;
      }
    }

    .border_non {
      .ant-select-selector {
        border: 1px solid $sub-header;
        background-color: $white !important;
        border-radius: $bradius5;
      }
    }
  }
}

//hint text
.eve_hint_text {
  font-size: $font14;
  padding: 0 0 20px;
  color: $sheader;
  margin-top: -10px;
}
//datepicker

//map button, buttons

.map_btn,
.promocode_btn,
.promo-code__add-btn,
.event-ticket__add-ticket-btn,
.refund__add-btn,
.company__add-btn {
  width: 100%;
  height: 32px;
  max-width: 168px;
  background-color: $primary-color;
  color: $white;
  border: 0;
  margin-left: 35px;
  position: relative;
  top: -11px;
  border-radius: $bradius3;
  font-weight: $regular;

  @media (min-width: $desktop-min) {
    width: 100%;
    height: 42px;
    max-width: 168px;
  }
}

.map_btn,
.event-ticket__add-ticket-btn {
  margin-top: 25px;
}

@mixin pseudo-normal-effect($element) {
  &:#{$element} {
    background-color: $primary-color;
    color: $white;
  }
}

.promo-code__add-btn,
.refund__add-btn {
  @include pseudo-normal-effect(hover);
  @include pseudo-normal-effect(focus);
  @include pseudo-normal-effect(active);

  top: 2px;

  @media (min-width: $desktop-min) {
    top: 18px;
  }
}

.addcompany_btn {
  top: 0;
}

.promocode_btn {
  max-width: 151px;
  height: 38px;
  top: 0;
  margin-top: 32px;
  margin-bottom: 61px;
}
//input
#event_title.head_sec_title {
  .search_input {
    width: 70%;

    input {
      border-color: $sub-header;
      font-weight: $regular;
      background: $main-background;
      border-radius: $bradius5;
      margin-right: 20px;
    }
  }
}
//multiple select
.ant-select-multiple {
  .anticon-close svg {
    font-size: unset;
    position: unset;
    top: unset;
    right: unset;
  }
}
//arrow
div.filter_events {
  .select_type svg,
  .select_filter svg {
    font-size: 13px;
  }
}
//button create event
.create_eve_btn {
  @include cursorPointer;
  @include large-font-mixin;

  background-color: $primary-color;
  color: $white;
  width: 124px;
  height: 32px;
  border: 0;
  border-radius: $bradius3;
  white-space: nowrap;
}
//divider
.datewise div.divider_Text {
  margin-bottom: 20px;

  .ant-divider-inner-text {
    padding-left: 0;

    h4 {
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
  }

  &::before {
    width: 0;
  }

  &::after {
    border-top: 1px solid $icon_color !important;
  }
}

.recommended-events-card__container__front,
.recommended-events-card__container__back {
  width: 100%;
}

.analytics-modal__event__box {
  margin-top: 10px;
  margin-bottom: 10px;
}

//.pro_steps_content
.pro_steps_content {
  overflow: hidden;
  background-color: $white;
  position: relative;
}

.event-analytics__wrapper {
  position: absolute;
  bottom: 0;
  width: 90%;
}
//create event page
.create_event_sec .pro_steps_content .content_subtitle,
.create_event_sec .pro_steps_content .green-header {
  color: $sheader;
  font-weight: $medium;
  margin-bottom: 15px;
  margin-top: 15px;

  @media (min-width: $desktop-min) {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

.create_event_sec {
  form {
    @include display-flex(column);
  }
}

//change_logo
.change_logo {
  text-align: center;
  max-width: 140px;
  width: 100%;

  .ant-avatar {
    width: 130px;
    height: 130px;
    display: block;

    span {
      max-width: 150px;
      width: 100%;
    }
  }

  button {
    border: 0;
    margin-top: 5px;

    span {
      font-size: $font15;
      color: $ctextcolor;
    }
  }
}
//editpen
.editpen {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 55px;
  margin-right: 40px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: $shadow2;

  svg {
    width: 25px;
    font-size: 25px;
    fill: $primary-color;
  }
}

.recruiter_events,
.candidate_events,
.organizer_events,
.recruiter_dashboard,
.candidate_dashboard,
.candidate_dashboard {
  .ongoing-placeholder.par_upcoming {
    margin-bottom: 0;
    margin-top: 0;
  }
}

@mixin pseudo-cancel-btn-effect($element) {
  &:#{$element} {
    background-color: transparent;
    color: $gray;
  }
}
//cancelrest
.cancel-reset-btn {
  @include pseudo-cancel-btn-effect(hover);
  @include pseudo-cancel-btn-effect(focus);
  @include pseudo-cancel-btn-effect(active);

  border: 0;
  color: $gray;
  font-weight: $medium;
  font-size: $font16;
  background-color: transparent;
}
//promo lineborder
.promo-code_lineborder,
.event-ticket_line-border,
.refund__lineborder {
  clear: both;
  border: 1px dashed $gray;
  padding: 30px 20px 20px 30px;
  border-radius: $bradius3;
  margin-bottom: 20px;

  .remove_btn,
  .remove-btn {
    font-size: $font16;
    color: $live_color;
    padding-top: 0;
    margin-top: 0;
    font-weight: $medium;
    cursor: pointer;
  }

  .event-ticket__remove,
  .refund__remove_btn,
  .promo-code__remove {
    border: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
}

.event-ticket_line-border,
.add-refund-link {
  background-color: $main-background;
}

//back_itsubmit
.back_itsubmit {
  //title
  .title_explore .exploretitle {
    color: $sheader2;
    margin-bottom: 15px;
  }

  .fullslider .live-event h5 {
    font-size: $font14;
    display: flex;
    align-items: center;
    top: 40px;
    height: 37px;

    span {
      display: block;
      line-height: 10px;

      svg {
        font-size: 20px;
        width: 20px;
      }
    }
  }
  //bookeventsec
  .bookeventsec h2 {
    font-weight: $bold;
  }
  //promote_eve
  .promote_eve {
    color: $sheader;
    font-size: $font20;
    margin-right: 15px;
  }

  .bookeventsec .blue_icon_btn {
    padding: 10px 10px 2px;
    background-color: $primary-color;
  }

  .round_icon {
    border-radius: 50% !important;
    box-shadow: $shadow2;

    svg {
      width: 22px;
      fill: $white;
    }
  }
  //
  .dateeventsec ul {
    li {
      margin-right: 50px;

      @media (max-width: $mobile) {
        margin-bottom: 15px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    li.blue_li {
      color: $primary-color;

      svg {
        fill: $primary-color;
      }
    }
  }

  .tag_sec h6 {
    padding-bottom: 20px;
  }

  div.para_content,
  p.para_content {
    margin: 20px 0 20px;
  }

  .org_tag {
    h5 {
      font-weight: $semi-bold;
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }

  .org_tag h4::after {
    width: 37px;
    height: 6px;
  }
}

.TicketPricing {
  .fr {
    color: $gray;

    svg {
      margin-left: 8px;
    }
  }
}

.organizer-info-section__view {
  margin-bottom: 15px;

  @media (min-width: $desktop-min) {
    margin-bottom: 20px;
  }

  & a:hover {
    cursor: pointer;
    color: $icon_color;
  }
}

.event_analytic {
  background-color: $primary-color;
  border-radius: $bradius10;
  padding: 32px 20px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  .view-event-analytics__container {
    @include ul-default;

    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    &__column {
      display: inline-block;

      .icon_bx {
        background: #a3d6cd;
        width: 45px;
        height: 45px;
        border-radius: 9px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;

        svg {
          width: 25px;
          fill: $white;
        }
      }

      .text_rgt {
        float: left;
        padding-left: 10px;

        h5 {
          padding: 0;
          margin: 0;
          margin-top: 5px;
          color: $white;
        }

        p {
          color: $white;
          font-weight: $semi-bold;
        }
      }
    }
  }

  .circle_1 {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background: #6ac8b6;
    position: absolute;
    right: -21px;
    top: -48px;
    z-index: 1;
  }

  .circle_2 {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: #5ac4af;
    position: absolute;
    right: -61px;
    bottom: -48px;
    z-index: 0;
  }
}

.recommended-events-card__container {
  width: 100%;
}

#recommended-events-card__container__front {
  width: 100%;
}

// cancelrequestmodal
.cancelrequest {
  width: 66% !important;
  border-radius: $bradius5;
  box-shadow: $shadow2;

  .ant-modal-body {
    padding-left: 3%;

    h4 {
      font-size: $font24;
      color: $custom-red;
      font-weight: $medium;
    }

    h5 {
      font-size: $font18;
      color: $tgtextcolor;
      margin-bottom: 40px;
      font-weight: $semi-bold;
    }

    .info-header {
      color: $tgtextcolor;
      font-weight: $semi-bold;
      font-size: $font18;
      padding-bottom: 12px;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    border-top: none;

    .ant-btn {
      color: $tgtextcolor;
      font-weight: $medium;
      font-size: $font18;
      border: 0;
    }

    .ant-btn.ant-btn-primary {
      color: $white;
      font-weight: $regular;
      font-size: $font18;
      background-color: $custom-red;
      padding: 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.attending-companies__header {
  font-weight: bold;
  color: $header;
}

.attending-companies__button--right {
  color: gray;
  display: flex;
  flex-direction: row-reverse;
}

.attending-companies__wrapper {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-analytics-modal.analytics-modal {
  .analytics-modal__header {
    color: $candidate-color;
  }

  .responsive-modal-header__container {
    background-color: $candidate-color;
  }

  .created_eve .circle_1 {
    background-color: $candidate-color-light-shade;
  }

  .created_eve .circle_2 {
    background-color: $candidate-color-light-shade;
  }

  .event-analytics__container {
    background-color: $candidate-color;

    .event-analytics__container__wrapper--icon--left {
      background-color: $candidate-color-light-shade;
    }
  }
}

.view-event__pie-chart {
  width: 130px;
  height: 85px !important; // overriding default height as it's breaking page in mobile screen `.ant-pie-chart`
  font-size: 15px;
  margin-bottom: 5px;

  @media (max-width: $mobile) {
    margin-left: -20px;
  }

  span {
    font-size: 12px;
  }

  .g2-tooltip {
    width: 200px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      margin-right: -5px;
    }
  }
}

.color_dot {
  float: right;
}
//component default style is Overriding custom style
.flex_center_end .view-event__pie-chart .g2-html-annotation {
  font-size: 16px !important;
  color: $bluecolor !important;
  font-weight: $medium !important;
}

.color_dot_value {
  margin-left: 10px;
}

.color_dot p .color_dot_value {
  font-weight: bold;
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: 0;
}

.attending-companies__container {
  display: flex;
  padding: 20px;
  background-color: $main-background;
  flex-direction: column;
  align-items: center;
  align-self: center;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.attending-companies__container--image {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.attending-companies__container--title {
  color: gray;
}

.recommended-events__button {
  display: flex;
  flex-direction: row-reverse;
}

.recommended-events__button--right {
  width: fit-content;
  color: $sub-header;
  font-size: 16px;
  font-weight: 500;
}

.recommended-events-card__wrapper {
  width: 230px;
  padding: 0 10px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.recommended-events__button--right:hover {
  cursor: pointer;
}

.recommended-events__container {
  margin-bottom: 20px;
  margin-top: 20px;

  @media (min-width: $desktop-min) {
    margin-top: 30px;
  }
}

.recommended-events__container__cards {
  padding-top: 10px;
  display: flex;
  padding: 20px 0;
  color: $gray;
  gap: 20px;

  @media (max-width: $mobile) {
    padding-bottom: 0;
  }
}

.recommended-events-card__container__row__details {
  height: 175px;

  &__view {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -10px;
    align-self: center;
    background: $white;
    margin-bottom: 5px;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      &--center {
        width: 141px;
        font-weight: $semi-bold;
        border-radius: 3px;
      }
    }
  }
}

.ongoing-events__quick-information {
  color: $tgtextcolor;
  margin-top: -45px;
  margin-bottom: 10px;
  font-weight: $semi-bold;

  @media (min-width: $desktop-min) {
    margin-left: 5px;
  }

  @media (max-width: $mobile) {
    margin-top: -30px;
  }
}

.recommended-events-card__container {
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 15px #00000029;
  border-radius: 5px;
  opacity: 1;
  justify-content: space-around;
}

.recommended-events-card__container__row__details__view__icon--left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: $desktop-min) {
    margin-right: 80px;
  }

  svg {
    fill: $placeholder;
    width: 10px;
    height: 10px;
  }

  svg:hover {
    width: 18px;
    height: 18px;
  }
}

.recommended-events-card__container__row__details__view__icon--right {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: $placeholder;
    width: 10px;
    height: 10px;
  }

  svg:hover {
    width: 18px;
    height: 18px;
  }
}

.recommended-events-card__container__row__image {
  width: 100%;
  height: 170px;
  border-radius: 5px;
  image-rendering: pixelated;

  &__wrapper {
    position: relative;
    width: 100%;
    height: 170px;
    min-width: 160px;
  }
}

.datewise div.divider_Text {
  margin-top: 20px;
}

.ongoing-booked-events {
  &.par_upcoming {
    h5.upcoming {
      margin-bottom: 0;
    }
  }

  &__filter {
    display: flex;
    flex-direction: row-reverse;

    &--right {
      width: 150px;
    }
  }
}

.recommended-events-card__container__row__details {
  position: relative;
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  flex: 1;

  div {
    h5 {
      font-size: 12px;
    }
    p {
      font-size: 16px;
    }
  }

  &__name {
    color: $black;
    margin: 5px;
    font-weight: bold;
    line-height: 1.1em;
    height: 1.1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__description {
    color: $ctextcolor;
    margin: 12px 5px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__jobs {
    display: flex;
    margin: 5px;
    align-content: center;

    svg {
      width: 17px;
      height: 18px;
    }

    &--left {
      font-weight: bold;
      margin-left: 5px;
    }
  }
}

.upcoming-events {
  margin-top: 20px;
}

// Note: over-ride ant carousel dots
.ant-carousel .slick-dots li button {
  background-color: $gray;
}

.ongoing-booked-events__header {
  margin-bottom: 10px;
}

.ongoing-booked-events__join-button--right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .join-event-button {
    margin: 0px 0px 0px 10px;
  }
}

.ongoing-booked-events__title--left--center {
  display: flex;
  text-align: center;
  align-items: center;

  h5 {
    margin-bottom: 0 !important;
  }
}

.ongoing-booked-events__join-button--right button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recruiter_events .ongoing-booked-events__join-button--right button {
  color: $white;
  background-color: $recruiter-color;
  border-radius: 5px;
  padding: 0;
}

.candidate_events .ongoing-booked-events__join-button--right button {
  color: $white;
  background-color: $candidate-color;
}

.event-date-type--left {
  display: block;
  margin: 20px 0;

  h6 {
    color: $gray;
    line-height: 23px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin: 20px 0;

    span {
      @include display-flex('row');

      align-items: center;

      svg {
        width: 17px;
        height: 17px;
        fill: gray;
        margin-right: 15px;
        position: relative;
        top: 0;
      }
    }
  }
}

.date_sec {
  display: flex;

  h6 {
    color: $gray !important;
    line-height: 23px;
    font-weight: 400;
    display: flex;

    svg {
      width: 17px;
      fill: gray;
      margin-right: 15px;
      position: relative;
      top: 0;
    }
  }
}

.candidate_events .explore-events__options ul li button {
  background-color: #efefef;
  border-color: #c3c3c3;
  color: $ctextcolor;
  border: 0;
}

.recruiter_events .explore-events__options ul li button {
  border: 0;
}

.recommended-events-card__container__row__details__cost {
  color: $ctextcolor;
  margin: 5px;
  display: flex;
  margin-bottom: 10px;

  &--bold {
    font-weight: bold;
  }

  &--small {
    margin-left: 5px;
  }
}

.recommended-events-card__container__row__type--top {
  width: 100%;
  height: 21px;
  max-width: 90px;
  background: $white;
  box-shadow: 0 3px 15px #00000075;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 0;
  color: $live_color;
  font-weight: 500;
  font-size: $font10;
  margin-top: 0;

  span {
    display: flex;
    align-content: center;
    justify-content: center;

    svg {
      width: 14px;
      font-size: 18px;
      fill: $live_color;
      margin-left: 7px;
      line-height: 16px;
      vertical-align: sub;
    }
  }
}

.recommended-events-card__container__row__view-details {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 40px;
  height: 355px;
  opacity: 0.8;
  border-radius: 5px;

  &__postedAt {
    color: $white;
    height: min-content;
  }

  &__name {
    font-weight: bold;
    color: $white;
    line-height: 1.1em;
    height: 1.1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__description {
    color: $white;
    height: min-content;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__jobs {
    display: flex;
    align-content: center;
    height: min-content;

    svg {
      color: $white;
      fill: $white;
      width: 14px;
      height: 12px;
      justify-content: center;
      align-self: center;
    }

    &--left {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
  }

  &__location {
    display: flex;
    align-content: center;
    height: min-content;

    svg {
      color: $white;
      fill: $white;
      width: 14px;
      height: 12px;
      justify-content: center;
      align-self: center;
    }

    &--fade {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
  }

  &__time {
    display: flex;
    align-content: center;
    height: min-content;

    svg {
      color: $white;
      fill: $white;
      width: 14px;
      height: 12px;
      justify-content: center;
      align-self: center;
    }

    &--fade {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
    }
  }

  &__cost {
    display: flex;
    margin-bottom: 10px;
    height: min-content;

    &--bold {
      font-weight: bold;
      color: $white;
    }

    &--small {
      color: $white;
      margin-left: 5px;
    }

    &__header {
      color: $white;
    }
  }

  &__button {
    width: 100%;
    position: absolute;
    bottom: 10px;
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--center {
      width: 130px;
      height: 23px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 12px;
        font-weight: $semi-bold;
      }
    }
  }
}

.events_title .title_sec h3 {
  margin-top: 0;
}

#event_title.head_sec_title {
  padding-bottom: 0;
  padding-top: 0;
}

#event_title.head_sec_title {
  padding-bottom: 0;
}

.recommended-events-card__container__row__details__view {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .recommended-events-card__container__row__details__view__button {
    margin-left: 26px;

    @media (min-width: $desktop-min) {
      margin-left: 38px;
    }
  }

  .recommended-events-card__container__row__details__view__icon--left {
    margin-bottom: -22px;
  }
}

.recommended-events-card__container__row__details__view__button--center {
  width: 120px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    @include medium-font-mixin;

    font-weight: 600;
    border-radius: 3px;
  }
}

.recommended-events-card__container__back {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 355px;
}

.recommended-events-card__container__row__type--top {
  background-color: $white;
}

.organizer_events div.events_title h3 {
  color: $primary-color;
}
//recruiter theme color
.recruiter_events div.events_title h3 {
  color: $sheader2;
  line-height: 46px;
  font-size: 20px;
  font-weight: $semi-bold;
}

.organizer_events {
  div.events_title h3,
  .title_sec h3 {
    color: $primary-color;
  }

  .org_tag h4::after {
    background-color: $primary-color;
  }
}
//recruiter theme color

.recruiter_events .createdevents .created_eve .circle_1 {
  background: #ffcf6e;
}

.recruiter_events .createdevents .created_eve .circle_2 {
  background: #ffc758;
}

.recruiter_events .createdevents .created_eve ul li .left {
  background-color: #fed27c;
}

.recruiter_events {
  .circle_box,
  .par_ongoingsec .ongoing_evnt .text_sec h5::before,
  .notifications .circle_light,
  .createdevents .created_eve,
  .par_upcoming h5.upcoming::before,
  .circle_box,
  .bookeventsec .blue_icon_btn,
  .org_tag h4::after,
  .section-heading::before {
    background-color: $recruiter-color;
  }
}

.recruiter_events {
  .par_ongoingsec .ongoing_evnt .text_sec h5,
  .par_ongoingsec .ongoing_evnt .text_sec .candidate ul li b,
  .par_upcoming h5.upcoming,
  .earning h4,
  .dateeventsec ul li.blue_li,
  .section-heading,
  .head_sec_title .title_sec h3,
  .back_itsubmit .dateeventsec ul li.blue_li {
    color: $recruiter-color;
    font-weight: $semi-bold;
  }
}

.recruiter_events .earning .floatr svg,
.recruiter_events .title_sec .right_info svg,
.recruiter_events .dateeventsec ul li.blue_li svg {
  fill: $recruiter-color;
}

.public_events {
  .par_ongoingsec .ongoing_evnt .text_sec h5,
  .par_ongoingsec .ongoing_evnt .text_sec .candidate ul li b,
  .par_upcoming h5.upcoming,
  .earning h4,
  .dateeventsec ul li.blue_li,
  .section-heading,
  .head_sec_title .title_sec h3,
  .back_itsubmit .dateeventsec ul li.blue_li {
    color: $bluecolor;
  }
}

.public_events .earning .floatr svg,
.public_events .title_sec .right_info svg,
.public_events .dateeventsec ul li.blue_li svg {
  fill: $bluecolor;
}
//candidate theme color
.candidate_events div.events_title h3 {
  color: $sheader2;
  line-height: 46px;
  font-weight: 700;
  float: left;

  @media (max-width: $mobile) {
    margin-top: 10px;
  }
}

.candidate_events .createdevents .created_eve .circle_1 {
  background: $candidate-color-light-shade;
}

.candidate_events .createdevents .created_eve .circle_2 {
  background: $candidate-color-light-shade;
}

.candidate_events .createdevents .created_eve ul li .left {
  background-color: $notifications-blue;
}

.candidate_events .circle_box,
.candidate_events .par_ongoingsec .ongoing_evnt .text_sec h5::before,
.candidate_events .notifications .circle_light,
.candidate_events .createdevents .created_eve,
.candidate_events .par_upcoming h5.upcoming::before,
.candidate_events .circle_box,
.candidate_events .bookeventsec .blue_icon_btn,
.candidate_events .org_tag h4::after,
.candidate_events .section-heading::before {
  background-color: $candidate-color;
}

.recruiter-analytics-modal.analytics-modal {
  .event-analytics__container {
    background-color: $recruiter-color;

    .analytics-item .event-analytics__container__wrapper--icon--left {
      background-color: #fed27c;
    }
  }

  .created_eve .circle_1,
  .created_eve .circle_2 {
    background-color: #ffcf6e;
  }

  .analytics-modal__header {
    color: $recruiter-color;
  }

  .responsive-modal-header__container {
    background-color: $recruiter-color;
  }
}

.analytics-item__select-container {
  margin-top: 20px;
}

.candidate_events .par_ongoingsec .ongoing_evnt .text_sec h5,
.candidate_events .par_ongoingsec .ongoing_evnt .text_sec .candidate ul li b,
.candidate_events .par_upcoming h5.upcoming,
.candidate_events .earning h4,
.candidate_events .dateeventsec ul li.blue_li,
.candidate_events .section-heading,
.candidate_events .head_sec_title .title_sec h3 {
  color: $candidate-color;
  margin-bottom: 20px;
}

.candidate_events .earning .floatr svg,
.candidate_events .title_sec .right_info svg,
.candidate_events .dateeventsec ul li.blue_li svg {
  fill: $candidate-color;
}

// Add event ticket
.event-ticket__price-container {
  display: flex;

  #ticket-currency-type {
    width: 120px;
    height: 30px;
    padding-left: 11px;
    border-right: 0;
    background-color: $white;
  }
}

.event-ticket__add-ticket-btn {
  @include pseudo-normal-effect(hover);
  @include pseudo-normal-effect(focus);
  @include pseudo-normal-effect(active);

  max-width: 20rem;
  top: -62px;
  left: 10.5rem;

  @media (min-width: $desktop-min) {
    margin-top: 5px;
  }
}

// Company
.company__card {
  background-color: $main-background;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 2rem 0;
}

.company__add-btn {
  @include pseudo-normal-effect(hover);
  @include pseudo-normal-effect(focus);
  @include pseudo-normal-effect(active);

  top: 0;
}

@media (max-width: 1200px) {
  .attending-companies__container--image {
    width: 60px;
    height: 60px;
  }

  .attending-companies__container--title {
    font-size: 15px;
    color: gray;
  }
}

@media (max-width: $mobile) {
  .event-analytics__wrapper {
    position: relative;
    width: 100%;
  }

  .event__search--right {
    margin-top: 0;
  }

  .analytics-modal__select-jobs-container {
    margin-top: 10px;
  }

  #event_title.head_sec_title .search_input {
    width: 100%;
  }

  .recommended-events-card__container__row__image__wrapper {
    height: 326px;
    border-radius: 5px;
  }

  .book-event-section__header {
    margin-top: 25px;
  }

  .attending-companies__container--image {
    width: 45px;
    height: 45px;
  }

  .attending-companies__container--title {
    font-size: 10px;
    color: gray;
  }

  .color_dot p {
    font-size: 12px;
  }

  .flex_left_res {
    justify-content: start;
  }

  .recommended-events-card__container__row {
    height: 326px;

    &__image {
      height: 326px;
    }

    &__details {
      height: 254px;
      margin-top: -10px;

      &__name.small-font {
        font-size: 15px;
        margin-bottom: 15px;
      }

      &__jobs {
        margin-bottom: 15px;

        svg {
          width: 20px;
          height: 21px;
        }

        &--left.small-font {
          font-size: 13px;
        }
      }

      &__cost {
        margin-bottom: 15px;

        &--bold.small-font {
          font-size: 15px;
          font-weight: bolder;
        }

        &--small.small-font {
          font-size: 15px;
          font-weight: normal;
          margin-left: 5px;
        }
      }

      &__description.small-font {
        margin-bottom: 15px;
        font-size: $font13;
      }
    }
  }

  .recommended-events-card__wrapper {
    padding: 0;
    width: 100%;
  }

  .recommended-events-card__container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    height: 580px;
  }

  .recommended-events__container {
    margin-top: 60px;
  }

  .recommended-events__container__heading {
    font-weight: 600 !important;
  }

  .recommended-events__button .select_filter .ant-select {
    width: 168px;
  }

  .recommended-events__button .select_filter .ant-select .ant-select-selector {
    width: 168px;
  }

  .recommended-events-card__container__row__details__view__icon--left {
    svg {
      fill: $placeholder;
      width: 20px;
      height: 23px;
    }

    svg:hover {
      width: 28px;
      height: 26px;
    }
  }

  .recommended-events-card__container__row__details__view__icon--right {
    svg {
      fill: $placeholder;
      width: 23px;
      height: 20px;
    }

    svg:hover {
      width: 28px;
      height: 26px;
    }
  }

  .recommended-events-card__container__row__details__view {
    bottom: 10px;
    margin-bottom: 0;
  }

  .recommended-events-card__container__row__details__view__button--center span {
    font-size: 16px;
  }

  .recommended-events-card__container__row__details__view__button--center,
  .recommended-events-card__container__row__view-details__button--center {
    width: 226px;
    height: 44px;
    font-size: 16px;
    border-radius: 5px;

    span {
      font-size: $font16;
    }
  }

  .ongoing-booked-events__join-button--right button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .datewise .future_box {
    padding: 13px 18px;
  }

  .datewise .future_box .action_sec div.left h6 {
    font-size: 12px !important;

    svg {
      width: 19px;
      height: 19px;
    }
  }

  .par_upcoming > .datewise > .future_box {
    .action_sec {
      .date_sec {
        h6 {
          svg {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }

  .date_sec {
    display: block;
  }

  .vw-details {
    display: flex;
    flex-direction: column-reverse;
  }

  .datewise .future_box .action_sec .vw-details a {
    height: max-content;

    & > div {
      font-size: 12px;

      svg {
        width: 12px;
        height: 10px;
        top: 0;
        margin-left: 0;
      }
    }
  }

  #event_title.head_sec_title {
    padding-bottom: 0;
    padding-top: 0;
    margin-top: -20px;

    .events_title {
      margin-top: 20px;
    }
  }

  .events__create--right {
    display: flex;
    flex-direction: row-reverse;

    button {
      width: 124px;
      height: 34px;
      font-size: $font14;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  div.events_title {
    h3 {
      font-weight: $semi-bold;
    }
  }

  .upcoming-events > .par_upcoming,
  .par_upcoming.par_past {
    & > h5 {
      &.upcoming {
        font-size: $font14;
      }

      &.upcoming::before {
        width: 9px;
        height: 30px;
      }
    }
  }

  .upcoming-events > .par_upcoming .recommended-events__container,
  .ongoing-booked-events.par_upcoming {
    h5 {
      &.upcoming {
        font-size: $font14;
      }

      &.upcoming::before {
        width: 9px;
        height: 30px;
      }
    }
  }

  .content_box .title_sec h3 {
    font-weight: bold;
  }

  .datewise .divider_Text > h4 {
    font-size: $font13;
  }

  .ongoing-booked-events
    .ongoing-booked-events__header
    > .ongoing-booked-events__title--left--center
    > h5 {
    font-size: $font14;
  }

  .recommended-events__button--right {
    font-size: $font12;
  }

  .event_analytic {
    .view-event-analytics__container {
      &__column {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  .back_itsubmit {
    .org_tag {
      p.tag_title {
        width: 100%;
      }
    }

    div.para_content,
    p.para_content {
      font-size: $font12;
      margin: 0;
      margin-bottom: 10px;
      margin-top: -20px;
    }

    .title_explore .exploretitle {
      font-size: $font14;
    }
  }

  .faq_terms .ant-collapse-header {
    @include medium-heading-mixin;
  }

  .event_analytic .view-event-analytics__container__column .text_rgt {
    h5 {
      font-size: $font16;
    }

    p {
      font-size: $font18;
      font-weight: $semi-bold;
    }
  }

  .event_analytic .circle_2 {
    top: 60px;
  }

  .attending-companies__header {
    font-size: $font14;
  }

  .attending-companies__button--right {
    font-size: $font12;
  }

  .back_itsubmit .fullslider .live-event h5 {
    top: 10px;
    right: 10px;
  }

  .organizer_events,
  .recruiter_events,
  .candidate_events {
    .book-event-section__book-area {
      flex-direction: row;
      padding-top: 30px;
      align-items: center;
      min-width: 120px;

      & .share-button svg {
        width: 15px;
        height: 15px;
      }

      & .share-button span > svg {
        width: 15px;
        height: 15px;
      }

      & .share-button {
        width: 27px;
        height: 27px;
        margin-left: 10px;
        margin-right: 20px;
      }

      & h3 {
        margin: 0;
      }
    }

    .book-event-section {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }

  .recruiter_events {
    .book-event-section__book-area {
      @media (max-width: $mobile) {
        padding-top: 10px;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  .candidate_events {
    .book-event-section__book-area {
      @media (max-width: $mobile) {
        padding-top: 10px;
        align-items: flex-end;
        justify-content: space-between;
      }

      @media (max-width: $mobile-special-case-2) {
        flex-wrap: wrap-reverse;
        gap: 15px;
      }
    }
  }

  .main-container .viewdetails,
  .main-container .vw-details {
    margin-top: -20px;
    padding-bottom: 0;
  }

  .recommended-events-card__container__row__view-details {
    height: 580px;
  }

  .recommended-events-card__container__row__type--top {
    width: 100%;
    height: 28px;
    max-width: 104px;
    background: $white;
    box-shadow: 0 3px 15px #00000029;
    border-radius: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px 0;
    color: #f5222d;
    font-weight: 500;
    font-size: 11px;
    margin-top: 0;

    span {
      display: flex;
      align-content: center;
      justify-content: center;

      svg {
        width: 18px;
        font-size: 18px;
        fill: $live_color;
        margin-left: 7px;
        line-height: 16px;
        vertical-align: sub;
      }
    }
  }

  .recommended-events-card__container__row__view-details {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    opacity: 0.9;

    &__postedAt {
      font-size: 12px;
      color: $white;
      height: min-content;
    }

    &__name {
      font-size: 14px;
      font-weight: bold;
      color: $white;
      height: min-content;
    }

    &__description {
      font-size: 16px;
      color: $white;
      height: min-content;
    }

    &__jobs {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 17px;
        height: 18px;
        justify-content: center;
        align-self: center;
      }

      &--left {
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__location {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 17px;
        height: 18px;
        justify-content: center;
        align-self: center;
      }

      &--fade {
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__time {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 17px;
        height: 18px;
        justify-content: center;
        align-self: center;
      }

      &--fade {
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__cost {
      display: flex;
      margin-bottom: 10px;
      height: min-content;

      &--bold {
        font-size: 12px;
        font-weight: bold;
        color: $white;
      }

      &--small {
        font-size: 12px;
        color: $white;
        margin-left: 5px;
      }

      &__header {
        font-size: 12px;
        color: $white;
      }
    }

    &__button {
      width: 100%;
      position: absolute;
      bottom: 15px;
      margin-left: -20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--center {
        width: 142px;
        font-size: 12px;
        font-weight: $semi-bold;
      }
    }
  }
}

@media (min-width: $desktop-min) {
  .recommended-events-card__container {
    height: 455px;
  }

  div.parent_sec .main-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
  }

  .recommended-events-card__container__back {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 455px;
  }

  .recommended-events-card__container__row__image {
    height: 241px;

    &__wrapper {
      height: 241px;
    }
  }

  .event-date-type--left h6 {
    svg {
      width: 23px;
    }
  }

  .date_sec h6 {
    svg {
      width: 23px;
      height: 23px;
    }
  }

  #event_title.head_sec_title .form-group.search_input {
    max-width: 320px;
  }

  .create_eve_btn {
    height: 40px;
    margin-top: 10px;
  }

  .fullslider {
    .right_slider {
      .ant-carousel img {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .back_itsubmit {
    //title
    .title_explore .exploretitle {
      color: $sheader2;
      margin-bottom: 15px;
    }

    .fullslider .live-event h5 {
      font-size: $font14;
      display: flex;
      align-items: center;
      top: 40px;
      height: 37px;

      span {
        display: block;
        line-height: 10px;

        svg {
          font-size: 20px;
          width: 20px;
        }
      }
    }
    //bookeventsec
    .bookeventsec h2 {
      font-weight: $bold;
    }
    //promote_eve
    .promote_eve {
      color: $sheader;
      font-size: $font20;
      margin-right: 15px;
    }

    .bookeventsec .blue_icon_btn {
      padding: 10px 10px 2px;
      background-color: $primary-color;
    }

    .round_icon {
      border-radius: 50% !important;
      box-shadow: $shadow2;

      svg {
        width: 22px;
        fill: $white;
      }
    }
  }

  .event_analytic {
    background-color: $primary-color;
    border-radius: $bradius10;
    padding: 35px 20px;
    margin-bottom: 50px;
    overflow: hidden;
    position: relative;

    .view-event-analytics__container {
      @include ul-default;

      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      &__column {
        display: inline-block;

        .icon_bx {
          background: #a3d6cd;
          width: 60px;
          height: 58px;
          border-radius: 9px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          float: left;

          svg {
            width: 24px;
            fill: $white;
          }
        }

        .text_rgt {
          float: left;
          padding-left: 10px;

          h5 {
            padding: 0;
            margin: 0;
            margin-top: 10px;
            color: $white;
          }

          p {
            color: $white;
            font-weight: 500;
          }
        }
      }
    }

    .circle_1 {
      width: 145px;
      height: 145px;
      border-radius: 50%;
      background: #6ac8b6;
      position: absolute;
      right: -21px;
      top: -48px;
      z-index: 1;
    }

    .circle_2 {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      background: #5ac4af;
      position: absolute;
      right: -61px;
      bottom: -48px;
      z-index: 0;
    }
  }

  .back_itsubmit {
    //title
    .title_explore .exploretitle {
      color: $sheader2;
      margin-bottom: 15px;
    }

    .fullslider .live-event h5 {
      font-size: $font14;
      display: flex;
      align-items: center;
      top: 40px;
      height: 37px;

      span {
        display: block;
        line-height: 10px;

        svg {
          font-size: 20px;
          width: 20px;
        }
      }
    }
    //bookeventsec
    .bookeventsec h2 {
      font-weight: $bold;
    }
    //promote_eve
    .promote_eve {
      color: $sheader;
      font-size: $font20;
      margin-right: 15px;
    }

    .bookeventsec .blue_icon_btn {
      padding: 10px 10px 2px;
      background-color: $primary-color;
    }

    .round_icon {
      border-radius: 50% !important;
      box-shadow: $shadow2;

      svg {
        width: 22px;
        fill: $white;
      }
    }
    //
    .dateeventsec ul {
      li {
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .tag_sec h6 {
      padding-bottom: 20px;
    }

    div.para_content,
    p.para_content {
      margin: 40px 0 50px;
    }

    .org_tag {
      h5 {
        font-weight: $semi-bold;
        padding-top: 10px;
        padding-bottom: 25px;
      }
    }
  }

  .TicketPricing {
    .fr {
      color: $gray;

      svg {
        font-size: 12px;
        margin-left: 8px;
      }
    }
  }

  .recommended-events-card__container__row__details {
    height: 214px;
  }

  .recommended-events-card__container__row__details__view__button--center {
    width: 141px;
    height: 32px;

    span {
      font-weight: 600;
      border-radius: 3px;
    }
  }

  .recommended-events-card__container__row__details__view__icon--right {
    svg {
      width: 16px;
      height: 15px;
    }

    svg:hover {
      width: 22px;
      height: 22px;
    }
  }

  .recommended-events-card__container__row__details__view__icon--left {
    svg {
      width: 16px;
      height: 15px;
    }

    svg:hover {
      width: 22px;
      height: 22px;
    }
  }

  .recommended-events-card__container__row__view-details {
    height: 455px;
  }

  .recommended-events-card__container__row__type--top {
    max-width: 104px;
    width: 100%;
    background: $white;
    box-shadow: 0 3px 15px #00000029;
    border-radius: 20px;
    position: absolute;
    right: 18px;
    top: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px 0;
    color: $live_color;
    font-weight: 500;
    font-size: 11px;
    margin-top: 0;

    span {
      display: flex;
      align-content: center;
      justify-content: center;

      svg {
        font-size: 18px;
        width: 18px;
        fill: $live_color;
        margin-left: 7px;
        line-height: 16px;
        vertical-align: sub;
      }
    }
  }

  .recommended-events-card__container__row__view-details {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    height: 455px;
    opacity: 0.9;

    &__postedAt {
      color: $white;
      height: min-content;
    }

    &__name {
      font-weight: bold;
      color: $white;
    }

    &__description {
      color: $white;
    }

    &__jobs {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 17px;
        height: 18px;
        justify-content: center;
        align-self: center;
      }

      &--left {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__location {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 17px;
        height: 18px;
        justify-content: center;
        align-self: center;
      }

      &--fade {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__time {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 17px;
        height: 18px;
        justify-content: center;
        align-self: center;
      }

      &--fade {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__cost {
      display: flex;
      margin-bottom: 10px;
      height: min-content;

      &--bold {
        font-weight: bold;
        color: $white;
      }

      &--small {
        color: $white;
        margin-left: 5px;
      }

      &__header {
        color: $white;
      }
    }

    &__button {
      width: 100%;
      position: absolute;
      bottom: 15px;
      margin-left: -20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--center {
        width: 142px;
        font-size: 12px;
        font-weight: $semi-bold;
      }
    }
  }

  .attending-companies__header {
    font-weight: bold;
    color: $header;
  }

  .recommended-events-card__container__row__view-details {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
    opacity: 0.9;

    &__postedAt {
      color: $white;
      height: min-content;
    }

    &__name {
      font-weight: bold;
      color: $white;
    }

    &__description {
      color: $white;
    }

    &__jobs {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 14px;
        height: 12px;
        justify-content: center;
        align-self: center;
      }

      &--left {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__location {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 14px;
        height: 12px;
        justify-content: center;
        align-self: center;
      }

      &--fade {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__time {
      display: flex;
      align-content: center;
      height: min-content;

      svg {
        color: $white;
        fill: $white;
        width: 14px;
        height: 12px;
        justify-content: center;
        align-self: center;
      }

      &--fade {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }

    &__cost {
      display: flex;
      margin-bottom: 10px;
      height: min-content;

      &--bold {
        font-weight: bold;
        color: $white;
      }

      &--small {
        color: $white;
        margin-left: 5px;
      }

      &__header {
        color: $white;
      }
    }

    &__button {
      width: 100%;
      position: absolute;
      bottom: 10px;
      margin-left: -10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--center {
        width: 142px;
        height: 32px;

        span {
          @include small-font-mixin;

          font-weight: $semi-bold;
        }
      }
    }
  }
}

@media (min-width: 1850px) {
  .event-analytics__wrapper {
    width: 84%;
  }
}

@media (max-width: 380px) {
  .TicketPricing {
    h4,
    h6 {
      @include small-heading-mixin();

      svg {
        width: unset;
        height: unset;
      }
    }
  }
}

.par_upcoming h5.upcoming.recommended-events__heading {
  margin-bottom: 10px;
}

a.create-event-path {
  color: $bluecolor;

  &:hover {
    cursor: pointer;
  }
}

.broadcast-event-button.broadcast-event__container__publish-form__button--left {
  display: none;
  margin-left: 20px;
  margin-top: -5px;

  @media (min-width: $desktop-min) {
    margin-top: -10px;
  }
}

// View event manage event
.view-event__manage-menu-btn:focus {
  outline-color: transparent;
}

.auto-mark-present-active {
  span {
    color: $custom-red;
  }
}

.join-event-button {
  margin: 5px 10px;
  padding: 17.1px 20px;
  border: none;
  cursor: pointer;

  @media (max-width: $mobile) {
    @include medium-font-mixin;
  }
}

.jobs-candidate-profile {
  width: 70%;
  @include display-flex(row);
  justify-content: flex-end;
}
.jobs-candidate-title {
  width: 30%;
}
.redirect-loading-modal {
  width: fit-content !important;
  align-self: center;

  @media (min-width: $mobile) {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 40px;
    transform: translate(-50%, -50%) !important;
  }

  .ant-modal-header {
    padding: 0px;
  }

  .ant-modal-body {
    padding: 10px;
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  &__heading {
    font-weight: 500;
    text-align: center;
  }

  &__timer {
    font-weight: bold;
    text-align: center;

    &.recruiter {
      color: $recruiter-color;
    }

    &.candidate {
      color: $candidate-color;
    }

    &.public {
      color: $custom-red;
    }
  }

  .responsive-modal-header__container {
    display: flex;
  }
}

@media (max-width: $mobile) {
  .recommended-events-card__container__row__details__view--parent {
    width: 100%;
  }

  .recommended-events-card__container__row__details__view
    .recommended-events-card__container__row__details__view__icon--left {
    margin-bottom: 15px;
  }

  .recommended-events-card__container__row__details__view
    .recommended-events-card__container__row__details__view__button {
    margin-left: -15px;
  }
}

.view-event-page {
  @media (max-width: $mobile) {
    #socialShare {
      margin-right: 0;
      margin-left: -10px;

      & .share-button {
        margin-right: 10px;
      }
    }

    .recruiter__view-analytics-button,
    .view-analytics-button {
      height: 34px;

      @include medium-font-mixin;

      span {
        @include medium-font-mixin;
      }
    }

    .join-event-button.book-button {
      padding: 5px;
      max-width: 148px;
      min-width: 80px;
      width: unset;
    }

    &.candidate_events {
      .join-event-button.book-button {
        margin-left: 0px;
      }
    }
    &.candidate_events .book-event-section__book-area {
      display: flex;
      flex-wrap: unset;
      grid-gap: unset;
      gap: unset;
    }
  }
}
