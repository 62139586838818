.chat {
  &__container {
    box-shadow: $shadow;
    z-index: 1;
    padding: 20px;
    position: relative;
    background: #fff;
    margin-top: 20px;

    @media (max-width: $mobile) {
      padding: 0 0 10px 0;
      margin-bottom: 10px;
    }
  }

  &__header {
    @include display-flex(row);

    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 20px 0;

    h3 {
      @include medium-heading-mixin;

      font-weight: $semi-bold;
      margin-bottom: 0;

      @media (max-width: $mobile) {
        padding-left: 10px;
      }
    }

    .form-group.search_input input {
      background-color: $white;
    }
  }

  &__body {
    @include display-flex(row);

    .chat__conversation {
      @include display-flex(column);

      width: 32%;
      margin-right: 5px;
      height: 65vh;
      overflow: scroll;
      overflow-x: hidden;

      @media (min-width: $desktop-min) {
        width: 30%;
        height: 70vh;
      }

      @media (max-width: $mobile) {
        width: 100%;
        height: 70vh;
        margin-right: 0;
        padding-left: 10px;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $icon_color;
        border-radius: 10px;
        height: 30px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $gray-3;
      }

      &__card {
        @include display-flex(row);

        background-color: $main-background;
        margin-bottom: 4px;
        min-height: 70px;
        cursor: pointer;
        margin-right: 5px;
        align-items: center;

        @media (min-width: $desktop-min) {
          min-height: 90px;
          margin-right: 10px;
        }

        @media (max-width: $mobile) {
          margin-right: 0;
        }

        .user-type {
          width: 8px;
          height: 100%;
          margin-right: 15px;
        }

        span {
          margin-right: 15px;
        }

        &--details {
          @include display-flex(column);

          justify-content: space-between;
          flex: auto;
          padding-right: 10px;

          &-above {
            @include display-flex(row);

            justify-content: space-between;

            h5 {
              @include medium-font-mixin;

              font-weight: $semi-bold;
              color: $ctextcolor;

              @media (min-width: $desktop-min) {
                @include large-font-mixin;
              }
            }
          }

          &-below {
            @include display-flex(row);

            justify-content: space-between;

            p {
              @include small-font-mixin;

              color: $gray-3;
              font-weight: $medium;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 10vw;

              @media (max-width: $mobile) {
                width: 35vw;
              }

              @media (min-width: $desktop-min) {
                @include medium-font-mixin;
              }
            }
          }
        }
      }

      .selected {
        background-color: $white;
      }
    }

    .message__thread {
      @include display-flex(column);

      width: 68%;
      height: 65vh;

      @media (min-width: $desktop-min) {
        width: 70%;
        height: 70vh;
      }

      @media (max-width: $mobile) {
        width: 100%;
        height: 80vh;
      }

      &__header {
        @include display-flex(row);

        height: 10%;
        border-bottom: 1px solid $very-light-grey-3;
        align-items: center;
        justify-content: space-between;

        &--left {
          @include display-flex(row);

          align-items: center;
          height: 100%;

          .userType {
            width: 8px;
            height: 100%;
            margin-right: 10px;
          }

          &__details {
            @include display-flex(column);

            padding: 15px;

            h5 {
              @include medium-font-mixin;

              margin: 0;
              color: $ctextcolor;
              font-weight: $semi-bold;

              @media (min-width: $desktop-min) {
                @include large-font-mixin;
              }
            }

            p {
              @include small-font-mixin;

              color: $gray-3;
              font-weight: $medium;

              @media (min-width: $desktop-min) {
                @include medium-font-mixin;
              }
            }
          }
        }
      }

      &__body {
        @include display-flex(column-reverse);

        color: $black-text;
        padding: 10px 25px;
        overflow: scroll;
        overflow-x: hidden;
        flex-grow: 1;

        @media (max-width: $mobile) {
          padding: 0 15px;
        }

        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $icon_color;
          border-radius: 10px;
          min-height: 30px;
          max-height: 30px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $gray-3;
        }

        .thread__sender {
          @include display-flex(row);

          justify-content: flex-end;
          margin: 10px 0;
          align-items: center;

          &__detail {
            @include display-flex(column);
            @include small-font-mixin;

            align-items: flex-end;
            margin-right: 10px;
            color: $header;
            font-weight: $medium;
            width: 50%;

            @media (max-width: $mobile) {
              width: 100%;
            }

            @media (min-width: $desktop-min) {
              @include medium-font-mixin;
            }

            p {
              @include display-flex(row);
              @include medium-font-mixin;

              align-items: center;
              border-radius: 15px 15px 0 15px;
              padding: 15px;
              width: fit-content;
              max-width: 100%;
              min-height: 0;

              @media (min-width: $desktop-min) {
                @include medium-font-mixin;

                min-height: 66px;
              }
            }

            .userType {
              &__candidate {
                background-color: $icon_color;
                color: $white;
                word-break: break-all;
              }

              &__non__candidate {
                background-color: $gray-2;
                color: $white;
                word-break: break-all;
              }
            }

            &__time {
              @include small-font-mixin;

              text-align: end;
              color: $sub-header;
            }
          }

          span {
            align-self: flex-end;
            margin-bottom: 20px;
          }
        }

        .thread__receiver {
          @include display-flex(row);

          margin: 10px 0;
          align-items: center;

          &__detail {
            @include display-flex(column);
            @include small-font-mixin;

            margin-left: 10px;
            color: $header;
            font-weight: $medium;
            width: 50%;

            @media (max-width: $mobile) {
              width: 100%;
            }

            @media (min-width: $desktop-min) {
              @include medium-font-mixin;
            }

            p {
              @include display-flex(row);
              @include medium-font-mixin;

              align-items: center;
              border-radius: 15px 15px 15px 0;
              padding: 15px;
              width: fit-content;
              max-width: 100%;
              min-height: 0;

              @media (min-width: $desktop-min) {
                @include medium-font-mixin;

                min-height: 66px;
              }
            }

            .receiverType {
              &__candidate {
                background-color: $candidate-color;
                color: $white;
              }

              &__recruiter {
                background-color: $recruiter-color;
                word-break: break-all;
                color: $white;
              }

              &__organizer {
                background-color: $organizer-color;
                word-break: break-all;
                color: $white;
              }
            }

            .userType {
              &__non__candidate {
                word-break: break-all;
              }
            }

            &__time {
              @include small-font-mixin;

              color: $sub-header;

              @media (min-width: $desktop-min) {
                @include medium-font-mixin;
              }
            }
          }

          span {
            align-self: flex-end;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
