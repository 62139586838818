.bulk-event-invite__error-left {
  border-left: 10px solid red;
  padding-left: 10px;
  margin-bottom: 10px;
  color: gray;
}

.bulk-event-invite__error-right {
  color: gray;
}

.bulk-booking__confirm {
  button {
    background-color: $primary-color;

    span {
      color: white;
    }
  }
}
