#header.candidate {
  .right_menu {
    .admin-recruit {
      a {
        color: $candidate-color;
        font-size: 16px;

        svg {
          fill: $candidate-color !important;
        }
      }
    }

    .admin-notify {
      span.notification-color-dot::after {
        background: $candidate-color !important;
      }
    }
  }
}

.admin-recruit {
  @media (min-width: $laptop-min) {
    margin-right: 30px;
  }
}

//candidate-hover-menu-color
.admin_recruit.candidate {
  li {
    &:hover div {
      color: $candidate-color;
      background-color: $white;
    }

    &:hover {
      background-color: $white;
    }
  }
}

.profile_logout.candidate {
  li {
    &:hover div {
      color: $candidate-color;
      background-color: $white;
    }

    &:hover div svg {
      fill: $candidate-color;
      background-color: $white;
    }
  }
}

//recruiter-hover-menu-color
.admin_recruit.recruiter {
  li {
    &:hover div {
      color: $recruiter-color;
      background-color: $white;
    }

    &:hover {
      background-color: $white;
    }
  }
}

.profile_logout.recruiter {
  li {
    &:hover div {
      color: $recruiter-color;
      background-color: $white;
    }

    &:hover div svg {
      fill: $recruiter-color;
      background-color: $white;
    }
  }
}
//sidebar
#sidebar.candidate {
  .fixed_side {
    .ant-menu {
      .ant-menu-item-selected {
        svg {
          fill: $candidate-color !important;
        }

        a {
          color: $candidate-color !important;
        }

        &::after {
          border-right: 7px solid $candidate-color;
        }
      }

      li.ant-menu-item-active,
      li.ant-menu-item-selected {
        a {
          color: $candidate-color !important;
        }

        svg {
          fill: $candidate-color !important;
        }

        &::after {
          border-right: 7px solid $candidate-color;
        }
      }
    }

    .support_btn {
      background-color: $candidate-color;
    }
  }
}

//top bar
#header.recruiter {
  .right_menu {
    .admin-recruit {
      a {
        color: $recruiter-color;
        font-size: 16px;

        svg {
          fill: $recruiter-color !important;
        }
      }
    }

    .admin-notify {
      span.notification-color-dot::after {
        background: $recruiter-color !important;
      }
    }
  }
}

//sidebar
#sidebar.recruiter {
  .fixed_side {
    .ant-menu {
      .ant-menu-item-selected {
        svg {
          fill: $recruiter-color !important;
        }

        a {
          color: $recruiter-color !important;
        }

        &::after {
          border-right: 7px solid $recruiter-color;
        }
      }

      li.ant-menu-item-active,
      li.ant-menu-item-selected {
        a {
          color: $recruiter-color !important;
        }

        svg {
          fill: $recruiter-color !important;
        }

        &::after {
          border-right: 7px solid $recruiter-color;
        }
      }
    }

    .support_btn {
      background-color: $recruiter-color;
    }
  }
}

.top-bar {
  border-bottom: 0.1rem solid #efefef;
  align-items: center;
  padding: 5px 0;
  overflow: hidden;
  display: flex;
  align-items: center;

  .places {
    display: flex;
    align-items: center;
    margin-left: 25px;
    width: 100%;

    img {
      opacity: 0.7;
      width: 19px;
      height: 19px;
    }

    .places-search {
      padding-left: 0.7rem;
      width: 100%;

      .autocomplete-dropdown-container {
        position: fixed;
      }

      .location-input {
        color: $color_text_2;
        border: none;
        cursor: pointer;
        letter-spacing: 0;
        width: 100%;
        max-width: 265px;

        &:focus {
          outline: none;
          cursor: text;
        }
      }

      .suggestion-item {
        padding: 1rem;
        background-color: $white;
        cursor: pointer;
      }

      .suggestion-item--active {
        padding: 1rem;
        background-color: $main-background;
        cursor: pointer;
      }
    }
  }
}

#header {
  position: sticky;
  width: 100%;
  background: $white;
  z-index: 111;
  top: 0;
  right: 0;
  left: 0;

  .logo {
    max-width: 312px;
    float: left;
    width: 19.3%;
    text-align: left;
    position: relative;

    @media (min-width: 1300px) and (max-width: $desktop-min) {
      width: 18.3%;
    }

    img {
      margin-left: 30px;
      width: 180px;
      height: 40px;
      cursor: pointer;

      @media (min-width: $desktop-min) {
        width: 190px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      background: #e4e4e4;
      right: 0;
      height: 42px;
      top: -8px;
    }
  }

  .right_menu {
    float: right;
    width: 100%;
    text-align: right;
    vertical-align: middle;
    line-height: 13px;

    li {
      display: inline-block;
      background-color: $white;
      padding: 0px 5px;
      @media (min-width: $desktop-min) {
        padding: 0px 14px;
      }
    }

    li.admin-recruit {
      a {
        color: $primary-color;
        @include display-flex(row);
        justify-content: center;
        align-items: center;
        align-items: center;

        span {
          @include display-flex(row);
          justify-content: center;
          align-items: center;
          align-items: center;
        }

        .img_frnt svg {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          fill: $primary-color;
        }

        span.anticon-caret-down {
          svg {
            width: 10px;
            margin-top: 5px;
            margin-left: 10px;
            fill: $primary-color;
            transition: 0.8s;
            margin-bottom: 5px;
          }
        }
      }

      .ant-dropdown-open {
        span.anticon-caret-down {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    li.admin-logout {
      margin-right: 0;
      padding-right: 0;

      a {
        color: $tgtextcolor;
        font-weight: $medium;
        height: 33px;
        display: flex;
        align-items: center;

        span.img_frnt {
          width: 25px;
          height: 25px;
          background-color: $very-light-grey-4;
          border-radius: 50%;
          display: inline-block;
          margin-right: 5px;
          text-align: center;
          line-height: 29px;

          svg {
            width: 13px;
            height: 13px;
            position: relative;
            top: 0;
            fill: $tgtextcolor;
          }
        }

        span.anticon-caret-down {
          svg {
            width: 10px;
            margin-left: 10px;
            fill: $tgtextcolor;
            transition: 0.8s;
          }
        }
      }

      .ant-dropdown-open {
        span.anticon-caret-down {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    li.admin-notify {
      a {
        color: $tgtextcolor;

        span.img_frnt {
          position: relative;

          img {
            width: 20px;
            height: 19px;
            margin-right: 15px;
            position: relative;
            top: 0;
            fill: $placeholder;
            filter: invert(97%) sepia(0%) saturate(31%) hue-rotate(153deg)
              brightness(88%) contrast(71%);
            transition: 0.03s;
          }

          &::after {
            width: 9px;
            height: 9px;
            content: '';
            position: absolute;
            left: 15px;
            top: 0;
            border-radius: 50%;
            background-color: $very-light-grey;
          }
        }
        span.notification-color-dot {
          &::after {
            background-color: $primary-color;
          }
        }

        span.anticon-caret-down {
          svg {
            width: 10px;
            margin-left: 10px;
            fill: $placeholder;
          }
        }
      }
    }
  }
}

//
.admin_recruit {
  margin-top: 16px;
  max-height: 70vh;
  overflow-y: auto;

  li {
    padding: 10px 15px;
    margin: 0;
    font-size: $font16;

    div {
      margin: 0;
      padding: 0 0;
      cursor: pointer;
      color: $placeholder;
      transition: 0.03s;
    }

    &:hover div {
      color: $primary-color;
      background-color: $white;
    }

    &:hover {
      background-color: $white;
    }
  }
}

.ant-menu-item a::before {
  display: none;
}

.ResSideMenu {
  display: none;
}

@media (max-width: 1200px) {
  #header .logo::after {
    width: 0;
  }

  #header .logo img {
    margin-left: -40px;
  }

  .ResSideMenu {
    display: block;
    float: left;
    padding-left: 30px;
    text-align: left;
    width: 100%;
    position: relative;
  }

  #header .logo {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }

  #header .logo .img {
    align-self: center;
  }

  .ResSideMenu .menuIcon {
    max-width: 106px;
    width: 38px;
    height: 28px;
    color: gray;
  }

  .resMenuStyle {
    width: 70%;
  }
}

@media (max-width: $mobile) {
  #header .logo img {
    margin-left: -40px;
  }

  .ResSideMenu .menuIcon {
    max-width: 106px;
    width: 28px;
    height: 22px;
    color: gray;
  }

  .resMenuStyle {
    width: 100%;
  }
}

.profile_logout {
  position: sticky;
  margin: 0 auto;
  width: fit-content;
  margin-top: 10px;
  @media (min-width: $desktop-min) {
    margin-top: 20px;
  }

  li {
    padding: 10px 15px;
    margin: 0;

    div {
      padding: 0 0;
      padding-left: 42px;
      font-size: $font16;
      margin: 0;
      color: $placeholder;
      cursor: pointer;
      position: relative;
      transition: 0.03s;

      svg {
        width: 21px;
        position: absolute;
        left: 10p;
        left: 6px;
        fill: $placeholder;
        transition: 0.09s;
      }
    }

    &:hover div {
      color: $primary-color;
      background-color: $white;
    }

    &:hover div svg {
      fill: $primary-color;
      background-color: $white;
    }

    &:hover {
      background-color: $white;
    }
  }

  li.profile_ico {
    &:hover div svg {
      width: 24px;
    }
  }
}

.notify_msg {
  position: sticky;
  top: 100px;
  width: 454px;
  margin: 0 auto;
  max-width: 454px;

  @media (max-width: $mobile) {
    width: 280px;
  }
}
.notification__header-item__link__view-all:hover {
  cursor: pointer;
}
.notify_box {
  margin-top: 30px;
  margin-right: -110px;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item-selected {
    background-color: $white;
    color: $black;
  }

  @media (max-width: $mobile) {
    margin-right: 10px;
  }
}
.notify_box.candidate {
  margin-top: 30px;
  margin-left: 120px;
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu-item-selected {
    background-color: $white;
    color: $black;
  }
}

.top-bar .ant-menu-vertical {
  border: 0;
}

.topbar__container--right {
  display: flex;
  flex-direction: row;
  width: 83.28%;

  .topbar-picture {
    margin-right: 10px;
  }
}

@media (min-width: $desktop-min) {
  #header {
    height: 80px;

    .logo {
      img {
        height: auto;
      }
    }

    .right_menu {
      li.admin-recruit {
        a {
          .img_frnt svg {
            width: 26px;
            height: 26px;
            margin-right: 5px;
          }

          span.anticon-caret-down {
            svg {
              margin-top: 0;
              margin-left: 10px;
            }
          }
        }
      }

      li.admin-logout {
        padding-bottom: 10px;

        a {
          font-size: $font16;

          span.img_frnt {
            width: 33px;
            height: 33px;
            margin-top: 0;
            margin-right: 10px;
            bottom: -5px;

            svg {
              width: 19px;
              height: 19px;
              top: 5px;
            }
          }
        }
      }

      li.admin-notify {
        padding-left: 0;

        a {
          span.img_frnt {
            position: relative;

            img {
              width: 24px;
              height: 24px;
            }

            &::after {
              top: -3px;
            }
          }
        }
      }
    }
  }

  .top-bar {
    .places {
      img {
        opacity: 0.7;
        width: 27px;
        height: 27px;
      }
    }
  }
}

.selected {
  &.recruiter div {
    color: $recruiter-color;
  }
  &.organizer div {
    color: $primary-color;
  }
}

.notify_box.organizer {
  @media (min-width: $desktop-min) {
    margin-right: -50px;
  }
}
