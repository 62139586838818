#socialShare {
  white-space: nowrap;
  width: max-content;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-top: -5px;
  }
}

#socialShare a,
#socialShare > .socialBox {
  position: relative;
  float: none;
  color: #fff;
  font-size: 20px;
  background-color: transparent;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  margin-top: 6px;

  svg {
    width: 25px;
    position: relative;
    height: 15px;
  }
  .share-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#socialShare-dashboard > .socialBox {
  svg {
    width: 25px;
    position: relative;
    height: 15px;
    margin-bottom: -9px;

    @media (min-width: $desktop-min) {
      height: 20px;
      margin-bottom: -11px;
    }
  }
}

#socialShare {
  .recommended {
    position: absolute;
    right: -10px;
  }
}

#socialShare a {
  background-color: rgba(0, 0, 0, 0.2);
}

#socialShare > * > span {
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
  display: block;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  transform: scale(1);
  transform: scale(1);
  transform: scale(1);
  transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

#socialShare > * > span:hover,
#socialShare > .open > span {
  transform: scale(1.25);
  transform: scale(1.25);
  transform: scale(1.25);
  transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

#socialGallery {
  left: 15%;
  margin: 0 auto 0;
  position: absolute;
  top: 30px;
  transform: translate(-50%, 0);
  visibility: hidden;
  width: max-content;
  z-index: 5;
  max-width: 400px;
  background-color: white;
  box-shadow: $shadow2;
  @include display-flex(row);

  @media (min-width: $desktop-min) {
    top: 50px;
  }

  @media (max-width: $mobile) {
    max-width: 100px;
    left: 80px;
  }
}

.socialBox.pointer {
  @include display-flex(row);

  align-items: center;
}

#socialGallery {
  margin-top: 8px;
  button,
  div.copy-link-button {
    background-color: white;
    margin: 10px;
    width: 24px;
    height: 25px;

    @media (min-width: $desktop-min) {
      width: 38px;
      // height: 38px;

      svg {
        width: 38px;
        height: 38px;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
  div.copy-link-button {
    &:hover {
      cursor: pointer;
    }
  }
}

.socialToolBox {
  cursor: default;
  display: flex;

  @media (max-width: $mobile) {
    display: block;
  }

  svg {
    height: 25px !important;
  }
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  #socialGallery {
    width: 120px;
  }
}

.recommended-events-card__container__row__details__view__icon--left {
  #socialGallery {
    left: 120px;
    margin: 0 auto 0;
    position: absolute;
    top: 40px;
    transform: translate(-50%, 0);
    visibility: hidden;
    width: max-content;
    z-index: 5;
    max-width: 400px;
    box-shadow: $shadow2;

    @media (min-width: $desktop-min) {
      top: 50px;
      left: 160px;
    }

    @media (max-width: $mobile) {
      max-width: 100px;
      left: 40px;
      top: 60px;
    }
  }
}

.invite-link-copy-button {
  width: 30px;
  height: 30px;
}
